import React from 'react'
import PropTypes from 'prop-types'
import BladeText from '../../components/BladeText'
import { renderBgTypeAndBg } from '../../utils'

const BladeTextGatsbyContentfulContainer = ({
  columns = [],
  theme,
  background,
  ...rest
}) => {
  let t = theme ? 'light' : 'dark'
  const { assetId, type, src, thumbnail, alt, autoPlay } = renderBgTypeAndBg({
    background,
    theme: t,
  })

  return (
    <BladeText
      assetId={assetId}
      assetAltTitle={alt}
      autoPlay={autoPlay}
      bg={src}
      bgType={type}
      columns={columns.map(({ overlayCopy, ...rest }) => ({
        richText: overlayCopy && overlayCopy.json,
        ...rest,
      }))}
      theme={t}
      thumbnail={thumbnail}
      {...rest}
    />
  )
}
export default BladeTextGatsbyContentfulContainer

BladeTextGatsbyContentfulContainer.propTypes = {
  id: PropTypes.string,
  __typename: PropTypes.string,
  height: PropTypes.number,
  theme: PropTypes.bool,
  background: PropTypes.array,
  columns: PropTypes.array,
}
