import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import { StyledSidebar, StyledSvgContainer, StyledSvg } from '../styles'
import { useMobile } from '../../../hooks'
import MainView from './MainView'
import CategoryView from './CategoryView'
import DetailView from './DetailView'
import { categorize } from '../../../utils'
import dragIcon from '../../../assets/drag-icon@3x.png'
import usePlatform from '../../../hooks/usePlatform'
import useVerticalSnapToPosition from '../useVerticalSnapToPosition'

const Sidebar = ({
  featureCollections,
  dispatch,
  view,
  setView,
  initView,
  ...rest
}) => {
  const { categories, categoryMap } = categorize(featureCollections, 'label')
  const sections = categories.map(c => ({
    id: c,
    title: c,
    featureCollections: categoryMap[c],
  }))

  const updateMap = (current, zoom = 16) => {
    if (current.type === 'detail') {
      dispatch({
        type: 'FLY_TO_LOCATION',
        payload: { location: current?.obj, zoom },
      })
    } else {
      dispatch({
        type: 'FIT_TO_BOUNDS',
        payload: {
          features: current?.obj?.features,
          zoom,
          shiftOffset: current.type === 'main',
        },
      })
    }
    dispatch({ type: 'RENDER_MARKERS', payload: current.category })
    dispatch({ type: 'RENDER_LAYER', payload: current.category })
  }

  const goToNextView = (nextView, zoom) => {
    const previous = view.current
    const current = nextView
    setView({ current, previous })
    updateMap(current, zoom)
  }

  const goToPrevView = zoom => {
    const current = view.previous.type === 'main' ? initView : view.previous
    const previous = initView
    setView({ current, previous })
    updateMap(current, zoom)
  }

  const { type } = view.current

  switch (type) {
    case 'detail':
      return (
        <DetailView
          {...view.current.obj}
          prevTitle={view.previous.obj.title}
          goToPrevView={goToPrevView}
          {...rest}
        />
      )
    case 'category':
      return (
        <CategoryView
          {...view.current.obj}
          prevTitle="Main Menu"
          goToPrevView={goToPrevView}
          goToNextView={goToNextView}
          {...rest}
        />
      )
    case 'main':
    default:
      return (
        <MainView sections={sections} goToNextView={goToNextView} {...rest} />
      )
  }
}

const SidebarWrapper = ({ zIndex, ...props }) => {
  const [isMobile] = useMobile()
  const isExpanded = useRef(false)
  const [, os] = usePlatform()
  const isIos = os === 'iOS'
  const sidebarRef = useRef()
  const snapPositions = useRef({
    expanded: '5%',
    minimized: isIos ? '60%' : '65%',
  })

  const [svgRef, , expand, minimize] = useVerticalSnapToPosition(
    sidebarRef,
    isExpanded,
    snapPositions.current.expanded,
    snapPositions.current.minimized
  )

  useEffect(() => {
    snapPositions.current.minimized = isIos ? '60%' : '65%'
  }, [isIos])

  return isMobile ? (
    <StyledSidebar
      ref={sidebarRef}
      position="absolute"
      width="95%"
      height={isIos ? '75%' : '85%'}
      bg="white"
      zIndex={zIndex}
      top={
        isExpanded.current
          ? snapPositions?.current?.expanded
          : snapPositions?.current?.minimized
      }
      left="0"
      right="0"
      margin="0 auto"
      pt={3}
      border="1px solid #d9d9d6"
    >
      <StyledSvgContainer
        ref={svgRef}
        onClick={() => {
          isExpanded.current ? minimize() : expand()
        }}
        position="absolute"
        top="10px"
        left="0"
        right="0"
        cursor="pointer"
      >
        <StyledSvg
          src={dragIcon}
          alt="drag-icon"
          height="3px"
          width="40%"
          mb={3}
        />
      </StyledSvgContainer>
      <Sidebar
        {...props}
        sidebarRef={sidebarRef}
        isExpanded={isExpanded}
        snapPositions={snapPositions}
      />
    </StyledSidebar>
  ) : (
    <StyledSidebar
      position="absolute"
      width="30%"
      minWidth="350px"
      height={isIos ? '75%' : '90%'}
      bg="white"
      zIndex={zIndex}
      top="5%"
      left="30px"
      border="1px solid #d9d9d6"
      maxWidth="350px"
    >
      <Sidebar {...props} />
    </StyledSidebar>
  )
}

SidebarWrapper.propTypes = {
  zIndex: PropTypes.number,
}

Sidebar.propTypes = {
  featureCollections: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  view: PropTypes.shape({
    current: PropTypes.shape({
      type: PropTypes.string,
      obj: PropTypes.object,
      category: PropTypes.string,
    }),
    previous: PropTypes.shape({
      type: PropTypes.string,
      obj: PropTypes.object,
      category: PropTypes.string,
    }),
  }),
  setView: PropTypes.func,
  initView: PropTypes.shape({
    current: PropTypes.shape({
      type: PropTypes.string,
      obj: PropTypes.object,
      category: PropTypes.string,
    }),
    previous: PropTypes.shape({
      type: PropTypes.string,
      obj: PropTypes.object,
      category: PropTypes.string,
    }),
  }),
}

export default SidebarWrapper
