import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, { GET_LIST_ITEMS } from '../../hooks/usePreviewQuery'
import DefaultList from '../../components/List/DefaultList'
import DownloadList from '../../components/List/DownloadList'

const BladeListApolloCPAContainer = ({
  id,
  layout,
  listItems = [],
  ...rest
}) => {
  const {
    loading: loadingListItems,
    error: errorListItems,
    data: dataListItems,
  } = usePreviewQuery({
    query: GET_LIST_ITEMS,
    skip: !listItems.length === 0,
    ids: listItems.map(({ sys }) => sys.id).join(','),
  })
  const [items, setItems] = useState([])

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const getMappedListItems = useCallback(
    (layout, data, entryIncludes, assetIncludes) => {
      const listItems =
        data &&
        data
          .map(({ fields, sys }) => ({
            ...fields,
            downloadItem:
              fields?.downloadItem &&
              getLinkedEntry(assetIncludes, fields.downloadItem?.sys?.id),
            navigationLink:
              fields?.navigationLink &&
              getLinkedEntry(entryIncludes, fields.navigationLink?.sys?.id),
            icon:
              fields?.icon &&
              getLinkedEntry(entryIncludes, fields.icon?.sys?.id),
            id: sys.id,
          }))
          .map(({ navigationLink, ...rest }) => ({
            ...rest,
            navigationLink:
              navigationLink?.fields &&
              getLinkedEntry(
                entryIncludes,
                navigationLink?.fields?.navigationLink?.sys?.id
              ),
          }))
          .map(({ downloadItem, icon, navigationLink, ...rest }) => ({
            ...rest,
            downloadItem: downloadItem?.fields && {
              id: downloadItem?.sys?.id,
              ...downloadItem.fields,
            },
            to: navigationLink?.fields && navigationLink?.fields?.slug,
            href: navigationLink?.fields && navigationLink?.fields?.url,
            icon: icon?.fields && {
              id: icon?.sys?.id,
              ...icon.fields,
            },
          }))

      if (layout === 'Download') {
        return listItems.map(({ downloadItem, ...rest }) => ({
          ...rest,
          src: downloadItem?.file?.url,
        }))
      } else {
        return listItems
      }
    },
    []
  )

  useEffect(() => {
    if (!loadingListItems && !errorListItems && dataListItems) {
      const {
        entries: {
          includes: { Entry, Asset },
          items,
        },
      } = dataListItems

      let mappedListItems = getMappedListItems(layout, items, Entry, Asset)

      setItems(mappedListItems)
    }
  }, [errorListItems, dataListItems, loadingListItems, getMappedListItems])

  switch (layout) {
    case 'Download':
      return <DownloadList key={id} listItems={items} {...rest} />
    default:
      return <DefaultList key={id} listItems={items} {...rest} />
  }
}

BladeListApolloCPAContainer.propTypes = {}

export default BladeListApolloCPAContainer
