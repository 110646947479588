import React from 'react'
import PropTypes from 'prop-types'
import { MediaApolloCPAContainer } from '../MediaContainer'
import Text from '../../components/Text'
import Divider from '../../components/Divider'

const BladeMediaContainer = ({
  layout,
  overlayCopy,
  theme,
  align,
  id,
  media,
  ...rest
}) => {
  switch (layout) {
    case 'Single Image':
      return (
        <MediaApolloCPAContainer
          overlayCopy={overlayCopy}
          theme={theme}
          type="image"
          id={media && media[0].sys.id}
          media={media}
          {...rest}
        />
      )
    case 'Single Video':
      return (
        <MediaApolloCPAContainer
          overlayCopy={overlayCopy}
          theme={theme}
          type="video"
          id={media && media[0].sys.id}
          media={media}
          {...rest}
        />
      )
    case 'Divider':
      return <Divider overlayCopy={overlayCopy} theme={theme} />
    case 'Text - Left':
      return <Text overlayCopy={overlayCopy} theme={theme} align="left" />
    case 'Text - Center':
      return <Text overlayCopy={overlayCopy} theme={theme} align="center" />
    case 'Hero Animation':
      return <Text overlayCopy={overlayCopy} theme={theme} align="left" />
    default:
      return <div />
  }
}

BladeMediaContainer.propTypes = {
  layout: PropTypes.string,
  theme: PropTypes.bool,
}

export default BladeMediaContainer
