import React from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '../../hooks'
import { Cell } from './styles'
import Wrapper from './components/Wrapper'
import BladeColumn from './components/BladeColumn'

const BladeText = ({
  assetAltTitle,
  assetId = 'str-id',
  autoPlay = false,
  bg, // {} || dark.bg or light.bg
  bgType = 'color',
  columns = [],
  displayScrollIcon = false,
  height = 100,
  theme = 'light',
  thumbnail,
  backgroundColor,
  backgroundOpacity,
  ...rest
}) => {
  const [isMobile] = useMobile()

  const renderTextAlignment = ({ align }) => {
    if (align === 'Top') {
      return 'flex-start'
    } else if (align === 'Bottom') {
      return 'flex-end'
    } else {
      return 'center'
    }
  }

  const handleDetermineRichText = ({ columns }) => {
    if (!columns || columns.length > 1) {
      return false
    }
    let column = columns[0]
    if (column?.richText) {
      return true
    } else {
      return false
    }
  }

  return (
    <Wrapper
      autoPlay={autoPlay}
      hasRichText={handleDetermineRichText({ columns })}
      assetAltTitle={assetAltTitle}
      assetId={assetId}
      bgType={bgType}
      bg={bg}
      backgroundColor={backgroundColor}
      backgroundOpacity={backgroundOpacity}
      displayScrollIcon={displayScrollIcon}
      height={height}
      theme={theme}
      thumbnail={thumbnail}
    >
      <Cell
        width="100%"
        maxWidth="maxWidth.xlarge"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        paddingTop={isMobile ? '16px' : '75px'}
        paddingBottom={isMobile ? '16px' : '75px'}
        paddingRight={isMobile ? '16px' : '125px'}
        paddingLeft={isMobile ? '16px' : '125px'}
      >
        {columns &&
          columns.map(
            ({ width = 12, id, textAlignmentVertical, ...rest }, i) => (
              <Cell
                width={[12 / 12, 12 / 12, width / 12, width / 12, width / 12]}
                key={`${id} + ${i}`}
                display="flex"
                justifyContent="center"
                theme={theme}
                alignItems={renderTextAlignment({
                  align: textAlignmentVertical,
                })}
              >
                <BladeColumn
                  {...rest}
                  id={id}
                  theme={theme}
                  height={height}
                  bgContentType={bgType}
                  textAlignmentVertical={renderTextAlignment({
                    align: textAlignmentVertical,
                  })}
                  dividerMinWidth={i !== 0 ? columns[i - 1].width : 0}
                />
              </Cell>
            )
          )}
      </Cell>
    </Wrapper>
  )
}

export default BladeText

BladeText.propTypes = {
  assetAltTitle: PropTypes.string,
  assetId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  autoPlay: PropTypes.bool,
  bg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgType: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  displayScrollIcon: PropTypes.bool,
  height: PropTypes.number,
  theme: PropTypes.string,
  thumbnail: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number,
}
