import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BladeContainer from '../../../common/containers/BladeContainer'
import Layout from '../components/Layout'
import config from '../../config'

const Page = ({ data, pageContext }) => {
  let { blades } = data?.page.nodes[0]

  return (
    <Layout {...pageContext} pageSEO config={config}>
      <BladeContainer blades={blades} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    ogImage: PropTypes.string,
  }).isRequired,
}

export default Page

export const query = graphql`
  query Page($id: String!) {
    page: allContentfulPage(filter: { contentful_id: { eq: $id } }) {
      nodes {
        ...pageFields
      }
    }
  }
`
