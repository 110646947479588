import styled from 'styled-components'
import {
  color,
  space,
  layout,
  flexbox,
  typography,
  border,
} from 'styled-system'
import { A } from '../Button'

export const StyledSectionName = styled.p`
  ${color}
  ${flexbox}
  ${typography}
  ${space}
`

export const StyledLink = styled(A)`
  cursor:pointer;
  ${color}
  ${flexbox}
  ${typography}
`

export const StyledContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  ${color}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
  ${typography}
`
export const StyledUpdates = styled.div`
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
`

export const StyledUpdate = styled.div`
  box-sizing: border-box;
  ${color}
  ${space}
  ${layout}
  ${flexbox}

`
export const StyledCategory = styled.p`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  text-transform: uppercase;
`
export const StyledTitle = styled.p`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${border}
`
export const StyledDate = styled.p`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
`
export const StyledBorder = styled.div`
${color}
${space}
${layout}
${flexbox}
${typography}
${border}
`
