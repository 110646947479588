import React from 'react'
import { renderRichText } from '../BladeText/components/RichText'
import { simpleRenderRichText } from './SimpleRichText'
import PropTypes from 'prop-types'

export const richText = (
  themePackage,
  overlayCopy,
  theme,
  shortenDescription
) => {
  switch (themePackage.richtext) {
    case 'simple':
      return simpleRenderRichText(overlayCopy, theme, shortenDescription)
    case 'default':
      return renderRichText({
        richText: overlayCopy,
        theme,
        shortenDescription,
      })
    default:
      return <div />
  }
}

richText.propTypes = {
  themePackage: PropTypes.object,
  overlayCopy: PropTypes.object,
  theme: PropTypes.string,
  shortenDescription: PropTypes.string,
}
