import React from 'react'
import Update from './Update'
import {
  StyledUpdates,
  StyledContainer,
  StyledSectionName,
  StyledLink,
} from './styles'
import PropTypes from 'prop-types'
import { useMobile } from '../../hooks'

const UpdatesList = ({
  updates = [],
  showMoreUpdates = false,
  theme = 'light',
  section = 'Latest Updates',
}) => {
  const [isMobile] = useMobile()
  if (!updates?.updates || updates.updates.length === 0) return null
  return (
    <StyledContainer
      data-testid="updates-list"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={['flex-start', 'center', 'center']}
      bg={`${theme}.bg`}
    >
      <StyledContainer
        maxWidth={!isMobile && 'maxWidth.xlarge'}
        px={3}
        py={4}
        width="100%"
      >
        <StyledContainer
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb="24px"
        >
          <StyledSectionName
            color={`${theme}.textAlt`}
            m={0}
            fontWeight={400}
            fontSize={[0, 3]}
            fontFamily="body"
          >
            {section}
          </StyledSectionName>
        </StyledContainer>
        <StyledUpdates
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flexWrap="nowrap"
          overflowX="auto"
        >
          {updates.updates.map(update => (
            <Update key={update.id} theme={theme} {...update} />
          ))}
        </StyledUpdates>
        {!isMobile && showMoreUpdates && (
          <StyledContainer textAlign="right">
            <StyledLink color={`${theme}.textAlt`} to={updates.parentSlug}>
              More updates >
            </StyledLink>
          </StyledContainer>
        )}
      </StyledContainer>
    </StyledContainer>
  )
}

UpdatesList.propTypes = {
  updates: PropTypes.object,
  showMoreUpdates: PropTypes.bool,
  theme: PropTypes.string,
  section: PropTypes.string,
}

export default UpdatesList
