import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  ModalContainer,
  ModalOverlay,
  ModalDismissButton,
  ModalBodyContent,
} from '../style'
import ModalContent from './ModalContent'
import { zIndices } from '../../../themes'
import closeBtn from '../../../assets/close-btn@3x.png'

const Modal = ({
  children,
  theme = 'dark',
  defaultModalVisibility = false,
  alignY = 'center',
  fullWidth = false,
  height = ['50vh', null, '75vh'],
  width = [4 / 5, null, '85%', '90%'],
  top = '15%',
  right = '0',
  bottom = false,
  displayCloseIcon = true,
  borderRadius = '0.3rem',
}) => {
  const [modalVisible, setModalVisible] = useState(defaultModalVisibility)
  const toggle = () => {
    setModalVisible(!modalVisible)
  }

  if (alignY === 'bottom') {
    top = false
    bottom = '0px'
    height = ['150px', '150px', '125px', '120px']
    borderRadius = '0px'
  }
  if (fullWidth) {
    width = ['100%']
  }
  if (typeof document !== 'undefined') {
    if (modalVisible) document.body.style.overflow = 'hidden'
    if (!modalVisible) document.body.style.overflow = 'initial'
  }

  return (
    <>
      {modalVisible && (
        <ModalContainer
          data-testid="modal"
          width="100%"
          height={!bottom && '100%'}
          position="fixed"
          top={top}
          bottom={bottom}
          right={right}
          zIndex={zIndices.modal}
        >
          <ModalOverlay
            modalVisible={modalVisible}
            width="100%"
            height="100%"
            position="fixed"
            top={0}
            right={0}
            overflow="hidden"
            onClick={!defaultModalVisibility ? toggle : undefined}
          />
          <ModalBodyContent
            bg={`${theme}.bg`}
            margin="0 auto"
            borderRadius={borderRadius}
            position="relative"
            zIndex={1}
            width={width}
            height={height}
          >
            {modalVisible && displayCloseIcon && (
              <ModalDismissButton
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggle}
                bg={`${theme}.bg`}
                borderRadius={'100%'}
                position="absolute"
                zIndex={4}
                border={0}
                m={0}
                p={0}
                fontSize="1.25rem"
                fontWeight={700}
                top={-10}
                right={-15}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  aria-hidden="true"
                  src={closeBtn}
                  height="auto"
                  width="85%"
                />
              </ModalDismissButton>
            )}
            {children}
          </ModalBodyContent>
        </ModalContainer>
      )}
    </>
  )
}

Modal.propTypes = {
  theme: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  thumbnail: PropTypes.string,
  contentType: PropTypes.string,
  defaultModalVisibility: PropTypes.bool,
  alignY: PropTypes.string,
  fullWidth: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.bool,
  displayCloseIcon: PropTypes.bool,
  borderRadius: PropTypes.string,
}

export default Modal
