/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, P, B, I, U } from './styles.js'
import { OpaqueButton } from '../Button'
import Icon from '../Icon'
import Video from '../Video'
import Image from '../Image/index'
import Modal from '../Modal'
import { MediaApolloCPAContainer } from '../../containers/MediaContainer'

export const renderComponentFromContentType = (node, children, theme) => {
  // const us = 'en-US'
  const base = node && node.data && node.data.target
  const type =
    (base &&
      base.sys &&
      base.sys.contentType &&
      base.sys.contentType.sys &&
      base.sys.contentType.sys.id) ||
    node.nodeType
  const fields = base && base.fields
  const id = base && base.sys && base.sys.contentful_id

  switch (type) {
    case 'embedded-entry-inline':
      return (
        <MediaApolloCPAContainer
          theme={theme}
          id={node && node.data && node.data.target && node.data.target.sys.id}
        />
      )
    case 'mediaVideo':
      return (
        <div style={{ marginTop: '40px' }}>
          <Video media={fields} id={id} overlay={false} />
        </div>
      )
    case 'mediaImage':
      return <Image media={fields} />
    case 'modal':
      return <Modal media={fields} />
    case 'icon':
      let { title, icon } = node.data.target.fields
      title = title['en-US']
      icon = icon['en-US']
      return <Icon icon={icon} title={title} />
    default:
      return null
  }
}

const EmbeddedComponent = ({ children, node, theme }) =>
  renderComponentFromContentType(node, children, theme)

const options = theme => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <>
          <P
            color={`${theme}.text`}
            fontSize={[0, 8, 8, 8]}
            lineHeight="1.5"
            fontFamily="body"
            fontWeight="regular"
            w={[1]}
            px={[4, 0, 0, 0]}
          >
            {children}
          </P>
        </>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1
        color={`${theme}.text`}
        fontSize={[2, 1, 1, 1]}
        w={[1]}
        fontFamily="headings"
        fontWeight={400}
        lineHeight={[0]}
        textShadow={theme === 'dark' && 'text'}
        m={0}
      >
        {children}
      </H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        // color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        color={`${theme}.text`}
        fontSize={[3, 2, 2, 2, 2, 2, 2]}
        fontFamily="headings"
        lineHeight={1.4}
        // fontWeight={theme === 'dark' ? 400 : 300}
        fontWeight={400}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
        m={0}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[4, 3, 3, 3, 3, 3, 3]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={200}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[5, 4, 4, 4, 4, 4, 4]}
        fontFamily="headings"
        lineHeight={1.4}
        // fontWeight={theme === 'dark' ? 400 : 300}
        fontWeight={200}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <H5
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[6, 5, 5, 5, 5, 5, 5]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <H6
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[7, 0, 0, 0, 0, 0, 0]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
        m={[2]}
      >
        {children}
      </H6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <>{children}</>,
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <OpaqueButton theme={theme} href={node.data.uri}>
        {children}
      </OpaqueButton>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <OpaqueButton
        theme={theme}
        to={node.data.target.fields.slug['en-US']}
        href={node.data.uri}
      >
        {children}
      </OpaqueButton>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const findModal =
        node &&
        node.data &&
        node.data.target &&
        node.data.target.fields &&
        node.data.target.fields.mediaReference
      const modalContent = node && node.data && node.data.target
      if (findModal) {
        return (
          <Modal type="opaque" media={modalContent}>
            {children}
          </Modal>
        )
      }
      return (
        <OpaqueButton
          theme={theme}
          to={node.data.target.fields.slug['en-US']}
          href={node.data.uri}
        >
          {children}
        </OpaqueButton>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedComponent node={node} theme={theme}>
        {children}
      </EmbeddedComponent>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B color={`${theme}.textAlt`} fontWeight={600}>
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => (
      <I color={`${theme}.textAlt`} fontWeight={600}>
        {text}
      </I>
    ),
    [MARKS.UNDERLINE]: text => (
      <U color={`${theme}.textAlt`} fontWeight={600}>
        {text}
      </U>
    ),
  },
})

export const renderRichText = (document, theme) =>
  documentToReactComponents(document, options(theme))
