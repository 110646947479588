import styled from 'styled-components'
import { position, space, layout, color, typography } from 'styled-system'
import { theme } from '../../themes/anetwork'

export const StyledBackground = styled.div`
  ${color}
`

export const StyledGridWrapper = styled.section`
  ${layout}
  ${space}
  ${position}
  ${color}
`

export const StyledGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${space}
  ${position}
`

export const StyledTitle = styled.h1`
  text-transform: uppercase;
  font-weight: 600;
  font-family: ${theme.fonts.headings};
  ${color}
  ${typography}
  ${space}
`
export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.5s;
`

export const StyledCaption = styled.p`
  z-index: 2;
  ${position}
  ${space}
  ${color}
  ${typography}
`

export const StyledOverlayShadow = styled.div`
  width: 100%;
  height: 30%;
  opacity: 0.5;
  background: linear-gradient(to bottom, transparent, black);
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`

export const StyledOverlayText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 32px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: ${theme.fonts.open};
  color: white;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.6s;
  opacity: 0;
  transform: translateY(100%);
  ${space}
`

export const StyledGridItemContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  
  &:hover ${StyledImage} {
    transform: scale(1.1);
  }

  &:hover ${StyledOverlayText}{
    transform: translateY(0%);
    opacity: 1;
  }
  
  ${layout}
  ${space}
  ${position}
  ${color}
`
