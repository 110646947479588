import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledGridItemContainer,
  StyledImage,
  StyledCaption,
  StyledOverlayShadow,
  StyledOverlayText,
} from './styles'
import defaultAvatar from '../../assets/default-avatar.png'
import { A } from '../Button'

const MyColorGridItem = ({
  name,
  title,
  slug,
  preview = false,
  shortBio,
  image = null,
}) => {
  shortBio = shortBio && shortBio.split('\n')[0]

  return (
    <StyledGridItemContainer width={[1, 1, 1 / 2]} p={3}>
      <A to={preview ? slug : `/the-players/${slug}`}>
        <StyledGridItemContainer position="relative">
          <StyledImage
            src={image?.fluid?.src || defaultAvatar}
            alt={title || 'grid item'}
          />
          <StyledCaption
            position="absolute"
            bottom="0"
            color="white"
            ml={3}
            fontSize={7}
          >
            {name} | {title}
          </StyledCaption>
          <StyledOverlayShadow />
          <StyledOverlayText p={3}>{shortBio}</StyledOverlayText>
        </StyledGridItemContainer>
      </A>
    </StyledGridItemContainer>
  )
}

MyColorGridItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  shortBio: PropTypes.shape({
    shortBio: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
}

export default MyColorGridItem
