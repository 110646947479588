import styled from 'styled-components'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  shadow,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
export const StyledDateContainer = styled(Container)``

export const StyledDate = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${shadow}
`
