import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from './style'

// Carousel component with settings, provide an array of objects with fluid props
const CarouselSlickHeader = ({ title, subtitle, theme = 'dark' }) => {
  return (
    <Cell width="100%" py={45}>
      <Cell
        fontSize={[3, 2, 2, 2, 2, 2, 2]}
        fontFamily="body"
        color={`${theme}.text`}
        pb={3}
        textShadow={theme === 'dark' && 'text'}
        letterSpacing="0.2px"
        fontWeight={600}
      >
        {title}
      </Cell>
      <Cell
        pb={3}
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[7, 6, 6, 6, 6, 6, 6]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={400}
        letterSpacing="0.18px"
        textShadow={theme === 'dark' && 'text'}
        style={{ textTransform: 'uppercase' }}
      >
        {subtitle}
      </Cell>
    </Cell>
  )
}

CarouselSlickHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.string,
}

export default CarouselSlickHeader
