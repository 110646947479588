import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  ModalContainer,
  ModalOverlay,
  ModalBackdrop,
  ModalDismissButton,
  ModalBodyContent,
  StyledImage,
  StyledVideoThumbnailContainer,
} from '../style'
import { zIndices } from '../../../themes'
import { PlayButton } from '../../Video'
import { ThemeSwitchButton } from '../../Button'
import closeBtn from '../../../assets/close-btn@3x.png'

const ModalSimple = ({
  children,
  theme = 'dark',
  thumbnail,
  alt = 'modal-item',
  contentType,
  label,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const toggle = () => {
    setModalVisible(modalVisible => !modalVisible)
  }
  if (typeof document !== 'undefined') {
    if (modalVisible) document.body.style.overflow = 'hidden'
    if (!modalVisible) document.body.style.overflow = 'initial'
  }
  return (
    <>
      {modalVisible
        ? ReactDOM.createPortal(
            <ModalContainer
              width="100%"
              height="100%"
              position="fixed"
              top="15%"
              right={0}
              zIndex={zIndices.modal}
            >
              <ModalOverlay
                modalVisible={modalVisible}
                width="100%"
                height="100%"
                position="fixed"
                top={0}
                right={0}
                overflow="hidden"
                onClick={toggle}
              />
              <ModalBodyContent
                margin="0 auto"
                borderRadius={'0.3rem'}
                position="relative"
                zIndex={1}
                width={[4 / 5, null, '85%', '90%']}
              >
                {modalVisible && (
                  <ModalDismissButton
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={toggle}
                    bg={`dark.bg`}
                    color={`dark.text`}
                    borderRadius={'100%'}
                    position="absolute"
                    zIndex={4}
                    border={0}
                    m={0}
                    p={0}
                    fontSize="1.25rem"
                    fontWeight={700}
                    top={-10}
                    right={-15}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      aria-hidden="true"
                      src={closeBtn}
                      height="auto"
                      width="85%"
                    />
                  </ModalDismissButton>
                )}
                {children}
              </ModalBodyContent>
            </ModalContainer>,
            document.body
          )
        : null}
      {thumbnail ? (
        <StyledVideoThumbnailContainer onClick={toggle} position="relative">
          {contentType.toLowerCase().includes('video') && (
            <PlayButton maxWidth="80px" alt="play" />
          )}
          <StyledImage
            fluid={{
              src: `https:${thumbnail}`,
              sizes: '',
              srcSet: '',
              base64: '',
              aspectRatio: 0,
            }}
            theme={theme}
            minHeight="33vh"
            width="100%"
            zIndex={0}
            alt={alt}
          />
        </StyledVideoThumbnailContainer>
      ) : (
        <ThemeSwitchButton theme={theme} onClick={toggle}>
          {label}
        </ThemeSwitchButton>
      )}
    </>
  )
}

ModalSimple.propTypes = {
  theme: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default ModalSimple
