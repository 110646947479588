import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const StyledSlider = styled(Slider)``

export const CarouselContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
`

export const Container = styled.div`
 box-sizing: border-box;
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  min-height: ${({ isMobile }) => isMobile && '55vh'};
`

export const ImageContainer = styled.div`
  margin-right: ${({ isMobile }) => !isMobile && '25px'};
  width: 248px;
  height: 248px;
  ${layout}
`
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: ${({ isMobile }) => isMobile && 'center'};
  ${space}
  ${layout}
`

export const QuoteContainer = styled.div`
  max-height: 100%;
  ${color}
  ${typography}
`

export const StyledSvgContainer = styled.div`
  cursor: pointer;
  ${position}
  ${layout}
  ${color}
  ${space}
  ${({ transform }) => transform && `transform: ${transform};`}
`

export const StyledSvg = styled.img`
  ${({ transform }) => transform && `transform: ${transform};`}
  ${position}
  ${layout}
  ${space}
  ${color}
`

export const StyledArrowContainer = styled(Container)``

export const StyledButtonWidth = styled(Container)``

export const StyledButtonContainer = styled(Container)`
  transition: padding 0.2s;
`

export const StyledButton = styled(Container)``
