import React from 'react'

import { StyledIcon, LineLeft, LineRight } from './styles.js'

const ScrollIcon = ({ bg }) => (
  <StyledIcon height="20px" width="20px" position="absolute" margin zIndex={1}>
    <LineLeft bg={bg} />
    <LineRight bg={bg} />
  </StyledIcon>
)
export default ScrollIcon
