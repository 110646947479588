/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, P, B, U, I, Cell } from '../styles.js'
import EmbeddedEntry from '../../../containers/EmbeddedEntryContainer'

const ParagraphBlock = ({ children, node, shortenDescription, theme }) => {
  if (shortenDescription) {
    children.forEach(c => {
      if (shortenDescription && c[1] !== '') children = shortenDescription
    })
  }
  return (
    <P
      color={`${theme}.text`}
      fontSize={[0, 0, 0, 0, 0, 0, 0]}
      fontFamily="headings"
      lineHeight="1.4"
      fontWeight={200}
      letterSpacing="0.23px"
      textShadow={theme === 'dark' && 'text'}
      w={[1]}
      px={[0, 0, 0, 0]}
    >
      {children}
    </P>
  )
}

const options = ({ theme, shortenDescription, isMobile, bgContentType }) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <ParagraphBlock
        node={node}
        theme={theme}
        shortenDescription={shortenDescription}
      >
        {children}
      </ParagraphBlock>
    ),
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <H1
          color={theme === 'dark' ? `${theme}.text` : 'black'}
          fontSize={[2, 1, 1, 1, 1, 1]}
          w={[1]}
          fontFamily="body"
          letterSpacing="0.3px"
          fontWeight={600}
          textShadow={theme === 'dark' && 'text'}
          m={0}
          marginTop="0px"
          lineHeight={[0]}
        >
          {children}
        </H1>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        color={`${theme}.text`}
        textShadow={theme === 'dark' && 'text'}
        letterSpacing="0.2px"
        fontSize={[3, 2, 2, 2, 2, 2, 2]}
        fontFamily="body"
        fontWeight={600}
        m={0}
        textAlign={isMobile && theme === 'light' && 'left'}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[4, 3, 3, 3, 3, 3, 3]}
        fontFamily="body"
        lineHeight={1.4}
        fontWeight={400}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4
        color={`${theme}.text`}
        fontSize={[4, 3, 3, 3, 3, 3, 3]}
        fontFamily="body"
        fontWeight={600}
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <H5
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[6, 5, 5, 5, 5, 5, 5]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={300}
        letterSpacing="0.18px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <H6
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[6]}
        fontFamily="body"
        lineHeight={1.4}
        fontWeight={400}
        letterSpacing="0.18px"
        textShadow={theme === 'dark' && 'text'}
        paddingBottom="16px"
        paddingTop="16px"
        m="0px"
        textAlign={isMobile && theme === 'light' && 'left'}
      >
        {children}
      </H6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => (
      <Cell
        color={`${theme}.text`}
        textShadow={theme === 'dark' && 'text'}
        letterSpacing="0.1rem"
        fontSize={[3, 2, 2, 2, 2, 2, 2]}
        fontFamily="body"
        fontWeight={theme === 'dark' ? 600 : 400}
        m={0}
      >
        {children}
      </Cell>
    ),
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <EmbeddedEntry node={node} theme={theme} bgContentType={bgContentType}>
        {children}
      </EmbeddedEntry>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <EmbeddedEntry node={node} theme={theme} bgContentType={bgContentType}>
        {children}
      </EmbeddedEntry>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <EmbeddedEntry node={node} theme={theme} bgContentType={bgContentType}>
        {children}
      </EmbeddedEntry>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedEntry node={node} theme={theme} bgContentType={bgContentType}>
        {children}
      </EmbeddedEntry>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontWeight={600}
      >
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => (
      <I color={`${theme}.text`} fontWeight={400}>
        {text}
      </I>
    ),
    [MARKS.UNDERLINE]: text => (
      <U color={`${theme}.text`} fontWeight={600}>
        {text}
      </U>
    ),
  },
})

export const renderRichText = ({
  richText,
  theme,
  shortenDescription,
  isMobile,
  bgContentType,
}) =>
  documentToReactComponents(
    richText,
    options({ theme, shortenDescription, isMobile, bgContentType })
  )
