import React from 'react'
import PropTypes from 'prop-types'
import CarouselSlickXs from '../../components/CarouselSlickXs/CarouselSlickXs'
import { useMobile, useComponentTheme, useWindowReady } from '../../hooks'
import { mappedString } from '../../utils'

// Carousel component with settings, provide an array of objects with fluid props
const CarouselXsContainer = ({
  theme: themeBool = true,
  autoPlay,
  minHeight = '40vh',
  galleryItems = [],
  ...rest
}) => {
  const { theme } = useComponentTheme(themeBool)
  const [isMobile] = useMobile()
  const windowReady = useWindowReady()

  const mappedGalleryItems = galleryItems.map(g => {
    const thumbnail =
      (g.media &&
        g.media[0] &&
        g.media[0].srcContentful &&
        g.media[0].srcContentful.fluid &&
        g.media[0].srcContentful.fluid.src) ||
      (g.media &&
        g.media[0] &&
        g.media[0].thumbnail &&
        g.media[0].thumbnail.fluid &&
        g.media[0].thumbnail.fluid.src)

    const stringArr =
      g &&
      g.description &&
      g.description.json &&
      mappedString(g.description.json.content, 'text')
    let turnIntoString = stringArr && stringArr.join(' ')
    if (turnIntoString && turnIntoString.split(' ').length > 30) {
      turnIntoString = turnIntoString
        .split(' ')
        .slice(0, 30)
        .join(' ')
    } else {
      turnIntoString = null
    }
    return (
      g && {
        ...g,
        fluid: thumbnail,
        shortenDescription: turnIntoString && `${turnIntoString}...`,
      }
    )
  })

  if (!windowReady) return null
  return (
    <CarouselSlickXs
      autoPlay={autoPlay}
      isMobile={isMobile}
      theme={theme}
      galleryItems={mappedGalleryItems}
      minHeight={minHeight}
      {...rest}
    />
  )
}

CarouselXsContainer.propTypes = {
  theme: PropTypes.bool,
  autoPlay: PropTypes.bool,
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnail: PropTypes.object,
      media: PropTypes.array,
      description: PropTypes.exact({
        json: PropTypes.exact({
          data: PropTypes.object,
          content: PropTypes.arrayOf(PropTypes.object).isRequired,
          nodeType: PropTypes.string.isRequired,
        }),
      }),
    })
  ).isRequired,
}

export default CarouselXsContainer
