import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { getVideoType, mappedContent, verticalObj } from '../../utils'
import VideoComponent from './VideoComponent.js'
import OverlayText from './OverlayText.js'

import { Cell } from './../Image/styles.js'
import { ThemeContext } from 'styled-components'
import { richText } from './../RichText/index'

const VideoContainer = ({
  theme = false,
  media = [],
  overlayCopy,
  overlay,
  minHeight = null,
  id,
  title,
  modal = false,
  bladeHeight,
  textAlignmentHorizontal = 'center',
  textAlignmentVertical = 'center',
}) => {
  const themePackage = useContext(ThemeContext)
  const findModal = overlayCopy && mappedContent(overlayCopy.content, 'modal')

  theme = theme ? 'light' : 'dark'
  overlay = theme === 'dark'

  const us = 'en-US'
  const thumbnail =
    (media &&
      media[0] &&
      media[0].thumbnail &&
      media[0].thumbnail.fluid &&
      media[0].thumbnail.fluid.src) ||
    (media &&
      media.thumbnail &&
      media.thumbnail[us] &&
      media.thumbnail[us].fields &&
      media.thumbnail[us].fields.file &&
      media.thumbnail[us].fields.file[us] &&
      media.thumbnail[us].fields.file[us].url) ||
    (media &&
      media[0] &&
      media[0].thumbnail &&
      media[0].thumbnail.fluid &&
      media[0].thumbnail.fluid.src)

  const referenceId =
    (media && media[0] && media[0].contentful_id) ||
    (media && media.contentful_id) ||
    id

  // directly from blades or coming from render rich text ie) blades - text/media -> inline render rich text
  const externalVideo =
    (media && media[0] && media[0].srcUrl) ||
    (media && media.srcUrl && media.srcUrl) ||
    (media && media.srcUrl && media.srcUrl[us])

  const internalVideoSrcUrl =
    (media &&
      media[0] &&
      media[0].srcContentful &&
      media[0].srcContentful.file &&
      media[0].srcContentful.file.url) ||
    (media &&
      media.srcContentful &&
      media.srcContentful[us].fields &&
      media.srcContentful[us].fields.file &&
      media.srcContentful[us].fields.file[us] &&
      media.srcContentful[us].fields.file[us].url)

  const externalVideoSrcUrl = externalVideo && getVideoType(externalVideo)
  const bgType = externalVideoSrcUrl && 'video-external'

  if (findModal && findModal.length === 1) {
    return (
      <Cell width={[1]} textAlign="center">
        {overlayCopy && richText(themePackage, overlayCopy, theme)}
      </Cell>
    )
  }
  if (!internalVideoSrcUrl && !externalVideoSrcUrl) return null
  return (
    <VideoComponent
      externalVideoSrcUrl={externalVideo && externalVideoSrcUrl}
      internalVideoSrcUrl={internalVideoSrcUrl}
      minHeight={bladeHeight}
      overlay={overlay}
      referenceId={referenceId || 'vid-id'}
      theme={theme}
      thumbnail={thumbnail}
      title={title}
      modal={modal}
      src={externalVideoSrcUrl || internalVideoSrcUrl}
      bgType={bgType}
    >
      {overlayCopy && (
        <OverlayText
          overlayCopy={overlayCopy}
          textAlign={textAlignmentHorizontal}
          alignItems={verticalObj[textAlignmentVertical]}
          themePackage={themePackage}
        />
      )}
    </VideoComponent>
  )
}

VideoContainer.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.bool.isRequired,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string.isRequired,
  }),
  media: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      src: PropTypes.string,
      srcContentful: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
      thumbnail: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
    })
  ),
}

export default VideoContainer
