import styled from 'styled-components'
import { sizes } from '../../themes/coh'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
export const StyledTest = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export const StyledListWrapper = styled(Container)``

export const StyledWidthContainer = styled(Container)`
  max-width: ${sizes.large.max}px;
  ${layout}
`
export const StyledTitle = styled.h1`
  ${space}    
  ${layout}
  ${typography}
  ${color}
  ${shadow}
`

export const StyledSvgContainer = styled(Container)`
  position: relative;
`

export const StyledSvg = styled.img`
  opacity: 1;
  position: absolute;
  object-fit: contain;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledSvgHover = styled(StyledSvg)`
  opacity: 0;
  transition: opacity 0.2s linear;
`

export const StyledMobileSvg = styled(StyledSvg)``

export const StyledItemContainer = styled(Container)`
  ${border}
  ${color}

  &:hover ${StyledSvgHover} {
    opacity: 1;
    transition: opacity 0.2s linear;
  }
`

export const StyledListItem = styled.li`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledButtonContainer = styled(Container)`
  cursor: pointer;
`

export const StyledItemText = styled.p`
  margin: 0;
  padding: 0;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledItemTitle = styled(StyledItemText)`
  text-decoration: underline solid transparent;
  transition: text-decoration 0.2s;
`

export const StyledItemSubtitle = styled(StyledItemText)``

export const StyledListUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  
  & a {
    ${StyledItemContainer} {
      background-image: linear-gradient(
        to right,
        currentcolor 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
    }
  
    &:hover ${StyledItemTitle} {
      text-decoration: underline solid currentcolor;
    }
  }

  & ${StyledListItem}:last-child ${StyledItemContainer} {
    background: none;
  }
`
