import React from 'react'
import GridSimple from '../../components/Grid/GridSimple/GridSimple'

const GridSimpleContainer = ({ galleryItems = [], ...rest }) => {
  galleryItems = galleryItems.map(({ media, description, ...rest }) => ({
    ...rest,
    media,
    image: media[0]?.srcContentful?.fluid?.src,
    to: media[0]?.navigationLink?.navigationLink?.slug,
    href: media[0]?.navigationLink?.navigationLink?.url,
    thumbnail: media[0]?.thumbnail?.fluid?.src,
    description: description.json,
    typename: media[0].__typename,
  }))

  return <GridSimple galleryItems={galleryItems} {...rest} />
}

export default GridSimpleContainer
