import React, { useEffect, useState, useContext } from 'react'
// import PropTypes from 'prop-types'
import CarouselSlick from '../../components/CarouselSlick'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
} from '../../hooks/usePreviewQuery'
import { PreviewAssetsContext } from '../../context'
import {
  filterAssetsObjFromAssetsArrByIdAndReturnFluid,
  filterAssetsObjFromAssetsArrById,
} from '../../utils'
import CarouselSlickInline from '../../components/CarouselSlickInline'

const CarouselContainerWithAssets = ({
  galleryItems,
  mediaIds,
  type,
  ...rest
}) => {
  const { assets } = useContext(PreviewAssetsContext)
  const {
    loading: loadingImage,
    error: errorImage,
    data: dataImage,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    id: 'mediaImage',
    ids: mediaIds.join(','),
    skip: !mediaIds,
  })
  const {
    loading: loadingVideo,
    error: errorVideo,
    data: dataVideo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    id: 'mediaVideo',
    ids: mediaIds.join(','),
    skip: !mediaIds,
  })
  const [mediaImageItems, setMediaImageItems] = useState([])
  const [mediaVideoItems, setMediaVideoItems] = useState([])
  const [gItems, setGItems] = useState([])

  useEffect(() => {
    if (!loadingImage && !errorImage && dataImage) {
      let mediaImageItems = dataImage?.entries?.items?.map(
        ({ sys, fields }) => ({
          id: sys.id,
          __typename: 'ContentfulMediaImage',
          title: fields.title,
          srcContentful: filterAssetsObjFromAssetsArrByIdAndReturnFluid({
            id: fields.srcContentful.sys.id,
            assetsArr: assets,
          }),
          thumbnail: filterAssetsObjFromAssetsArrByIdAndReturnFluid({
            id: fields.thumbnail.sys.id,
            assetsArr: assets,
          }),
        })
      )
      setMediaImageItems(mediaImageItems)
    }
  }, [loadingImage, dataImage, errorImage, assets])

  useEffect(() => {
    if (!loadingVideo && !errorVideo && dataVideo) {
      let mediaVideoItems = dataVideo?.entries?.items?.map(
        ({ sys, fields }) => ({
          id: sys.id,
          __typename: 'ContentfulMediaVideo',
          title: fields.title,
          srcContentful: filterAssetsObjFromAssetsArrById({
            id: fields.srcContentful.sys.id,
            assetsArr: assets,
          }),
          thumbnail: filterAssetsObjFromAssetsArrById({
            id: fields.thumbnail.sys.id,
            assetsArr: assets,
          }),
        })
      )
      setMediaVideoItems(mediaVideoItems)
    }
  }, [loadingVideo, dataVideo, errorVideo, assets])

  useEffect(() => {
    if (mediaImageItems.length >= 1) {
      let gItems = galleryItems.map(({ media, ...rest }) => ({
        media: [mediaImageItems.filter(({ id }) => id === media[0].sys.id)[0]],
        ...rest,
      }))
      setGItems(gItems)
    }
    if (mediaVideoItems.length >= 1) {
    }
  }, [mediaImageItems, mediaVideoItems, galleryItems])

  if (loadingImage || loadingVideo) return null
  if (errorVideo || errorImage) return `  `
  if (dataImage && !loadingImage && !loadingVideo) {
    return type === 'inline' ? (
      <CarouselSlickInline {...rest} galleryItems={gItems} />
    ) : (
      <CarouselSlick {...rest} galleryItems={gItems} />
    )
  } else {
    return <div />
  }
}

const CarouselContainer = ({ galleryItems, ...rest }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'galleryItem',
  })
  const filterLinkedIdsFromArr = ({ arr }) => {
    if (!arr) {
      return []
    }
    return arr.map(({ sys }) => sys.id)
  }
  const [galleryItemIds, setGalleryItemIds] = useState(
    filterLinkedIdsFromArr({ arr: galleryItems })
  )
  const [gItems, setGItems] = useState([])
  const [gItemMediaIds, setGItemMediaIds] = useState([])

  const filterItemsByIdArr = ({ idArr, arr }) => {
    if (!idArr || !arr) {
      return []
    }
    return arr.filter(({ sys }) => idArr.includes(sys.id))
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const gItems = filterItemsByIdArr({
        idArr: galleryItemIds,
        arr: data.entries.items,
      }).map(({ sys, fields }) => ({
        name: fields.name,
        description: {
          json: fields.description,
        },
        media: fields.media,
        id: sys.id,
      }))
      setGItems(gItems)
    }
  }, [loading, data, error, galleryItemIds])

  useEffect(() => {
    if (gItems.length >= 1) {
      const mItemIds = gItems.map(({ media }) => media[0].sys.id)
      setGItemMediaIds(mItemIds)
    }
  }, [gItems])

  return (
    <CarouselContainerWithAssets
      galleryItems={gItems}
      mediaIds={gItemMediaIds}
      {...rest}
    />
  )
}

CarouselContainer.propTypes = {}

export default CarouselContainer
