import React from 'react'
import PropTypes from 'prop-types'
import { GridContainer } from './styles'
import GridItem from './GridItem'
import { sizes } from '../../../themes'

const GridBody = ({ galleryItems = [], theme }) => {
  return (
    <GridContainer
      maxWidth={['350px', sizes.small.max, sizes.large.max]}
      margin="0 auto"
    >
      {galleryItems.map(({ id, ...rest }) => (
        <GridItem key={id} theme={theme} {...rest} />
      ))}
    </GridContainer>
  )
}
GridBody.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.string,
}

export default GridBody
