import React from 'react'
import List from '.'
import { A } from '../Button'
import ListItem from './ListItem'
import PropTypes from 'prop-types'
import { useComponentTheme } from '../../hooks'
import { StyledListItem } from './styles'

const DownloadList = ({ listItems = [], theme: themeBool, title }) => {
  const { theme } = useComponentTheme(themeBool)

  return (
    <List dataTestid="download-list" theme={theme} title={title}>
      {listItems.map(({ id, src, ...rest }) => (
        <StyledListItem key={id}>
          <A
            data-testid="downloadlist-items"
            href={src && `https://${src}`}
            download
          >
            <ListItem theme={theme} {...rest} />
          </A>
        </StyledListItem>
      ))}
    </List>
  )
}

DownloadList.propTypes = {
  /**
    List Item Info
  */
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
      }),
      navigationLink: PropTypes.string,
      src: PropTypes.string,
    })
  ),

  theme: PropTypes.bool,
  title: PropTypes.string,
}

export default DownloadList
