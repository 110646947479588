import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRY,
  GET_NAVIGATION_ITEMS,
} from '../../hooks/usePreviewQuery'
import { BladeApolloCPAContainer } from '../BladeContainer'
import { TimelineEntryApolloCPAContainer } from '../TimelineEntryContainer'
import { filterLinkedIds } from '../../utils'

const ProjectListContainerApollo = props => {
  const [bladeIds, setBladeIds] = useState([])
  const [timelineEntryIds, setTimelineEntryIds] = useState([])
  const [projectId, setProjectId] = useState(null)
  const {
    loading: loadingNavItems,
    error: errorNavItems,
    data: dataNavItems,
  } = usePreviewQuery({
    query: GET_NAVIGATION_ITEMS,
  })
  const {
    loading: loadingProject,
    error: errorProject,
    data: dataProject,
  } = usePreviewQuery({
    query: GET_ENTRY,
    id: projectId,
    skip: !projectId,
  })

  const getMappedNavItemEntryLinkId = useCallback((data, entryIncludes, id) => {
    const mapItem = entryIncludes.filter(
      ({ sys: { id: itemId } }) => itemId === id
    )[0]
    const mapId = mapItem.fields?.navigationLink?.sys?.id

    return mapId
  }, [])

  useEffect(() => {
    if (!loadingNavItems && !errorNavItems && dataNavItems) {
      const {
        entries: {
          includes: { Entry },
          items,
        },
      } = dataNavItems

      const { navItems } = items[0]?.fields
      const navId = filterLinkedIds({
        dataObj: dataNavItems.entries.items[0],
        key: 'navItems',
      })[0]?.id
      const id = getMappedNavItemEntryLinkId(navItems, Entry, navId)
      setProjectId(id)
    }
  }, [
    errorNavItems,
    loadingNavItems,
    dataNavItems,
    getMappedNavItemEntryLinkId,
  ])

  useEffect(() => {
    if (!loadingProject && !errorProject && dataProject) {
      const bIds = filterLinkedIds({
        dataObj: dataProject.entry,
        key: 'blades',
      })
      setBladeIds(bIds)
      const tIds = filterLinkedIds({
        dataObj: dataProject.entry,
        key: 'timelineEntries',
      })
      setTimelineEntryIds(tIds)
    }
  }, [loadingProject, dataProject, errorProject, projectId])

  return (
    <>
      {bladeIds.length >= 1 &&
        bladeIds.map((item, i) => (
          <BladeApolloCPAContainer key={i} id={item.id} order={i + 1} />
        ))}
      {timelineEntryIds.length >= 1 && (
        <TimelineEntryApolloCPAContainer
          ids={timelineEntryIds.map(item => item.id).join(',')}
        />
      )}
    </>
  )
}

ProjectListContainerApollo.propTypes = {}

export default ProjectListContainerApollo
