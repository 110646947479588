import React from 'react'
import PropTypes from 'prop-types'
import GridHeader from './GridHeader'
import GridBody from './GridBody'
import { BG } from '../GridToggle/styles'
import useTheme from '../../../hooks/useComponentTheme'

const GridSimple = ({
  title,
  subtitle,
  galleryItems,
  theme: themeBool,
  ...rest
}) => {
  const { theme } = useTheme(themeBool)

  return (
    <BG
      data-testid="grid-simple"
      bg={`${theme}.bgAlt`}
      color={`${theme}.text`}
      margin="0 auto"
      py={4}
      textAlign="center"
    >
      <GridHeader title={title} subtitle={subtitle} {...rest} />
      <GridBody galleryItems={galleryItems} theme={theme} {...rest} />
    </BG>
  )
}
GridSimple.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
  galleryItems: PropTypes.arrayOf(PropTypes.object),
}

export default GridSimple
