import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Video from '../../../components/Video'
import { PreviewAssetsContext } from '../../../context'
import { formatVideoMediaArr } from '../../../utils'

const VideoContainer = ({ overlayCopy, theme, mediaObj, assetId, ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)
  let mediaArr = formatVideoMediaArr({
    assets,
    mediaObj,
    assetId,
  })
  return (
    <Video overlayCopy={overlayCopy} theme={theme} media={mediaArr} {...rest} />
  )
}

VideoContainer.propTypes = {}

export default VideoContainer
