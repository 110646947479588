import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  StyledPreviewContainer,
  StyledText,
  StyledPreviewContent,
  StyledHighlightList,
  StyledListItem,
  StyledContainer,
} from '../styles'
import Icon from '../../Icon'

const Preview = ({ title = 'Location', tags = [], icon, handleClick }) => {
  return (
    <StyledPreviewContainer borderBottom="1px solid #D9D9DD" py={3}>
      <StyledPreviewContent onClick={handleClick}>
        <StyledContainer display="flex" alignItems="flex-start">
          <Icon {...icon} height="auto" width="13px" m={2} ml={1} />
          <StyledText py={1} fontSize={9} fontWeight={400}>
            {title}
          </StyledText>
        </StyledContainer>
        <StyledHighlightList>
          {tags &&
            tags.map(({ id, title, icon, customIcon }) => (
              <StyledListItem
                key={id}
                pl={3}
                ml={2}
                py={1}
                display="flex"
                alignItems="center"
              >
                <Icon
                  icon={icon}
                  customIcon={customIcon}
                  height="auto"
                  width="15px"
                  m={1}
                  ml={0}
                />
                <StyledText ml={2} fontSize={9} fontWeight={400}>
                  {title}
                </StyledText>
              </StyledListItem>
            ))}
        </StyledHighlightList>
      </StyledPreviewContent>
    </StyledPreviewContainer>
  )
}

Preview.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      customIcon: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
      icon: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  icon: PropTypes.shape({
    customIcon: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.object,
    }),
    icon: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  handleClick: PropTypes.func,
}

export default Preview
