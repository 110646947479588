import React, { useState, useEffect, useCallback } from 'react'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../hooks/usePreviewQuery'
import Map from '../../components/Map'

const MapContainerApollo = props => {
  const {
    loading: loadingMapItems,
    error: errorMapItems,
    data: dataMapItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'mapItem',
  })
  const {
    loading: loadingSiteInfo,
    error: errorSiteInfo,
    data: dataSiteInfo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'siteInfo',
  })
  const {
    loading: loadingClosures,
    error: errorClosures,
    data: dataClosures,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'closureAlert',
  })

  const [mapItems, setMapItems] = useState([])
  const [closures, setClosures] = useState([])
  const [siteInfo, setSiteInfo] = useState(null)

  const createImgSrc = file => ({
    fluid: {
      src: file?.url,
      sizes: '(max-width: 1460px) 100vw, 1460px',
      srcSet: '',
      base64: '',
      aspectRatio: 0.8,
    },
  })

  const getLinkedEntry = (arr, targetId) =>
    targetId && arr.find(({ sys: { id } }) => id === targetId)

  const getMappedAsset = useCallback((arr, targetId) => {
    let mappedCustomIcon = getLinkedEntry(arr, targetId)?.fields
    mappedCustomIcon = createImgSrc(mappedCustomIcon?.file)
    return mappedCustomIcon
  }, [])

  const getMappedCategory = useCallback(
    (category, includes) => {
      const { Entry, Asset } = includes
      const mappedCategory = getLinkedEntry(Entry, category?.sys?.id)
      let icon = getLinkedEntry(Entry, mappedCategory?.fields?.icon?.sys?.id)
        ?.fields
      const customIcon = getMappedAsset(Asset, icon?.customIcon?.sys?.id)
      icon = Object.assign({}, { ...icon, customIcon })
      const marker = getMappedAsset(
        Asset,
        mappedCategory?.fields?.marker?.sys?.id
      )
      return Object.assign(
        {},
        { ...mappedCategory?.fields, id: mappedCategory?.sys?.id, icon, marker }
      )
    },
    [getMappedAsset]
  )

  const getMappedIconList = useCallback(
    (icons = [], includes) => {
      const { Entry, Asset } = includes

      let mappedIconList = icons
        .map(({ sys: { id } }) => getLinkedEntry(Entry, id))
        .map(
          ({
            fields: {
              customIcon: {
                sys: { id },
              },
              ...rest
            },
          }) => {
            return {
              customIcon: getMappedAsset(Asset, id),
              id,
              ...rest,
            }
          }
        )
      return mappedIconList
    },
    [getMappedAsset]
  )

  const getFeatureCollections = useCallback(rawFeatureCollections => {
    const categoryMap = {}
    if (rawFeatureCollections) {
      rawFeatureCollections.forEach(({ category, coordinates, ...rest }) => {
        if (!categoryMap[category.title]) {
          categoryMap[category.title] = { ...category, features: [] }
        }
        categoryMap[category.title].features.push({
          ...coordinates,
          properties: {
            ...rest,

            icon: category?.icon,
          },
        })
      })
    }

    return Object.values(categoryMap)
  }, [])

  useEffect(() => {
    if (!loadingMapItems && !errorMapItems && dataMapItems) {
      const {
        entries: { includes, items },
      } = dataMapItems

      let mappedItems = items.map(
        ({
          fields: { category, tags, closure, nearbyParkingAndTransit, ...rest },
        }) => {
          const mappedCategory = getMappedCategory(category, includes)
          const mappedTags = getMappedIconList(tags, includes)
          const mappedNearbyParkingAndTransit = getMappedIconList(
            nearbyParkingAndTransit,
            includes
          )
          const mappedClosure = getLinkedEntry(
            includes?.Entry,
            closure?.sys?.id
          )?.fields

          return {
            category: mappedCategory,
            closure: mappedClosure,
            tags: mappedTags,
            nearbyParkingAndTransit: mappedNearbyParkingAndTransit,
            ...rest,
          }
        }
      )

      setMapItems(getFeatureCollections(mappedItems))
    }
  }, [
    errorMapItems,
    loadingMapItems,
    dataMapItems,
    getMappedCategory,
    getMappedIconList,
    getFeatureCollections,
  ])

  useEffect(() => {
    if (!loadingSiteInfo && !errorSiteInfo && dataSiteInfo) {
      const {
        entries: { items },
      } = dataSiteInfo

      setSiteInfo(items?.[0]?.fields)
    }
  }, [errorSiteInfo, loadingSiteInfo, dataSiteInfo])

  useEffect(() => {
    if (!loadingClosures && !errorClosures && dataClosures) {
      let closures = dataClosures?.entries?.items.map(({ sys, fields }) => ({
        description: fields.description,
        publicationDate: fields.publicationDate,
        title: fields.title,
        displayText: fields.displayText,
        id: sys.id,
      }))
      setClosures(closures)
    }
  }, [errorClosures, loadingClosures, dataClosures])

  return (
    <Map
      featureCollections={mapItems}
      siteInfo={siteInfo}
      closures={closures}
      {...props}
    />
  )
}

export default MapContainerApollo
