import styled from 'styled-components'
import {
  color,
  space,
  layout,
  flexbox,
  typography,
  border,
  position,
} from 'styled-system'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { A } from '../Button'

export const StyledCards = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
`
export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledDetailsContainer = styled(Container)`
  box-sizing: border-box;
`

export const StyledSectionName = styled.p`
  ${color}
  ${flexbox}
  ${typography}
  ${space}
`
export const StyledButton = styled(A)`
  
  border: 1px solid;
  border-radius: 30px;
  &:hover{
    ${({ btnhover }) => btnhover && `background-color: ${btnhover}`};
    box-shadow : 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  ${color} 
  ${flexbox} 
  ${typography}
  ${border}
  ${layout}
  ${space}
`

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${color}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
`

export const StyledWidthContainer = styled(StyledContainer)``

export const StyledImage = styled(BackgroundImage)`
  ${space}
  ${layout}
  ${flexbox}
`

export const StyledImageContainer = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
`

export const StyledCard = styled(A)`
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
  ${color} 
  ${space} 
  ${layout}
  ${border}
  ${flexbox}
`

export const StyledTitle = styled.p`
  font-weight: 300;
  ${color} 
  ${space} 
  ${layout} 
  ${typography}
  ${flexbox}
`
export const StyledCategoryContainer = styled.div`
  box-sizing: border-box;
  ${color}
  ${border}
  ${space}
  ${layout}
  ${flexbox}
`

export const StyledDetails = styled.p`
  font-weight: 200;
  margin: ${({ isDesktop }) => !isDesktop && '0px'};
  margin-bottom: ${({ isDesktop }) => !isDesktop && '20px'};
  ${flexbox}
  ${typography}
  ${border}
  ${layout}
  ${color}
`

export const StyledIntro = styled.p`
${color}
  ${layout}
  ${flexbox}
  ${typography}
  ${space}
`

export const StyledDirect = styled.p`
  ${color}
  ${flexbox}
  ${typography}
  ${space}
`

export const StyledFeatured = styled.p`
  ${color}
  ${space}
  ${flexbox}
  ${typography}
`
export const StyledExtend = styled.div`
  cursor: pointer;
  ${layout}
  ${color}
  ${space}
  ${flexbox}
  ${typography}
`
export const StyledExtendText = styled.p``

export const StyledLink = styled(Link)`
${color}
${space}
${flexbox}
${typography}
${layout}
`
export const StyledCardShadow = styled(Container)`
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
`

export const StyledArrow = styled(Container)`
  width: 7px;
  height: 7px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(135deg);
`

export const StyledArrowLeft = styled(Container)`
  width: 7px;
  height: 7px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
`
export const StyledTitleCategoryContainer = styled(Container)``

export const StyledIntroCTAContainer = styled(Container)``

export const StyledCardContainer = styled(Container)`
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  ${flexbox}
`
