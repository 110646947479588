import React, { useContext } from 'react'
import { PreviewContext } from '../../context'
import EmbeddedEntryApolloCPAContainer from './EmbeddedEntryApolloCPAContainer'
import EmbeddedEntryBaseContainer from './EmbeddedEntryBaseContainer'
import { removeLocaleNesting } from '../../utils'

const removeNestedLocaleInFields = ({ fieldsObj, locale }) => {
  const removeKey = ({ object, key }) =>
    Object.keys(object).map(k =>
      object && object[k] && object[k][key]
        ? { [k]: object[k][key] }
        : { [k]: object[k] }
    )
  let arrOfKeyValuePairs = removeKey({
    object: fieldsObj,
    key: locale,
  })
  return Object.assign(...arrOfKeyValuePairs)
}

const EmbeddedEntryContainer = ({ node, children, theme, bgContentType }) => {
  const { preview } = useContext(PreviewContext)

  let p = preview

  if (p) {
    return (
      <EmbeddedEntryApolloCPAContainer
        node={node}
        theme={theme}
        bgContentType={bgContentType}
      >
        {children}
      </EmbeddedEntryApolloCPAContainer>
    )
  } else {
    let richTextNode = node && removeLocaleNesting({ node, localeKey: 'en-US' })
    if (richTextNode?.data?.target?.fields?.mediaReference?.fields) {
      let fieldsObj = removeNestedLocaleInFields({
        fieldsObj: richTextNode?.data?.target?.fields?.mediaReference?.fields,
        locale: 'en-US',
      })
      if (fieldsObj.srcContentful?.fields) {
        fieldsObj.srcContentful = removeNestedLocaleInFields({
          fieldsObj: fieldsObj.srcContentful.fields,
          locale: 'en-US',
        })
        fieldsObj.thumbnail = removeNestedLocaleInFields({
          fieldsObj: fieldsObj.thumbnail.fields,
          locale: 'en-US',
        })
      }
      richTextNode.data.target.fields.mediaReference.fields = fieldsObj
    }

    return (
      <EmbeddedEntryBaseContainer
        node={richTextNode}
        theme={theme}
        bgContentType={bgContentType}
      >
        {children}
      </EmbeddedEntryBaseContainer>
    )
  }
}

EmbeddedEntryContainer.propTypes = {}

export default EmbeddedEntryContainer
