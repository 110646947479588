import React from 'react'
import PropTypes from 'prop-types'
import { Cards } from '../Cards'
import { UpdatesList } from '../UpdatesList'
import { Instagram } from '../Instagram'
import BrandLogos from '../BrandLogos'
import ProjectList from '../ProjectList'
import NewsAndUpdates from '../NewsAndUpdates'
import Closures from '../Closures'
import { MapGatsbyCDAContainer as Map } from '../../containers/MapContainer'

const BladeCustom = ({ layout, ...rest }) => {
  switch (layout) {
    case 'News':
      return <Cards {...rest} showBtn />
    case 'Updates':
      return <UpdatesList {...rest} />
    case 'Expansion Timeline - Projects':
      return <ProjectList {...rest} />
    case 'News & Updates':
      return <NewsAndUpdates {...rest} />
    case 'Closures':
      return <Closures {...rest} />
    case 'Instagram':
      return <Instagram user="cityofhope" {...rest} />
    case 'Home Logos':
      return <BrandLogos {...rest} />
    case 'Map':
      return <Map {...rest} />
    default:
      return null
  }
}

BladeCustom.propTypes = {
  layout: PropTypes.string,
}

export default BladeCustom
