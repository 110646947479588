import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Cards } from '../../components/Cards'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../hooks/usePreviewQuery'
import { PreviewAssetsContext } from '../../context'
import {
  filterAssetsObjFromAssetsArrById,
  filterAssetsObjFromAssetsArrByIdAndReturnFluid,
} from '../../utils'

const NewsContainerApollo = ({ ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'news',
  })

  let news = {}

  news.news = data?.entries?.items.map(({ sys, fields }) => ({
    bodyCopy: {
      json: fields.bodyCopy,
    },
    categories: fields.categories,
    date: fields.date,
    heroImage:
      fields.heroImage &&
      filterAssetsObjFromAssetsArrByIdAndReturnFluid({
        id: fields.heroImage.sys.id,
        assetsArr: assets,
      }),
    ogImage:
      fields.ogImage &&
      filterAssetsObjFromAssetsArrById({
        id: fields.ogImage.sys.id,
        assetsArr: assets,
      }),
    intro: {
      intro: fields.intro,
    },
    slug: fields.slug,
    title: fields.title,
    id: sys.id,
  }))
  news.parentSlug = 'news-and-updates'
  if (loading) return 'Loading'
  if (error) return ''
  if (data) {
    return <Cards {...rest} news={news} showBtn />
  } else {
    return <div />
  }
}

NewsContainerApollo.propTypes = {}

export default NewsContainerApollo
