import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { PreviewAssetsContext } from '../../../context'
import usePreviewQuery, {
  GET_ENTRY,
  GET_REEL,
  GET_MEDIA,
} from '../../../hooks/usePreviewQuery'
import { filterAssetsObjFromAssetsArrById } from '../../../utils'
import GridItem from '../../../components/Grid/GridToggle/GridItem'

const CompanyItemWithAllFields = ({
  media,
  logo,
  reel,
  i,
  toggle,
  isMobile,
  active,
  setActive,
  t,
  item,
}) => {
  let mediaObjWithAssets = {
    id: media.id,
    title: media.title,
    srcContentful: {
      id: media?.srcContentful?.id,
      fluid: {
        src: media.srcContentful.file.url,
      },
    },
    thumbnail: {
      id: media.thumbnail.id,
      fluid: {
        src: media.thumbnail.file.url,
      },
    },
  }
  let mediaArr = [mediaObjWithAssets]

  return (
    <GridItem
      id={item.id}
      key={item.id}
      title={item.fields.title}
      shortDescription={item.fields.shortDescription}
      logo={logo}
      website={item.fields.website}
      email={item.fields.email}
      longDescription={item.fields.longDescription}
      media={mediaArr}
      reel={reel}
      i={i}
      t={t}
      toggle={toggle}
      isMobile={isMobile}
      active={active}
      setActive={setActive}
    />
  )
}

const CompanyItemWithMediaNoReel = ({ mediaId, logoId, item, ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)
  const { loading: loadingMedia, error: errMedia, data: dataMedia } = useQuery(
    GET_MEDIA,
    {
      variables: { id: mediaId },
      skip: mediaId === '0',
    }
  )

  if (loadingMedia) return null
  if (errMedia) return `  `
  if (dataMedia && !loadingMedia) {
    let mediaObj = dataMedia.entry.fields
    let mediaObjWithAssets = {
      id: mediaId,
      title: mediaObj.title,
      srcContentful: filterAssetsObjFromAssetsArrById({
        id: mediaObj.srcContentful.sys.id,
        assetsArr: assets,
      }),
      thumbnail:
        mediaObj &&
        mediaObj.thumbnail &&
        filterAssetsObjFromAssetsArrById({
          id: mediaObj.thumbnail.sys.id,
          assetsArr: assets,
        }),
    }

    let logoObj = filterAssetsObjFromAssetsArrById({
      id: logoId,
      assetsArr: assets,
    })

    return (
      <CompanyItemWithAllFields
        media={mediaObjWithAssets}
        logo={logoObj}
        item={item}
        {...rest}
      />
    )
  } else return <div />
}

const CompanyItemWithMediaWithReel = ({
  mediaId,
  reelId,
  logoId,
  item,
  ...rest
}) => {
  const { assets } = useContext(PreviewAssetsContext)
  const { loading: loadingReel, error: errReel, data: dataReel } = useQuery(
    GET_REEL,
    {
      variables: { id: reelId },
      skip: reelId === '0',
    }
  )
  const { loading: loadingMedia, error: errMedia, data: dataMedia } = useQuery(
    GET_MEDIA,
    {
      variables: { id: mediaId },
      skip: mediaId === '0',
    }
  )

  if (loadingMedia || loadingReel) return null
  if (errMedia || errReel) return `  `
  if (dataReel && !loadingReel && dataMedia && !loadingMedia) {
    let mediaObj = dataMedia.entry.fields
    let mediaObjWithAssets = {
      id: mediaId,
      title: mediaObj.title,
      srcContentful: filterAssetsObjFromAssetsArrById({
        id: mediaObj.srcContentful.sys.id,
        assetsArr: assets,
      }),
      thumbnail: filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      }),
    }

    let reelObj = dataReel.entry.fields
    let reelObjWithAssets = {
      id: reelId,
      title: reelObj.title,
      src: reelObj.src,
      srcUrl: reelObj.srcUrl,
    }

    let logoObj = filterAssetsObjFromAssetsArrById({
      id: logoId,
      assetsArr: assets,
    })

    return (
      <CompanyItemWithAllFields
        media={mediaObjWithAssets}
        logo={logoObj}
        reel={reelObjWithAssets}
        item={item}
        {...rest}
      />
    )
  } else return <div />
}

const CompanyItemContainer = ({ id, ...rest }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
  })
  const [logoId, setLogoId] = useState('0')
  const [reelId, setReelId] = useState('0')
  const [mediaId, setMediaId] = useState('0')

  const filterLinkedId = ({ dataObj, key }) => {
    if (!dataObj.fields[key]) {
      return '0'
    }
    return dataObj.fields[key].sys.id
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const logoId = filterLinkedId({ dataObj: data.entry, key: 'logo' })

      const reelId = filterLinkedId({ dataObj: data.entry, key: 'reel' })

      let mediaId =
        data.entry.fields &&
        data.entry.fields.media &&
        data.entry.fields.media[0].sys &&
        data.entry.fields.media[0].sys.id

      setLogoId(logoId)
      setReelId(reelId)
      setMediaId(mediaId)
    }
  }, [loading, data, error])

  if (loading) return null
  if (error) return ` `
  if (data && data.entry) {
    if (reelId !== '0') {
      return (
        <CompanyItemWithMediaWithReel
          logoId={logoId}
          reelId={reelId}
          mediaId={mediaId}
          item={data.entry}
          {...rest}
        />
      )
    } else {
      return (
        <CompanyItemWithMediaNoReel
          logoId={logoId}
          mediaId={mediaId}
          item={data.entry}
          {...rest}
        />
      )
    }
  } else {
    return <div />
  }
}

CompanyItemContainer.propTypes = {}

export default CompanyItemContainer
