import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GridSimple from '../../../components/Grid/GridSimple/GridSimple'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
} from '../../../hooks/usePreviewQuery'

const GridSimpleApolloContentfulPreviewContainer = ({
  galleryItemIds,
  theme,
  title,
  subtitle,
}) => {
  const [galleryItems, setGalleryItems] = useState([])
  const gItemIds = galleryItemIds.map(({ id }) => id)
  const { loading, error, data } = usePreviewQuery({
    id: 'galleryItem',
    ids: gItemIds.join(','),
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    skip: !gItemIds,
  })

  const filterArrById = ({ arr, id }) =>
    arr.filter(item => item?.sys?.id === id)[0]

  const handleThumbnail = ({ thumbnail }) => {
    return {
      id: thumbnail?.sys?.id,
      file: thumbnail?.fields?.file,
      fluid: {
        src: thumbnail?.fields?.file?.url,
      },
    }
  }
  const handleMedia = ({ mediaArr, assetsArr, mediaId }) => {
    let mediaObj = filterArrById({ arr: mediaArr, id: mediaId })

    const filteredObj =
      mediaObj?.fields.srcContentful &&
      filterArrById({
        arr: assetsArr,
        id: mediaObj?.fields.srcContentful?.sys?.id,
      })
    return {
      ...mediaObj?.fields,
      id: mediaObj?.sys?.id,
      srcContentful: { file: filteredObj?.fields?.file },
      thumbnail:
        mediaObj?.fields.thumbnail &&
        handleThumbnail({
          thumbnail: filterArrById({
            arr: assetsArr,
            id: mediaObj?.fields.thumbnail.sys?.id,
          }),
        }),
    }
  }

  useEffect(() => {
    if (data && data.entries && !loading) {
      let gEntries = data.entries.includes.Entry
      let gAssets = data.entries.includes.Asset

      let gItems = gItemIds.map(id => ({
        id,
        media: handleMedia({
          mediaArr: gEntries,
          assetsArr: gAssets,
          mediaId: data?.entries?.items.filter(item => item.sys?.id === id)[0]
            .fields?.media?.[0]?.sys?.id,
        }),
        description: data?.entries?.items.filter(item => item.sys?.id === id)[0]
          ?.fields?.description,
        theme,
        name: data?.entries?.items.filter(item => item.sys?.id === id)[0]
          ?.fields?.name,
      }))
      setGalleryItems(gItems)
    }
  }, [data, loading, error])

  if (loading) return ''
  if (error) return ` `
  if (data && !loading && data.entries) {
    return (
      <GridSimple
        theme={theme}
        galleryItems={galleryItems}
        title={title}
        subtitle={subtitle}
      />
    )
  } else {
    return null
  }
}

GridSimpleApolloContentfulPreviewContainer.propTypes = {}

export default GridSimpleApolloContentfulPreviewContainer
