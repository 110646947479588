import React from 'react'
import PropTypes from 'prop-types'
import { useHover } from '../../../hooks'
import {
  Tile,
  Grid,
  Overlay,
  GridLogo,
  GridCaption,
  ArrowContainer,
  ArrowUp,
} from './styles'
import logoLocal from '../../../assets/adotnetwork-logo.svg'

const GridItemThumbnail = ({
  toggle,
  shortDescription,
  logo,
  isMobile,
  bgImageUrl,
  active,
  i,
  id,
}) => {
  const [hoverRef, isHovered] = useHover()

  const svgUrl = logo && logo.file ? `https://${logo.file.url}` : logoLocal
  return (
    <>
      <Tile
        data-testid="grid-toggle-thumbnail"
        id={id}
        width="100%"
        position="relative"
        overflow="hidden"
        ref={hoverRef}
        isHovered={isHovered && !isMobile}
        active={active}
        onClick={() => toggle(i)}
      >
        <Grid
          backgroundImage={bgImageUrl}
          isHovered={isHovered && !isMobile}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Overlay
            width="100%"
            height="100%"
            opacity=".35"
            top="0"
            left="0"
            position="absolute"
            isHovered={isHovered && !isMobile}
            active={active}
          />
          <GridLogo
            padding="1rem"
            position="absolute"
            width={isMobile && '95%'}
            isHovered={isHovered && !isMobile}
            active={active}
          >
            <img src={svgUrl} alt="logo" />
          </GridLogo>
          <GridCaption
            fontWeight={600}
            padding="0 1em"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            isHovered={isHovered && !isMobile}
            active={active}
          >
            <p>{shortDescription}</p>
          </GridCaption>
        </Grid>
        <ArrowContainer
          active={active}
          isHovered={isHovered && !isMobile}
          position="absolute"
          bottom="0"
        >
          <ArrowUp />
        </ArrowContainer>
      </Tile>
    </>
  )
}

GridItemThumbnail.propTypes = {
  toggle: PropTypes.func,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.object,
  isMobile: PropTypes.bool,
  bgImageUrl: PropTypes.string,
  active: PropTypes.bool,
  i: PropTypes.number,
  id: PropTypes.string,
}

export default GridItemThumbnail
