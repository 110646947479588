import React from 'react'
import PropTypes from 'prop-types'

const ContentfulVideo = ({
  autoPlay,
  referenceId,
  showControls,
  src,
  title,
  thumbnail,
  minHeight,
}) => {
  return (
    <video
      width="100%"
      height="100%"
      id={`${referenceId}`}
      poster={thumbnail}
      controls={showControls}
      title={title}
      minHeight={minHeight}
    >
      <source src={`https:${src}`} type="video/webm" />
      <source src={`https:${src}`} type="video/mp4" />
      <source src={`https:${src}`} type="video/ogg" />
      Your browser does not support video. Please upgrade your browser.
    </video>
  )
}

ContentfulVideo.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
  internalVideoSrcUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default ContentfulVideo
