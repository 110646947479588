import React from 'react'
import { ModalSimple } from '../../Modal'
import ModalPopUpContent from './ModalPopUpContent'

const EmbeddedEntryModal = ({ theme, children, contentType, content }) => {
  let modalButtonLabel = children?.[0]?.length > 1 && children[0][1]
  return (
    <ModalSimple
      theme={theme}
      label={modalButtonLabel}
      contentType={contentType}
    >
      <ModalPopUpContent
        contentType={contentType}
        theme={theme}
        content={content}
      />
    </ModalSimple>
  )
}

export default EmbeddedEntryModal
