import React from 'react'
import { StyledSvgContainer, StyledSvg, StyledSvgHover } from './styles'
import PropTypes from 'prop-types'
import downloadIcon from '../../assets/download-icon@3x.png'
import hoverDownloadIcon from '../../assets/hover-download-icon@3x.png'

const ListIcon = ({ icon }) => {
  switch (icon) {
    case 'Download':
      return (
        <StyledSvgContainer minWidth="70px" height="70px">
          <StyledSvg
            src={downloadIcon}
            alt={`${icon}-icon`}
            width="100%"
            top="0"
          />
          <StyledSvgHover
            z-index="2"
            src={hoverDownloadIcon}
            alt={`${icon}-hover-icon`}
            width="100%"
            top="0"
          />
        </StyledSvgContainer>
      )
    default:
      return null
  }
}

ListIcon.propTypes = {
  icon: PropTypes.string,
}

export default ListIcon
