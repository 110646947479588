import React from 'react'
import PropTypes from 'prop-types'
import Closures from '../../components/Closures'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../hooks/usePreviewQuery'
import map from '../../assets/Map.png'

const ClosuresContainer = ({ id, title, theme }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'closureAlert',
  })
  let closuresBg = {
    childImageSharp: {
      fluid: {
        src: map,
      },
    },
  }
  let closures = data?.entries?.items.map(({ sys, fields }) => ({
    description: fields.description,
    publicationDate: fields.publicationDate,
    title: fields.title,
    displayText: fields.displayText,
    id: sys.id,
  }))

  return (
    <Closures
      title={title}
      theme={theme}
      closuresBg={closuresBg}
      closures={closures}
    />
  )
}

ClosuresContainer.propTypes = {}

export default ClosuresContainer
