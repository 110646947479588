import React from 'react'
import PropTypes from 'prop-types'
import { BladeCustomApolloCPAContainer } from '../BladeCustomContainer'
import BladeMediaContainer from './LegacyBladeMediaApolloCPAContainer'
import { BladeListApolloCPAContainer } from '../BladeListContainer'
import { BladeTextApolloCPAContainer } from '../BladeTextContainer'
import { BladeGalleryApolloCPAContainer } from '../BladeGalleryContainer'
import usePreviewQuery, { GET_ENTRY } from '../../hooks/usePreviewQuery'

const BladeContainer = ({ id, order }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skip: !id,
  })

  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    const { id, fields, contentType } = data.entry
    switch (contentType) {
      case 'bladeGallery':
        return <BladeGalleryApolloCPAContainer id={id} {...fields} />
      case 'list':
        return <BladeListApolloCPAContainer id={id} {...fields} />
      case 'bladeCustom':
        return <BladeCustomApolloCPAContainer id={id} {...fields} />
      case 'bladeMedia':
        return <BladeMediaContainer id={id} {...fields} />
      case 'bladeText':
        return (
          <BladeTextApolloCPAContainer
            id={id}
            {...fields}
            displayScrollIcon={order === 1}
          />
        )
      default:
        return <div />
    }
  }
}

export default BladeContainer

BladeContainer.propTypes = {
  layout: PropTypes.string,
  theme: PropTypes.bool,
}
