import { useRef, useCallback } from 'react'

const useVerticalSnapToPosition = (
  targetRef,
  isExpanded,
  expandedPosition = '5%',
  minimizedPosition = '75%',
  snapToSwipeDirection = false
) => {
  const srcRef = useRef(null)
  const touchInitPosition = useRef(0)
  const touchCurrPosition = useRef(0)
  const touchOffset = useRef(0)
  const touchDirection = useRef(0)
  const transitionDuration = 250
  const thresholdPercentage = 0.125

  const getSnapPosition = useCallback(
    () => (isExpanded.current ? expandedPosition : minimizedPosition),
    [expandedPosition, isExpanded, minimizedPosition]
  )

  const handleOnTouchStart = useCallback(e => {
    touchInitPosition.current = e.touches[0].clientY
    touchCurrPosition.current = 0
    touchDirection.current = 0
    touchOffset.current = 0
  }, [])

  const handleOnTouchMove = useCallback(
    e => {
      touchDirection.current = touchCurrPosition.current - e.touches[0].clientY
      touchCurrPosition.current = e.touches[0].clientY
      touchOffset.current =
        touchInitPosition.current - touchCurrPosition.current
      targetRef.current.style.top = `calc(${getSnapPosition()} - ${
        touchOffset.current
      }px)`
    },
    [getSnapPosition, targetRef]
  )

  const handleOnTouchEnd = useCallback(() => {
    const threshold = snapToSwipeDirection
      ? touchDirection.current
      : isExpanded.current
      ? thresholdPercentage -
        (1 - touchOffset.current) / targetRef.current.clientHeight
      : touchOffset.current / targetRef.current.clientHeight -
        thresholdPercentage

    if (threshold > 0) isExpanded.current = true
    if (threshold < 0) isExpanded.current = false

    targetRef.current.style.transition = `top ${transitionDuration}ms`
    targetRef.current.style.top = getSnapPosition()
    setTimeout(() => {
      targetRef.current.style.transition = ''
    }, transitionDuration + 10)
  }, [getSnapPosition, isExpanded, snapToSwipeDirection, targetRef])

  const callbackRef = useCallback(
    node => {
      if (srcRef.current) {
        srcRef.current.removeEventListener('touchstart', handleOnTouchStart)
        srcRef.current.removeEventListener('touchmove', handleOnTouchMove)
        srcRef.current.removeEventListener('touchend', handleOnTouchEnd)
      }
      srcRef.current = node
      if (srcRef.current) {
        srcRef.current.addEventListener('touchstart', handleOnTouchStart)
        srcRef.current.addEventListener('touchmove', handleOnTouchMove)
        srcRef.current.addEventListener('touchend', handleOnTouchEnd)
      }
    },
    [handleOnTouchEnd, handleOnTouchMove, handleOnTouchStart]
  )

  const expand = (preventTransition = false) => {
    if (isExpanded) {
      isExpanded.current = true
      if (!preventTransition)
        targetRef.current.style.transition = `top ${transitionDuration}ms`
      targetRef.current.style.top = getSnapPosition()
      setTimeout(() => {
        targetRef.current.style.transition = ''
      }, transitionDuration + 10)
    }
  }

  const minimize = (preventTransition = false) => {
    if (isExpanded) {
      isExpanded.current = false
      if (!preventTransition)
        targetRef.current.style.transition = `top ${transitionDuration}ms`
      targetRef.current.style.top = getSnapPosition()
      setTimeout(() => {
        targetRef.current.style.transition = ''
      }, transitionDuration + 10)
    }
  }

  return [callbackRef, isExpanded, expand, minimize]
}

export default useVerticalSnapToPosition
