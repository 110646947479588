import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  ContainerNew,
  VideoBackground,
  VideoForeground,
  ExternalVideoIFrame,
  InternalHtmlVideoWrapper,
  VideoOverlayContent,
  VideoOverlayContentInternal,
} from './styles'
import PlayButton from './PlayButton'

const useVideoState = (refId, externalVid) => {
  const [playCount, setPlayCount] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const ctrlVideo = useRef(null)

  useEffect(() => {
    if (document !== undefined) {
      ctrlVideo.current = document.getElementById(`${refId}`)
    }
  }, [])

  const play = () => {
    if (!isPlaying && !externalVid) {
      ctrlVideo.current.play()
      setPlayCount(playCount + 1)
      setIsPlaying(true)
    }
  }

  const pause = () => {
    if (isPlaying) {
      ctrlVideo.current.pause()
      setIsPlaying(false)
    }
  }
  const handleSetPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  return [ctrlVideo, isPlaying, play, pause, playCount, handleSetPlaying]
}

const FullWidthVideoBGWrapper = ({
  children,
  height,
  width = '100%',
  minHeight,
  src,
  theme,
  autoPlay,
}) => {
  return (
    <ContainerNew
      data-testid="video-component"
      width={width}
      height={`${height}vh`}
      minHeight={`${minHeight}vh`}
      bg={!src && `${theme}.bg`}
    >
      <VideoBackground height={`${height}vh`} bg={!src && `${theme}.bg`}>
        <VideoForeground autoPlay={autoPlay} height={`${height}vh`}>
          {children}
        </VideoForeground>
      </VideoBackground>
    </ContainerNew>
  )
}

const ModalVideoBGWrapper = ({
  children,
  height,
  width = '100%',
  minHeight,
  src,
  theme,
}) => {
  return (
    <ContainerNew
      width={width}
      minHeight={`${minHeight}vh`}
      bg={!src && `${theme}.bg`}
      position="relative"
      paddingBottom="56.25%"
    >
      {children}
    </ContainerNew>
  )
}

export const ModalVideo = ({
  autoPlay = true,
  src,
  title,
  minHeight = null,
  width,
  mute,
  thumbnail,
  height,
  theme,
  boxShadow,
  borderRadius,
}) => {
  return (
    <ModalVideoBGWrapper
      height={height}
      width={width}
      minHeight={minHeight}
      src={src}
      theme={theme}
    >
      <Video
        src={src}
        autoPlay={autoPlay}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        title={title}
        mute={mute}
        thumbnail={thumbnail}
        background
      />
    </ModalVideoBGWrapper>
  )
}

export const Video = ({
  src,
  boxShadow = false,
  borderRadius = '0rem',
  autoPlay = false,
  title,
  thumbnail,
  mute = true,
  background = false,
  width,
  height,
  controlsOnly = false,
  minHeight = null,
  inlineVideo = false,
}) => {
  let youtubeBaseStr = 'https://www.youtube.com/embed/'
  let vimeoBaseStr = 'https://vimeo.com/'
  let external = src && (src.includes('youtube') || src.includes('vimeo'))
  let youtube = src && src.includes('youtube')

  let vidSrc =
    src && (!src.includes('http') || !src.includes('https'))
      ? `https:${src}`
      : src

  let youtubeVidId =
    youtube && vidSrc.slice(youtubeBaseStr.length, vidSrc.length)

  const handleRenderVideoHtmlBasedOnProps = () => {
    let propsStr = ''
    if (controlsOnly) {
      propsStr = `controls poster=${thumbnail}`
    } else if (autoPlay && !background) {
      if (mute) {
        propsStr = propsStr + 'autoplay loop playsinline controls muted'
      } else {
        propsStr = propsStr + `autoplay playsinline controls`
      }
    } else if (autoPlay && background) {
      propsStr = propsStr + 'autoplay loop playsinline muted'
    } else {
      propsStr = `poster=${thumbnail}`
    }
    return propsStr
  }
  const handleRenderVideoStyleBasedOnProps = () => {
    if (width && !boxShadow) {
      return `style="width: ${width}"`
    } else if (width && boxShadow) {
      return `style="width: ${width}; border-radius: ${borderRadius}; box-shadow: ${boxShadow};"`
    } else if (!width && boxShadow) {
      return `border-radius: ${borderRadius}; box-shadow: ${boxShadow};"`
    } else {
      return ''
    }
  }
  const createInternalVideoMarkup = () => {
    return {
      __html: ` <video ${handleRenderVideoHtmlBasedOnProps()} ${handleRenderVideoStyleBasedOnProps()} >
    <source src=${vidSrc} type="video/webm" /> 
    <source src=${vidSrc} type="video/ogg" /> 
    <source src=${vidSrc} type="video/mp4" />
    Your browser does not support video. Please upgrade your browser.
  </video>`,
    }
  }

  return external ? (
    <ExternalVideoIFrame
      src={
        youtube
          ? `${src}?controls=0&showinfo=0&rel=0&autoplay=${
              autoPlay ? 1 : 0
            }&loop=1&playlist=${youtubeVidId}&mute=${mute ? 1 : 0}`
          : `${src}?autoplay=${autoPlay ? 1 : 0}&loop=1&background=${
              background ? 1 : 0
            }`
      }
      frameBorder="0"
      allowFullScreen
      title={title}
      allow="autoplay; fullscreen"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      data-ready="true"
      width={width}
      height={height}
      minHeight={minHeight ? minHeight : ['50vh', null, '75vh']}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      inlineVideo={inlineVideo}
    />
  ) : (
    <InternalHtmlVideoWrapper
      dangerouslySetInnerHTML={createInternalVideoMarkup()}
    />
  )
}

const VideoOverlay = ({ children, overlay, hasRichText, autoPlay }) => {
  if (overlay) {
    return (
      <VideoOverlayContent color={overlay && 'rgba(0,0,0,0.3)'}>
        <VideoOverlayContentInternal>
          {hasRichText ? children : <PlayButton aria-label="Play Video" />}
        </VideoOverlayContentInternal>
      </VideoOverlayContent>
    )
  }
  if (autoPlay) {
    return (
      <VideoOverlayContent>
        <VideoOverlayContentInternal>{children}</VideoOverlayContentInternal>
      </VideoOverlayContent>
    )
  } else {
    return null
  }
}

const VideoComponent = ({
  autoPlay = true,
  src,
  title,
  children,
  minHeight = null,
  width,
  thumbnail,
  hasRichText,
  height,
  overlay = false,
  theme,
  boxShadow,
  borderRadius,
}) => {
  return (
    <FullWidthVideoBGWrapper
      height={height}
      width={width}
      minHeight={minHeight}
      src={src}
      theme={theme}
      autoPlay={autoPlay}
    >
      <Video
        src={src}
        autoPlay={autoPlay}
        mute={autoPlay}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        title={title}
        thumbnail={thumbnail}
        background
      />
      <VideoOverlay
        overlay={overlay}
        hasRichText={hasRichText}
        autoPlay={autoPlay}
      >
        {children}
      </VideoOverlay>
    </FullWidthVideoBGWrapper>
  )
}

VideoComponent.propTypes = {
  title: PropTypes.string,
  minHeight: PropTypes.number.isRequired,
  externalVideoSrcUrl: PropTypes.string,
  internalVideoSrcUrl: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
}

export default VideoComponent
