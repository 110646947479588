import React from 'react'
import { NewsApolloCPAContainer } from '../NewsContainer'
import { UpdatesApolloCPAContainer } from '../UpdatesContainer'
import { InstagramApolloCPAContainer } from '../InstagramContainer'

const NewsAndUpdatesContainerApollo = ({ title, ...rest }) => {
  return (
    <>
      <UpdatesApolloCPAContainer {...rest} />
      <InstagramApolloCPAContainer user="cityofhope" posts={[]} />
      <NewsApolloCPAContainer {...rest} title={title} />
    </>
  )
}

export default NewsAndUpdatesContainerApollo
