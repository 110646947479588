import React from 'react'
import PropTypes from 'prop-types'
import {
  ExternalVideoWrapper,
  StyledThumbnail,
  StyledPlayButton,
} from './styles'

// const ExternalVideo = ({ autoPlay, src, title }) => {
//   let clicked = false
//   return (
//     <>
//       <iframe
//         style={{
//           width: '100%',
//           height: '100%',
//           // position: 'absolute',
//           // top: '0',
//           // left: '0',
//           // visibility: clicked ? 'visible' : 'hidden',
//         }}
//         frameBorder="0"
//         src={`${src}${clicked && '?autoplay=1'}`}
//         />
// </>
//   )
// }
const ExternalVideo = ({
  src,
  height,
  minHeight = '55vh',
  title,
  referenceId,
  thumbnail,
}) => {
  return (
    <ExternalVideoWrapper
      width="100%"
      height="100%"
      minHeight={`${minHeight}vh`}
      referenceId={referenceId}
    >
      <iframe
        style={{
          width: '100%',
          minHeight: `${minHeight}vh`,
          height: '100%',
        }}
        frameBorder="0"
        src={src}
        title={title}
        allow="autoplay; fullscreen"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        data-ready="true"
      />
    </ExternalVideoWrapper>
  )
}

ExternalVideo.propTypes = {
  title: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
  minHeight: PropTypes.string.isRequired,
  externalVideoSrcUrl: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

export default ExternalVideo
