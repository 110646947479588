import React from 'react'
import {
  StyledListWrapper,
  StyledListUl,
  StyledWidthContainer,
  StyledTitle,
} from './styles'
import PropTypes from 'prop-types'
import { useMobile } from '../../hooks'

const List = ({
  title = 'Link Section',
  theme = 'dark',
  children = [],
  dataTestid,
}) => {
  const [isMobile] = useMobile()

  return (
    <StyledListWrapper
      fontFamily="body"
      data-testid={dataTestid}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={`${theme}.bgAlt`}
      py={5}
    >
      <StyledWidthContainer
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={3}
        maxWidth={['maxWidth.xsmall', 'maxWidth.medium', 'maxWidth.large']}
        width="100%"
      >
        <StyledTitle
          m={0}
          color={`${theme}.text`}
          fontSize={[3, 2, 2, 2]}
          fontWeight={600}
          fontFamily="body"
          textAlign="center"
          paddingBottom={5}
        >
          {title}
        </StyledTitle>
        <StyledListUl width={isMobile ? '100%' : '90%'}>
          {children}
        </StyledListUl>
      </StyledWidthContainer>
    </StyledListWrapper>
  )
}

List.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  children: PropTypes.array,
  dataTestid: PropTypes.string,
}

export default List
