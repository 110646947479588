import React from 'react'
import PropTypes from 'prop-types'
import { Date } from '../Date'
import {
  StyledImage,
  StyledImageContainer,
  StyledTitle,
  StyledCategoryContainer,
  StyledDetails,
  StyledCardContainer,
  StyledDetailsContainer,
} from './styles'
import { useComponentTheme } from '../../hooks'
import { navigate } from 'gatsby'

const CardMobile = ({
  title,
  slug,
  parentSlug,
  pageTitle,
  location,
  heroImage,
  date,
  categories = [null],
  theme: themeBool = true,
}) => {
  const { theme } = useComponentTheme(themeBool)
  const borderSize = `1px solid`
  return (
    //  with Image
    <StyledCardContainer
      display="flex"
      flexDirection="column"
      data-testid={'card-mobile'}
      onClick={() => {
        let currentLocation = { slug: location?.pathname, title: pageTitle }
        let locationTrail = []
        if (location?.state?.trail) locationTrail = location.state.trail
        locationTrail.push(currentLocation)
        navigate(`${parentSlug}/${slug}`, {
          state: { trail: locationTrail },
        })
      }}
      mb={4}
    >
      {/* top side */}
      {heroImage && (
        <StyledImageContainer width={[1, 1, 3 / 5]}>
          <StyledImage
            fluid={heroImage.fluid}
            alt="card image"
            width="100%"
            height="250px"
          />
        </StyledImageContainer>
      )}

      {/* bottom side */}
      <StyledDetailsContainer
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={3}
        width={[1, 1, 2 / 5]}
        borderBottom={borderSize}
        borderColor="hero"
        borderBottomWidth="5px"
      >
        <StyledTitle
          color={`${theme}.textAlt`}
          fontSize={[0]}
          fontWeight={400}
          fontFamily="body"
          m="0px"
          py={!heroImage ? 5 : 2}
        >
          {title}
        </StyledTitle>
        <StyledCategoryContainer
          display="flex"
          justifyContent="space-between"
          m="0px"
        >
          <StyledDetails
            color={`${theme}.text`}
            fontSize="14px"
            fontFamily="headings"
            fontWeight="400"
            margin="0px"
          >
            {categories?.[0]}
          </StyledDetails>
          <Date
            date={date}
            fontSize="14px"
            fontFamily="headings"
            fontWeight="600"
            m="0px"
            color={`${theme}.text`}
          />
        </StyledCategoryContainer>
      </StyledDetailsContainer>
    </StyledCardContainer>
  )
}

CardMobile.propTypes = {
  title: PropTypes.string,
  parentSlug: PropTypes.string,
  slug: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  heroImage: PropTypes.object,
  date: PropTypes.string,
  featured: PropTypes.bool,
  theme: PropTypes.bool,
  pageTitle: PropTypes.string,
  location: PropTypes.object,
}

export default CardMobile
