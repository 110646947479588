import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'
import { useComponentTheme, useMobile, useWindowReady } from '../../hooks'
import {
  Background,
  ImageContainer,
  StyledSlider,
  StyledArrowContainer,
  StyledButtonWidth,
  StyledButtonContainer,
  StyledButton,
} from './style'
import CarouselSlickHeader from './CarouselSlickHeader'
import { ThemeContext } from 'styled-components'
import { mappedString } from '../../utils'
import CarouselSlickBtn from './CarouselSlickBtn'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Carousel component with settings, provide an array of objects with fluid props
const CarouselSlick = ({
  title,
  subtitle,
  theme: themeBool = true,
  galleryItems = [],
  autoPlay,
  height = '75vh',
}) => {
  const windowReady = useWindowReady()
  const { theme } = useComponentTheme(themeBool)
  const themePackage = useContext(ThemeContext)
  const slider = useRef(null)
  const [isMobile] = useMobile()

  const mappedGalleryItems = galleryItems.map(g => {
    const stringArr =
      g &&
      g.description &&
      g.description.json &&
      mappedString(g.description.json.content, 'text')
    let turnIntoString = stringArr && stringArr.join(' ')
    if (turnIntoString && turnIntoString.split(' ').length > 30) {
      turnIntoString = turnIntoString
        .split(' ')
        .slice(0, 30)
        .join(' ')
    } else {
      turnIntoString = null
    }
    return {
      ...g,
      shortenDescription: turnIntoString && `${turnIntoString}...`,
    }
  })

  const carouselSettings = {
    autoplay: autoPlay,
    dots: true,
    arrows: false,
    centerMode: true,
    maxWidth: themePackage.sizes.maxWidth.xlargeCarousel,
    adaptiveHeight: true,
    mobileView: isMobile,
    dotColor: themePackage.colors[theme].carouselDots,
    centerPadding: isMobile ? '30px' : '50px',
    responsive: [
      {
        breakpoint: 690,
        settings: {
          centerPadding: '18px',
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: '30px',
        },
      },
    ],
  }

  if (!windowReady) return null
  return (
    <Background data-testid="carousel-default" textAlign="center" pt={3} pb={5}>
      <CarouselSlickHeader theme={theme} title={title} subtitle={subtitle} />
      {/* two absolute inside */}
      <StyledArrowContainer position="relative">
        <StyledButtonWidth
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          width="100%"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <StyledButtonContainer
            display="flex"
            flexDirection="row"
            width="100%"
            px={['9vw', '11vw', '11vw']}
            margin="0 auto"
            justifyContent="space-between"
            maxWidth={`calc(${themePackage.sizes.maxWidth.xlargeNav} - 35px)`}
          >
            <StyledButton zIndex={1}>
              <CarouselSlickBtn
                onClick={() => slider.current.slickPrev()}
                direction="left"
                fill={themePackage.colors[theme].btnBg}
                hover={themePackage.colors[theme].btnHover}
                arrowColor={isMobile ? themePackage.colors.blues[3] : '#fff'}
              />
            </StyledButton>
            <StyledButton zIndex={1}>
              <CarouselSlickBtn
                onClick={() => slider.current.slickNext()}
                direction="right"
                fill={themePackage.colors[theme].btnBg}
                hover={themePackage.colors[theme].btnHover}
                arrowColor={isMobile ? themePackage.colors.blues[3] : '#fff'}
              />
            </StyledButton>
          </StyledButtonContainer>
        </StyledButtonWidth>
        <StyledSlider ref={slider} {...carouselSettings}>
          {mappedGalleryItems &&
            mappedGalleryItems.map((image, i) => {
              return (
                <ImageContainer
                  height={['55vh', '70vh', '70vh', height]}
                  key={image.id ? image.id : `image+${i}`}
                >
                  <Image
                    alt={image.name}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    theme={false}
                    media={image && image.media}
                    overlayCopy={
                      image && image.description && image.description.json
                    }
                    shortenDescription={image && image.shortenDescription}
                    displayScrollIcon={false}
                    minHeight={['55vh', '70vh', '70vh', height]}
                    overlayPadding={0}
                  />
                </ImageContainer>
              )
            })}
        </StyledSlider>
      </StyledArrowContainer>
    </Background>
  )
}

CarouselSlick.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
  autoPlay: PropTypes.bool,
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnail: PropTypes.object,
      description: PropTypes.exact({
        json: PropTypes.exact({
          data: PropTypes.object,
          content: PropTypes.arrayOf(PropTypes.object).isRequired,
          nodeType: PropTypes.string.isRequired,
        }),
      }),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string,
          title: PropTypes.string,
          src: PropTypes.string,
          srcContentful: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
          thumbnail: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
        })
      ).isRequired,
    })
  ),
  fullWidth: PropTypes.bool,
}

export default CarouselSlick
