import React from 'react'
import DefaultList from '../../components/List/DefaultList'

const DefaultListContainer = ({ listItems = [], ...rest }) => {
  listItems = listItems.map(({ navigationLink, ...rest }) => ({
    ...rest,
    to: navigationLink?.navigationLink?.slug,
    href: navigationLink?.navigationLink?.url,
  }))

  return <DefaultList listItems={listItems} {...rest} />
}

export default DefaultListContainer
