import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  StyledCategoryListContainer,
  StyledCategoryHeaderContainer,
  StyledText,
  StyledContainer,
} from '../styles'
import Preview from './Preview'
import Icon from '../../Icon'
import BackButton from './BackButton'
import useMeasure from '../../../hooks/useMeasure'
import useVerticalSnapToPosition from '../useVerticalSnapToPosition'

const CategoryView = ({
  title,
  icon,
  features = [],
  prevTitle,
  goToPrevView = () => {},
  goToNextView = () => {},
  sidebarRef,
  isExpanded,
  snapPositions,
}) => {
  const [ref, height] = useMeasure()
  const [categoryViewRef] = useVerticalSnapToPosition(
    sidebarRef,
    isExpanded,
    snapPositions?.current?.expanded,
    snapPositions?.current?.minimized
  )

  features = features.sort((a, b) =>
    a.properties.title.localeCompare(b.properties.title)
  )

  return (
    <StyledCategoryListContainer height="100%">
      <StyledCategoryHeaderContainer
        p={3}
        borderBottom="1px solid #D9D9DD"
        minHeight="100px"
        ref={ref}
      >
        <StyledContainer mx={2} mb={2} ref={categoryViewRef}>
          <BackButton prevTitle={prevTitle} onClick={() => goToPrevView(16)} />
          <StyledContainer display="flex" alignItems="flex-start">
            <Icon {...icon} height="auto" width="19px" mr={3} mt={1} />
            <StyledText fontWeight={600} fontSize="28px" lineHeight="32px">
              {title}
            </StyledText>
          </StyledContainer>
        </StyledContainer>
      </StyledCategoryHeaderContainer>
      <StyledContainer p={3} ml={1} pr={1} height={`calc(100% - ${height}px)`}>
        <StyledContainer height="100%" pr={3} overflowY="scroll">
          {features.map(feature => (
            <Preview
              key={feature.id}
              {...feature.properties}
              icon={icon}
              handleClick={() =>
                goToNextView(
                  { type: 'detail', obj: feature, category: title },
                  17
                )
              }
            />
          ))}
        </StyledContainer>
      </StyledContainer>
    </StyledCategoryListContainer>
  )
}

CategoryView.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  features: PropTypes.arrayOf(PropTypes.object),
  prevTitle: PropTypes.string,
  goToPrevView: PropTypes.func,
  goToNextView: PropTypes.func,
  sidebarRef: PropTypes.object,
  isExpanded: PropTypes.object,
  snapPositions: PropTypes.object,
}

export default CategoryView
