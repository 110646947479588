import React from 'react'
import PropTypes from 'prop-types'
import { Cards } from '../Cards'
import { UpdatesList } from '../UpdatesList'
import { Instagram } from '../Instagram'

const NewsAndUpdates = ({
  posts,
  news,
  updates,
  title,
  location,
  pageTitle,
}) => {
  return (
    <div data-testid="news-and-updates">
      <UpdatesList updates={updates} />
      <Instagram user="cityofhope" posts={posts} />
      <Cards
        news={news}
        title={title}
        location={location}
        pageTitle={pageTitle}
        extend
        displayAllCards
      />
    </div>
  )
}

NewsAndUpdates.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  news: PropTypes.object,
  updates: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  pageTitle: PropTypes.string,
}

export default NewsAndUpdates
