import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { UpdatesList } from '../../components/UpdatesList'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../hooks/usePreviewQuery'

const UpdatesContainerApollo = ({ ...rest }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'update',
  })
  let updates = {}

  updates.updates = data?.entries?.items.map(({ sys, fields }) => ({
    categories: fields.categories,
    date: fields.date,
    title: fields.title,
    id: sys.id,
  }))
  updates.parentSlug = 'news-and-updates'
  if (loading) return 'Loading'
  if (error) return ''
  if (data) {
    return <UpdatesList {...rest} updates={updates} />
  } else {
    return <div />
  }
}

UpdatesContainerApollo.propTypes = {}

export default UpdatesContainerApollo
