import styled from 'styled-components'
import Img from 'gatsby-image'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledBrandLogosContainer = styled(Container)``

export const StyledWidthContainer = styled(Container)``

export const StyledImage = styled(Img)`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${border}
`
export const StyledNormalImage = styled.img`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${border}
`

export const StyledLogoContainer = styled(Container)``
