import styled from 'styled-components'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'
import { theme } from '../../themes/coh'

export const Background = styled.section`
  ${color}
  ${layout}
  ${position}
  ${space}
`

export const RichTextContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}

  height: 100%;
  box-sizing: border-box;

`
export const Col = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}

`
export const BorderColBottom = styled(Col)`
  background-image: ${({ mobile }) =>
    mobile &&
    `linear-gradient(to right, ${theme.colors.hero} 40%, rgba(255, 255, 255, 0) 0%)`};
  background-position: ${({ mobile }) => mobile && 'bottom'};
  background-size: ${({ mobile }) => mobile && '10px 1px'};
  background-repeat: ${({ mobile }) => mobile && 'repeat-x'};
`
export const BorderColLeft = styled(Col)`
  background-image: ${({ mobile }) =>
    !mobile &&
    `linear-gradient(to bottom,${theme.colors.hero} 40%,rgba(255,255,255,0) 0%)`};
  background-position: ${({ mobile }) => !mobile && 'left'};
  background-size: ${({ mobile }) => !mobile && '1px 10px'};
  background-repeat: ${({ mobile }) => !mobile && 'repeat-y'};
  box-sizing: border-box;
`

export const H1 = styled.h1`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H2 = styled.h2`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H3 = styled.h3`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H4 = styled.h4`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H5 = styled.h5`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H6 = styled.h6`
  ${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const P = styled.p`
  ${color}
  ${layout}
  ${typography} 
  ${shadow}
`
export const Button = styled.button`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
`

export const FontCheck = styled.div`
  ${typography}
`
export const B = styled.b`
  ${color}
  ${layout}
  ${typography}

`
