import React from 'react'
import {
  StyledItemContainer,
  StyledItemTitle,
  StyledItemSubtitle,
  Container,
} from './styles'
import PropTypes from 'prop-types'
import ListIcon from './ListIcon'

const ListItem = ({ icon, theme, title, subtitle }) => {
  return (
    <StyledItemContainer
      display="flex"
      flexDirection="row"
      alignItems="center"
      color={`${theme}.text`}
      py={3}
    >
      <ListIcon icon={icon?.icon} />
      <Container
        p={3}
        paddingLeft={4}
        height="100%"
        color={`${theme}.text`}
        fontSize={0}
      >
        <StyledItemTitle>{title}</StyledItemTitle>
        <StyledItemSubtitle fontSize={0} fontWeight={100} pt={1}>
          {subtitle}
        </StyledItemSubtitle>
      </Container>
    </StyledItemContainer>
  )
}

ListItem.propTypes = {
  icon: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
  theme: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ListItem
