import React from 'react'
import Image from '../Image'
import { Video } from '../Video/VideoComponent'

const CarouselSlickInlineContent = ({ content }) => {
  const contentType = content?.media[0]?.__typename

  switch (contentType) {
    case 'ContentfulMediaImage':
      return (
        <Image
          alt={content.name}
          style={{
            width: '100%',
            height: '485px',
          }}
          minHeight="0px"
          theme={false}
          media={content && content.media}
          overlayCopy={
            content && content.description && content.description.json
          }
          shortenDescription={content && content.shortenDescription}
          displayScrollIcon={false}
          overlayPadding={0}
        />
      )
    case 'ContentfulMediaVideo':
      return (
        <Video
          src={
            content?.media[0]?.srcUrl ||
            content?.media[0]?.srcContentful?.file?.url
          }
          minHeight="0px"
          mute={false}
          title={content.media[0].title}
          thumbnail={content.media[0].thumbnail.file.url}
          width="100%"
          height="100%"
          controlsOnly
        />
      )
    default:
      return null
  }
}

export default CarouselSlickInlineContent
