import React from 'react'
import PropTypes from 'prop-types'
import { useState } from '../../../hooks'
import GridContainer from '../GridContainer'
import GridItem from './GridItem'

const Grid = ({ theme, galleryItems = [], isMobile }) => {
  const [active, setActive] = useState(99)

  const toggle = i => {
    if (i === active) {
      setActive(99)
    } else {
      setActive(i)
    }
  }

  return (
    <GridContainer>
      {galleryItems.map(
        (
          {
            id,
            title,
            shortDescription,
            logo,
            website,
            email,
            longDescription,
            media,
            reel,
          },
          i
        ) => (
          <GridItem
            id={id}
            key={id}
            title={title}
            shortDescription={shortDescription}
            logo={logo}
            website={website}
            email={email}
            longDescription={longDescription?.json}
            media={media}
            reel={reel}
            i={i}
            theme={theme}
            toggle={toggle}
            isMobile={isMobile}
            active={active}
            setActive={setActive}
          />
        )
      )}
    </GridContainer>
  )
}

Grid.propTypes = {
  theme: PropTypes.string,
  align: PropTypes.string,
  galleryItems: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
}

export default Grid
