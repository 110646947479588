import React from 'react'
import PropTypes from 'prop-types'
import { useMobile, useComponentTheme } from '../../../hooks'
import { BG } from './styles'
import GridTitle from './GridTitle'
import Grid from './Grid'

const GridWithTitle = ({
  title,
  subtitle,
  theme: themeBool = false,
  ...rest
}) => {
  const [isMobile] = useMobile('39.9375em')
  const { theme } = useComponentTheme(themeBool)

  return (
    <BG
      data-testid="grid-default"
      bg={!isMobile ? `${theme}.bg` : `${theme}.text`}
      color={`${theme}.text`}
      margin="0 auto"
      textAlign="center"
    >
      <GridTitle title={subtitle} theme={theme} />
      <Grid {...rest} theme={theme} isMobile={isMobile} />
    </BG>
  )
}
GridWithTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
}

export default GridWithTitle
