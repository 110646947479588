import React from 'react'
import { OpaqueButton } from '../../Button'
import InternalOrExternalLinkButton from './InternalOrExternalLinkButton'

const EmbeddedEntryButton = ({
  theme,
  href,
  to,
  forceOpaqueButton,
  children,
}) => {
  if (forceOpaqueButton) {
    return (
      <OpaqueButton theme={theme} href={href} to={to}>
        {children}
      </OpaqueButton>
    )
  }
  return (
    <InternalOrExternalLinkButton theme={theme} href={href} to={to}>
      {children}
    </InternalOrExternalLinkButton>
  )
}

export default EmbeddedEntryButton
