import React from 'react'
import PropTypes from 'prop-types'
import {
  GridItemContainer,
  StyledImageContainer,
  StyledImage,
  StyledCaption,
} from './styles'
import { renderRichText } from '../GridToggle/richText'
import useTheme from '../../../hooks/useComponentTheme'
import { Video } from '../../Video/VideoComponent'
import { ModalSimple } from '../../Modal'
import { renderBgTypeAndBg } from '../../../utils'

const GridItem = ({ name, description, media, theme }) => {
  const { theme: themeStyle } = useTheme(theme)
  const {
    assetId,
    type,
    src,
    thumbnail: thumb,
    alt,
    autoPlay,
  } = renderBgTypeAndBg({
    background: media,
    theme,
  })

  return (
    <GridItemContainer width={[1, 1 / 2, 1 / 3]} px={3} py={4}>
      <StyledImageContainer>
        <ModalSimple
          alt={name}
          theme={theme}
          thumbnail={thumb}
          contentType={type}
          label={description || 'grid-item'}
        >
          {type.includes('video') && (
            <Video
              theme={theme}
              src={src}
              autoPlay
              mute={false}
              title={description || 'grid-item'}
              thumbnail={thumb}
              width="100%"
              height="auto"
              background={false}
              boxShadow
            />
          )}
          {type.includes('image') && (
            <StyledImage
              fluid={{ src: `https://${src}` }}
              theme={theme}
              height="100%"
              width="100%"
              alt={description || 'grid-item'}
              boxShadow="0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2)"
              borderRadius="0.3rem"
            />
          )}
        </ModalSimple>
      </StyledImageContainer>
      <StyledCaption>
        {description && renderRichText(description, themeStyle)}
      </StyledCaption>
    </GridItemContainer>
  )
}
GridItem.propTypes = {
  name: PropTypes.string,
  description: PropTypes.object,
  media: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
  image: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  thumbnail: PropTypes.string,
  typename: PropTypes.string,
}

export default GridItem
