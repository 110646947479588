import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  shadow,
  background,
  minHeight,
} from 'styled-system'

export const Container = styled(BackgroundImage)`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${minHeight}

`

export const Cell = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${background}
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}
`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}
`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}
`
export const P = styled.p`
${color}
  ${layout}
  ${typography}
  ${space}
  ${shadow}
`

export const FontCheck = styled.div`
  ${typography}
`
export const B = styled.b`
${color}
  ${layout}
  ${typography}

`

export const I = styled.i`
${color}
  ${layout}
  ${typography}

`

export const U = styled.u`
${color}
  ${layout}
  ${typography}

`
