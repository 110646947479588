import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import MyColorGrid from '../../../components/MyColorGrid'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../../hooks/usePreviewQuery'
import { PreviewAssetsContext } from '../../../context'
import { filterAssetsObjFromAssetsArrById } from '../../../utils'

const TeamContainer = ({ galleryItemIds, theme }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'teamMember',
  })
  const { assets } = useContext(PreviewAssetsContext)
  const [teamMembers, setTeamMembers] = useState([])

  const addAssetsToFilteredArr = ({ arr }) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].image) {
        let tempImage = filterAssetsObjFromAssetsArrById({
          id: arr[i].image.sys.id,
          assetsArr: assets,
        })
        arr[i].image = {
          id: tempImage.id,
          fluid: {
            src: tempImage.file.url,
          },
        }
      }
    }
    return arr
  }
  const filterFields = ({ arr }) => {
    if (!arr) {
      return []
    }
    return arr.map(({ sys, fields }) => ({ id: sys.id, ...fields }))
  }

  const filterItemsByIdArr = ({ idArr, arr }) => {
    if (!idArr || !arr) {
      return []
    }
    return arr.filter(({ sys: id }) => idArr.includes(id))
  }

  const adjustSlugForPreview = ({ arr }) => {
    if (!arr) {
      return []
    }
    return arr.map(({ slug, id, ...rest }) => ({
      preview: true,
      id,
      slug: `/preview/?t=${process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN}&id=${id}&contentType=teamMember`,
      ...rest,
    }))
  }

  useEffect(() => {
    if (data && data.entries) {
      let filteredIdArr = galleryItemIds.map(({ id }) => id)
      let teamMembers = filterItemsByIdArr({
        idArr: filteredIdArr,
        arr: data.entries.items,
      })
      teamMembers = filterFields({ arr: teamMembers })
      teamMembers = addAssetsToFilteredArr({ arr: teamMembers })
      teamMembers = adjustSlugForPreview({ arr: teamMembers })
      setTeamMembers(teamMembers)
    }
  }, [data, loading, error])

  if (loading) return null
  if (error) return ` `
  if (data && !loading && data.entries) {
    return <MyColorGrid theme={theme} galleryItems={teamMembers} />
  } else {
    return <div />
  }
}

TeamContainer.propTypes = {}

export default TeamContainer
