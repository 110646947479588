import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Cell } from './style'
import { ThemeContext } from 'styled-components'

// Carousel component with settings, provide an array of objects with fluid props
const CarouselSlickInlineHeader = ({
  title,
  subtitle,
  theme = 'dark',
  isMobile,
}) => {
  const themePackage = useContext(ThemeContext)
  return (
    <Cell
      width="100%"
      pl={isMobile ? 3 : 0}
      pt={45}
      maxWidth={themePackage.sizes.maxWidth.xlargeNav}
    >
      <Cell
        fontSize={3}
        fontFamily="headings"
        color={`${theme}.text`}
        pb={3}
        textShadow={theme === 'dark' && 'text'}
        letterSpacing="0.2px"
        fontWeight={600}
      >
        {title}
      </Cell>
      <Cell
        pb={3}
        color={`${theme}.text`}
        fontSize={[9, 9, 9, 9, 9, 9, 9]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={300}
        letterSpacing="0.18px"
        textShadow={theme === 'dark' && 'text'}
        style={{ textTransform: 'uppercase' }}
      >
        {subtitle}
      </Cell>
    </Cell>
  )
}

CarouselSlickInlineHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.string,
}

export default CarouselSlickInlineHeader
