import React from 'react'
import PropTypes from 'prop-types'
import { GridTitle, GridSubtitle, GridHeaderContainer } from './styles'

const GridHeader = ({ title, subtitle, theme, ...rest }) => {
  return (
    <GridHeaderContainer p={3} fontFamily="headings">
      <GridTitle fontSize={2}>{title}</GridTitle>
      <GridSubtitle fontSize={3} py={4}>
        {subtitle}
      </GridSubtitle>
    </GridHeaderContainer>
  )
}
GridHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
}

export default GridHeader
