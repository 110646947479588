import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  StyledViewContainer,
  StyledSectionContainer,
  StyledSectionTitle,
  StyledCategoryContainer,
  StyledCategoryTitle,
  StyledContainer,
  StyledSvgContainer,
  StyledSvg,
  StyledPhoneNumber,
  StyledClosure,
  StyledText,
} from '../styles'
import Search from './Search'
import _ from 'lodash'
import Icon from '../../Icon'
import useMeasure from '../../../hooks/useMeasure'
import phoneIcon from '../../../assets/phone.svg'

import useVerticalSnapToPosition from '../useVerticalSnapToPosition'

const MainView = ({
  sections = [],
  goToNextView = () => {},
  siteInfo,
  sidebarRef,
  isExpanded,
  snapPositions,
  closures,
}) => {
  const categoryViews = _.flatten(
    sections.map(({ featureCollections }) => featureCollections)
  )
    .map(({ title, ...rest }) => ({
      category: title,
      type: 'category',
      obj: { ...rest, title },
    }))
    .sort((a, b) => a.category.localeCompare(b.category))

  const locationViews = _.flatten(
    categoryViews.map(({ category, obj: { features } }) =>
      features.map(f => ({ ...f, category }))
    )
  )
    .map(({ category, ...rest }) => ({
      category,
      type: 'detail',
      obj: { ...rest },
    }))
    .sort((a, b) =>
      a.obj.properties.title.localeCompare(b.obj.properties.title)
    )

  const groupedOptions = [
    {
      label: 'Locations',
      options: locationViews.map(({ obj, ...rest }) => ({
        label: obj?.properties?.title,
        value: { obj, ...rest },
      })),
    },
    {
      label: 'Categories',
      options: categoryViews.map(({ category, ...rest }) => ({
        label: category,
        value: { category, ...rest },
      })),
    },
  ]

  const handleSelect = selectedOption => {
    goToNextView(
      selectedOption.value,
      selectedOption.value.type === 'detail' ? 17 : 16
    )
  }

  const [ref, height] = useMeasure()
  const [, , expand] = useVerticalSnapToPosition(
    sidebarRef,
    isExpanded,
    snapPositions?.current?.expanded,
    snapPositions?.current?.minimized
  )

  return (
    <StyledViewContainer
      display="flex"
      flexDirection="column"
      p={3}
      height="100%"
    >
      <StyledContainer ref={ref}>
        <Search
          options={groupedOptions}
          groupOptions
          handleSelect={handleSelect}
          onTouchStart={() => expand(true)}
        />
      </StyledContainer>
      <StyledContainer py={3} px={1} height={`calc(100% - ${height}px)`}>
        <StyledContainer height="100%" pr={2} overflowY="scroll">
          {sections.map(({ id, title, featureCollections }) => (
            <StyledSectionContainer key={id} pt={1} pb={2}>
              <StyledSectionTitle
                fontSize={8}
                fontWeight={500}
                py={1}
                fontFamily="headings"
              >
                {title}
              </StyledSectionTitle>
              {featureCollections.map(
                ({ id, title: category, icon, features, ...rest }) => (
                  <StyledCategoryContainer
                    key={id}
                    pl={3}
                    py={2}
                    display="flex"
                  >
                    <Icon {...icon} height="auto" width="15px" />
                    <StyledCategoryTitle
                      ml={2}
                      fontWeight={400}
                      onClick={() =>
                        goToNextView(
                          {
                            type: 'category',
                            category,
                            obj: {
                              id,
                              title: category,
                              icon,
                              features,
                              ...rest,
                            },
                          },
                          16
                        )
                      }
                    >
                      {category}
                    </StyledCategoryTitle>
                  </StyledCategoryContainer>
                )
              )}
            </StyledSectionContainer>
          ))}
          {siteInfo?.sitePhoneNumber && (
            <StyledContainer>
              <StyledSectionTitle
                fontSize={8}
                fontWeight={500}
                py={2}
                fontFamily="headings"
              >
                Call our Helpline
              </StyledSectionTitle>
              <StyledContainer display="flex" alignItems="center" ml={3}>
                <StyledSvgContainer>
                  <StyledSvg src={phoneIcon} alt="phone-icon" />
                </StyledSvgContainer>
                <StyledPhoneNumber
                  href={`tel: ${siteInfo?.sitePhoneNumber}`}
                  py={1}
                  ml={2}
                  fontSize={6}
                  fontWeight={400}
                >
                  {siteInfo?.sitePhoneNumber}
                </StyledPhoneNumber>
              </StyledContainer>
            </StyledContainer>
          )}
          {closures && (
            <StyledContainer>
              <StyledSectionTitle
                fontSize={8}
                fontWeight={500}
                py={2}
                fontFamily="headings"
                pt={3}
              >
                Parking Updates
              </StyledSectionTitle>
              {closures.map(
                ({ description, displayText, title, publicationDate }) => (
                  <StyledCategoryContainer
                    key={publicationDate}
                    py={1}
                    display="flex"
                    pl={3}
                  >
                    <StyledSvgContainer>
                      <Icon icon="Alert" height="auto" width="15px" />
                    </StyledSvgContainer>
                    <StyledClosure ml={2} fontWeight={400}>
                      <StyledText ml={1} fontWeight={600}>
                        {displayText}
                      </StyledText>
                      {description && (
                        <StyledText ml={1} fontWeight={400}>
                          {description}
                        </StyledText>
                      )}
                    </StyledClosure>
                  </StyledCategoryContainer>
                )
              )}
            </StyledContainer>
          )}
        </StyledContainer>
      </StyledContainer>
    </StyledViewContainer>
  )
}

MainView.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      featureCollections: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  goToNextView: PropTypes.func,
  siteInfo: PropTypes.shape({
    sitePhoneNumber: PropTypes.string,
  }),
  sidebarRef: PropTypes.object,
  isExpanded: PropTypes.object,
  snapPositions: PropTypes.object,
}

export default MainView
