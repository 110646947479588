import React from 'react'
import EmbeddedEntry from '../../components/EmbeddedEntry'
import { renderBgTypeAndBg } from '../../utils'

const handleSimplifyContentEntryToFields = ({ node }) => {
  if (!node) {
    return {}
  }
  if (node?.data?.uri) {
    return {
      href: node.data.uri,
    }
  }
  return node?.data?.target?.fields
}

const handleFindContentType = ({ node, childrenLength }) => {
  const linkContentTypesArray = ['hyperlink', 'project', 'news', 'page']
  if (!node?.data?.target) {
    return node.nodeType
  } else {
    let cT = node?.data?.target?.contentType

    if (childrenLength >= 1 && linkContentTypesArray.includes(cT)) {
      return 'button'
    } else {
      return cT
    }
  }
}
const handleDetermineModalContentType = ({ node }) => {
  if (!node) {
    return 'text'
  } else if (
    node.data.target.fields.mediaReference?.sys?.contentType?.sys?.id
  ) {
    return node.data.target.fields.mediaReference?.sys?.contentType?.sys?.id
  } else if (node.data.target.fields.mediaReference?.contentType) {
    return node.data.target.fields.mediaReference?.contentType
  } else {
    return 'div'
  }
}

const getParentPath = ({ childPath, contentType }) => {
  if (!contentType) {
    return childPath
  }
  if (contentType === 'page') {
    return childPath
  }
  if (contentType === 'project') {
    return `expansion-timelines/${childPath}`
  }
  if (contentType === 'news') {
    return `news-and-updates/${childPath}`
  }
}

const handleInternalRoutingSlug = ({ node }) => {
  if (!node) {
    return '/'
  }

  let nestedEntryQuery = node.data?.target?.contentType
  let directEntryQuery = node.data?.target?.entry?.contentType

  if (directEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.entry?.fields.slug,
      contentType: directEntryQuery,
    })
  } else if (nestedEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.fields.slug,
      contentType: nestedEntryQuery,
    })
  } else return '/'
}

const handleSrc = ({ targetEntryFields, t }) => {
  const { assetId, type, src, thumbnail, alt, autoPlay } = renderBgTypeAndBg({
    background: targetEntryFields,
    theme: t,
  })

  if (src?.src) {
    return { assetId, type, src: src.src, thumbnail, alt, autoPlay }
  }
  return { assetId, type, src, thumbnail, alt, autoPlay }
}

const handleSrcIcon = ({ targetEntryFields }) => {
  if (targetEntryFields.customIcon?.fields?.file?.['en-US']) {
    return `https:${targetEntryFields.customIcon?.fields?.file?.['en-US']?.url}`
  } else {
    return `https:${targetEntryFields.customIcon?.fields?.file?.url}`
  }
}

const EmbeddedEntryBaseContainer = ({
  node,
  children,
  theme,
  bgContentType,
}) => {
  const contentType = handleFindContentType({
    node,
    childrenLength: children?.length,
  })
  const targetEntryFields = handleSimplifyContentEntryToFields({
    node,
    contentType,
  })

  // embedded entry specific fields
  const modalPopUpContentType =
    contentType === 'modal' && handleDetermineModalContentType({ node })
  const modalContent =
    (modalPopUpContentType === 'mediaVideo' ||
      modalPopUpContentType === 'mediaImage') &&
    handleSrc({
      targetEntryFields: node?.data?.target?.fields?.mediaReference?.fields,
      t: theme,
    })

  const to = contentType === 'button' && handleInternalRoutingSlug({ node })

  const bg =
    (contentType === 'mediaVideo' || contentType === 'mediaImage') &&
    handleSrc({ targetEntryFields, t: theme })

  return (
    <EmbeddedEntry
      contentType={contentType}
      childrenLength={children?.length}
      alt={node?.data?.target?.fields?.title}
      theme={theme}
      to={to}
      href={contentType === 'hyperlink' && targetEntryFields.href}
      image={contentType === 'mediaImage' && bg}
      video={contentType === 'mediaVideo' && bg}
      inlineVideo={
        contentType === 'mediaVideo' &&
        node?.nodeType === 'embedded-entry-inline'
      }
      color={
        (contentType === 'hyperlink' || contentType === 'to') &&
        bgContentType === 'color'
      }
      iconSrc={
        contentType === 'icon' &&
        targetEntryFields.icon === 'Custom Icon' &&
        handleSrcIcon({ targetEntryFields })
      }
      icon={contentType === 'icon' && targetEntryFields.icon}
      modalContentType={modalPopUpContentType}
      modalContent={modalContent}
    >
      {children}
    </EmbeddedEntry>
  )
}

EmbeddedEntryBaseContainer.propTypes = {}

export default EmbeddedEntryBaseContainer
