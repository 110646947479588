import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledGridContainer,
  StyledGridWrapper,
  StyledTitle,
  StyledBackground,
} from './styles'
import MyColorGridItem from './MyColorGridItem'
import { useComponentTheme } from '../../hooks'

const MyColorGrid = ({
  title,
  theme: themeBool = false,
  galleryItems = [],
}) => {
  const { theme } = useComponentTheme(themeBool)

  return (
    <StyledBackground bg={`${theme}.bg`} data-testid="my-color-grid">
      <StyledGridWrapper maxWidth={['450px', '450px', '900px']} margin="0 auto">
        <StyledTitle fontSize={3} m={0} p={3} color={`${theme}.text`}>
          {title}
        </StyledTitle>
        <StyledGridContainer>
          {galleryItems.map(({ id, shortBio, ...rest }) => (
            <MyColorGridItem
              key={id}
              shortBio={
                shortBio && shortBio.shortBio ? shortBio.shortBio : shortBio
              }
              {...rest}
            />
          ))}
        </StyledGridContainer>
      </StyledGridWrapper>
    </StyledBackground>
  )
}

MyColorGrid.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
  galleryItems: PropTypes.arrayOf(PropTypes.object),
}

export default MyColorGrid
