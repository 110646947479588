import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  background,
  shadow,
} from 'styled-system'

export const Container = styled.div`
box-sizing: border-box;
${position}
${flexbox}
${space}
${color}
${layout}
${typography}

`

export const Cell = styled.div`
&::-webkit-scrollbar{
  display: none;
}
-ms-overflow-style: none;

${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${shadow}
`

export const StyledCaptions = styled.div`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${shadow}
`

export const PostImage = styled(BackgroundImage)`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}


`
export const PostCell = styled(Cell)`
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;

  opacity: ${({ isDarken }) =>
    isDarken
      ? // ? 'linear-gradient(to bottom,rgba(0,0,0,.9) 30%,rgba(0,0,0,.25) 100%)'
        // : 'linear-gradient(to top,rgba(0,0,0,.05) 100%,rgba(0,0,0,.25) 100%)'};
        '1'
      : '0'};
`

// background: ${({ isDarken }) =>
// isDarken
//   ? // ? 'linear-gradient(to bottom,rgba(0,0,0,.9) 30%,rgba(0,0,0,.25) 100%)'
//     // : 'linear-gradient(to top,rgba(0,0,0,.05) 100%,rgba(0,0,0,.25) 100%)'};
//     '#c4e2fa'
//   : 'linear-gradient(to top,rgba(0,0,0,.05) 100%,rgba(0,0,0,.25) 100%)'};

export const PostCaption = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${border}
  transition-duration: .8s;
transition-timing-function: ease-out;
transition-property: opacity,-webkit-transform;
transition-property: transform,opacity;
transition-property: transform,opacity,-webkit-transform;

opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};



`
export const PostDate = styled.p`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  transition-duration: .8s;
  transition-timing-function: ease-out;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`
export const StyledExtraContainer = styled(Container)``

export const StyledLikes = styled.p`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
`
export const StyledComments = styled.p`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
`

export const StyledLikesContainer = styled(Container)``

export const StyledCommentsContainer = styled(Container)``

export const StyledImage = styled.img`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${background}
`

export const StyledInstaImageGatsby = styled(Img)`
  
  ${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${background}
object-fit: cover;
`
export const StyledInstaImageRemote = styled(StyledImage)`
  object-fit: cover;
`
export const StyledImageContainer = styled(Container)``

export const StyledArrowLeft = styled.div`
  width: 7px;
  height: 7px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
