import styled from 'styled-components'
import {
  layout,
  position,
  space,
  color,
  border,
  shadow,
  typography,
} from 'styled-system'
import Img from 'gatsby-image'

export const StyledImage = styled.img`
  box-sizing: border-box;
  ${layout}
  ${shadow}
  ${border}
  ${space}
  ${color}
  ${typography}
  ${position}
`

export const StyledGatsbyImage = styled(Img)`
  box-sizing: border-box;
  ${layout}
  ${border}
  ${space}
  ${color}
  ${typography}
  ${position}
`
