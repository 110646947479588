import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridSimple } from '../Grid'
import { MyColorGrid } from '../MyColorGrid'
import { CarouselSlick } from '../CarouselSlick'
import { CarouselSlickInline } from '../CarouselSlickInline'
import CarouselXsContainer from '../../containers/CarouselXsContainer'

const BladeGallery = ({ layout, ...rest }) => {
  switch (layout) {
    case 'Grid - Team':
      return <MyColorGrid {...rest} />
    case 'Grid':
      return <Grid {...rest} />
    case 'Grid - Simple':
      return <GridSimple {...rest} />
    case 'Carousel':
      return <CarouselSlick {...rest} />
    case 'Carousel - XS':
      return <CarouselXsContainer {...rest} />
    case 'Carousel - Inline':
      return <CarouselSlickInline {...rest} />
    default:
      return null
  }
}

export default BladeGallery

BladeGallery.propTypes = {
  layout: PropTypes.string,
}
