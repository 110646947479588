import { useEffect, useState } from 'react'
import useWindowReady from './useWindowReady'

const usePlatform = () => {
  const [platform, setPlatform] = useState(null)
  const [os, setOS] = useState(null)
  const windowReady = useWindowReady()

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  useEffect(() => {
    if (windowReady) {
      const userAgent = window.navigator.userAgent
      const platform = window.navigator.platform
      let os = null
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
      }
      setPlatform(platform)
      setOS(os)
    }
  }, [iosPlatforms, macosPlatforms, windowReady, windowsPlatforms])

  return [platform, os]
}

export default usePlatform
