import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '../../hooks'
import { Container } from './styles'
import TextContent from './TextContent'
import { verticalObj } from '../../utils/index'
import { ThemeContext } from 'styled-components'

const Text = ({
  theme = true,
  overlayCopy,
  media,
  bladeHeight = '20',
  textAlignmentVertical = 'center',
  textAlignmentHorizontal = 'left',
}) => {
  const [isMobile] = useMobile('39.9375em')
  const themePackage = useContext(ThemeContext)
  let t = theme ? 'light' : 'dark'

  const backgroundImage =
    media &&
    media[0] &&
    media[0].srcContentful &&
    media[0].srcContentful.fluid &&
    media[0].srcContentful.fluid.src

  const bg = themePackage.colors[t].txtBg || themePackage.colors[t].bg

  return (
    <Container
      bg={bg}
      color={`${t}.text`}
      height="auto"
      minHeight={`${bladeHeight}vh`}
      textAlign={textAlignmentHorizontal}
      margin="0 auto"
      p={[3, 3, 3, 4, 4, 4]}
      display="flex"
      justifyContent="center"
      alignItems={verticalObj[textAlignmentVertical]}
    >
      <TextContent
        backgroundImage={backgroundImage}
        theme={t}
        overlayCopy={overlayCopy}
      />
    </Container>
  )
}

Text.propTypes = {
  name: PropTypes.string,
  minHeight: PropTypes.string,
  theme: PropTypes.bool.isRequired,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string.isRequired,
  }),
  media: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string.isRequired,
      title: PropTypes.string,
      src: PropTypes.string.isRequired,
      srcContentful: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
      thumbnail: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
    })
  ),
}

export default Text
