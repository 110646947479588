import styled, { keyframes } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  shadow,
} from 'styled-system'

export const Container = styled.div`
${color}
${layout}
${position}
${space}
${flexbox}
${typography}
`

export const RichTextOverlay = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  
  `
export const Background = styled(BackgroundImage)`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`

export const Row = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`
export const Cell = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${shadow}
`
export const IconContainer = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
`

export const StyledIcon = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  margin-left: calc(100vw / 2 - (20px / 2));
  -moz-animation: ${bounce} 2s 3;
  -webkit-animation: ${bounce} 2s 3;
  animation: ${bounce} 2s 3;
  bottom: 10px;
  
  `

export const LineSpan = styled.span`
  background: #5bc2e7;
  border-radius: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
`
export const LineLeft = styled(LineSpan)`
  transform: rotate(45deg);
  background: ${({ bg }) => bg};
`
// background: ${({bg}) => bg};
export const LineRight = styled(LineSpan)`
  transform: translateX(12.8px) rotate(-45deg);
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}
`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}
`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}
`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}
`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}
`
export const P = styled.p`
${color}
  ${layout}
  ${typography}
`
export const Button = styled.button`
${color}
  ${layout}
  ${typography}
  width: 160px;
  height: auto;
  padding: 10px 0px;
  border-radius: 30px;
  border: solid 0.5px white;
  background: none;
  color: white;
  
  font-family: Catamaran;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.14px;
  text-align: center;
`

export const FontCheck = styled.div`
  ${typography}
`

export const StyledNotificationIcon = styled.img`
  width: 50px;
  height: 50px;
  ${space}
  ${color}
  ${space}
`
