import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { useComponentTheme, useMobile, useWindowReady } from '../../hooks'
import {
  Background,
  StyledSlider,
  StyledArrowContainer,
  StyledButtonWidth,
  StyledButtonContainer,
  StyledButton,
  StyledContentContainer,
  StyledCarouselContainer,
} from './style'
import CarouselSlickInlineHeader from './CarouselSlickInlineHeader'
import CarouselSlickInlineBtn from './CarouselSlickInlineBtn'
import CarouselSlickInlineContent from './CarouselSlickInlineContent'
import { ThemeContext } from 'styled-components'
import { mappedString } from '../../utils'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Carousel component with settings, provide an array of objects with fluid props
const CarouselSlickInline = ({
  title,
  subtitle,
  theme: themeBool = true,
  galleryItems = [],
  autoPlay,
  height = '75vh',
}) => {
  const windowReady = useWindowReady()
  const { theme } = useComponentTheme(themeBool)
  const themePackage = useContext(ThemeContext)
  const slider = useRef(null)
  const [isMobile] = useMobile()

  const mappedGalleryItems = galleryItems.map(g => {
    const stringArr = mappedString(g?.description?.json?.content, 'text')
    let turnIntoString = stringArr && stringArr.join(' ')
    if (turnIntoString && turnIntoString.split(' ').length > 30) {
      turnIntoString = turnIntoString
        .split(' ')
        .slice(0, 30)
        .join(' ')
    } else {
      turnIntoString = null
    }
    return {
      ...g,
      shortenDescription: turnIntoString && `${turnIntoString}...`,
    }
  })

  const carouselSettings = {
    autoplay: autoPlay,
    dots: true,
    arrows: false,
    speed: 300,
    adaptiveHeight: true,
    maxWidth: themePackage.sizes.maxWidth.xlargeCarousel,
    mobileView: isMobile,
    dotColor: themePackage.colors.carouselDots,
    dotFillColor: themePackage.colors[theme].text,
  }

  if (!windowReady) return null
  return (
    <Background
      data-testid="carousel-default"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <StyledCarouselContainer
        textAlign="left"
        pt={3}
        pb={5}
        width={isMobile ? '100%' : '65vw'}
        height="auto"
        maxWidth={`calc(${themePackage.sizes.maxWidth.xlargeNav} - 50px)`}
      >
        <CarouselSlickInlineHeader
          theme={theme}
          title={title}
          subtitle={subtitle}
          isMobile={isMobile}
        />
        {/* two absolute inside */}
        <StyledArrowContainer position="relative">
          <StyledButtonWidth
            position="absolute"
            left={isMobile ? '0' : '-10%'}
            right={isMobile ? '0' : '-10%'}
            top="0"
            bottom="0"
            margin="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <StyledButtonContainer
              display="flex"
              flexDirection="row"
              width="100%"
              px={isMobile ? 0 : 3}
              margin="0 auto"
              justifyContent="space-between"
              maxWidth={themePackage.sizes.maxWidth.xlargeNav}
            >
              <StyledButton zIndex={2}>
                <CarouselSlickInlineBtn
                  onClick={() => slider.current.slickPrev()}
                  direction="left"
                  fill={themePackage.colors[theme].btnBg}
                  hover={themePackage.colors[theme].btnHover}
                  arrowColor={themePackage.colors[theme].text}
                />
              </StyledButton>
              <StyledButton zIndex={2}>
                <CarouselSlickInlineBtn
                  onClick={() => slider.current.slickNext()}
                  direction="right"
                  fill={themePackage.colors[theme].btnBg}
                  hover={themePackage.colors[theme].btnHover}
                  arrowColor={themePackage.colors[theme].text}
                />
              </StyledButton>
            </StyledButtonContainer>
          </StyledButtonWidth>
          <StyledSlider ref={slider} {...carouselSettings}>
            {mappedGalleryItems &&
              mappedGalleryItems.map((content, i) => {
                return (
                  <StyledContentContainer
                    key={content.id ? content.id : `content+${i}`}
                    height={isMobile ? '50vh' : '70vh'}
                    width="auto"
                    position="relative"
                    maxWidth={themePackage.sizes.maxWidth.xlargeNav}
                  >
                    <CarouselSlickInlineContent
                      content={content}
                      height="485px"
                    />
                  </StyledContentContainer>
                )
              })}
          </StyledSlider>
        </StyledArrowContainer>
      </StyledCarouselContainer>
    </Background>
  )
}

CarouselSlickInline.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.bool,
  autoPlay: PropTypes.bool,
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnail: PropTypes.object,
      description: PropTypes.exact({
        json: PropTypes.exact({
          data: PropTypes.object,
          content: PropTypes.arrayOf(PropTypes.object).isRequired,
          nodeType: PropTypes.string.isRequired,
        }),
      }),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string,
          title: PropTypes.string,
          src: PropTypes.string,
          srcContentful: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
          thumbnail: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
        })
      ).isRequired,
    })
  ),
  fullWidth: PropTypes.bool,
}

export default CarouselSlickInline
