import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import Image from '../../Image'
import Video from '../../Video'
import { renderBgTypeAndBg } from '../../../utils'

const ModalWrapper = ({
  thumbnail,
  contentType,
  theme = 'dark',
  label,
  type,
  fields,
}) => {
  let t = theme ? 'light' : 'dark'
  // mediaObj.__typename = targetEntry.

  const {
    assetId,
    type: newType,
    src,
    thumbnail: newThumbnail,
    alt,
    autoPlay,
  } = renderBgTypeAndBg({
    background: fields,
    theme: t,
  })

  if (!fields) return <div />
  return (
    <Modal
      theme={theme}
      label={label}
      type={type}
      contentType={contentType}
      thumbnail={thumbnail}
    >
      {contentType === 'mediaVideo' ? (
        <Video src={src} media={fields} theme={theme} modal />
      ) : (
        <Image media={fields} theme={theme} />
      )}
    </Modal>
  )
}

ModalWrapper.propTypes = {
  theme: PropTypes.string,
  type: PropTypes.string.isRequired,
  media: PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.object,
      modalContent: PropTypes.object,
      theme: PropTypes.object,
      mediaReference: PropTypes.object,
    }),
    sys: PropTypes.object,
  }),
  children: PropTypes.array,
}

export default ModalWrapper
