import React from 'react'
import { StyledTimelineWrapper, StyledOverflowContainer } from './styles'
import TimelineItem from './TimelineItem'
import { useComponentTheme } from '../../hooks'

import PropTypes from 'prop-types'

const TimelineBanner = ({ entries = [], theme = true }) => {
  const { theme: themeStyle } = useComponentTheme(theme)
  if (!entries) return null
  return (
    <StyledTimelineWrapper width="100%" bg={`${themeStyle}.bg`}>
      <StyledOverflowContainer
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="flex-start"
      >
        {entries?.map(entry => (
          <TimelineItem key={entry.id} {...entry} theme={theme} />
        ))}
      </StyledOverflowContainer>
    </StyledTimelineWrapper>
  )
}

TimelineBanner.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string,
      copy: PropTypes.objectOf(PropTypes.string),
      image: PropTypes.object,
    })
  ),
  theme: PropTypes.bool,
}

export default TimelineBanner
