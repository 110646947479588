import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Image from '../../../components/Image'
import { PreviewAssetsContext } from '../../../context'
import { formatImageMediaArr } from '../../../utils'

const ImageContainer = ({ overlayCopy, theme, mediaObj, assetId, ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)

  let mediaArr = formatImageMediaArr({
    assets,
    mediaObj,
    assetId,
  })

  return (
    <Image overlayCopy={overlayCopy} theme={theme} media={mediaArr} {...rest} />
  )
}

ImageContainer.propTypes = {
  overlayCopy: PropTypes.object,
  theme: PropTypes.bool,
  mediaObj: PropTypes.object,
  assetId: PropTypes.string,
}

export default ImageContainer
