import React from 'react'
import { StyledGridContainer } from './GridToggle/styles'

export const GridContainer = ({ children }) => {
  return (
    <StyledGridContainer
      maxWidth="maxWidth.xxlarge"
      margin="0 auto"
      display="grid"
      fontFamily="body"
      padding="0 1.25rem"
      id="grid-toggle"
    >
      {children}
    </StyledGridContainer>
  )
}

export default GridContainer
