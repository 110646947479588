import React from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, { GET_ENTRY } from '../../hooks/usePreviewQuery'
import { useState, useEffect, useMobile } from '../../hooks'
import CompanyItemContainer from './components/CompanyItemContainer'
import TeamContainer from './components/TeamContainer'
import GridSimpleApolloContentfulPreview from './components/GridSimpleApolloContentfulPreviewContainer'
import GridWrapper from '../../components/Grid/GridContainer'

const GridContainer = ({ type, theme = true, id, ...rest }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skip: !id,
  })
  const [isMobile] = useMobile()
  const [galleryItemIds, setGalleryItemIds] = useState([])
  const [active, setActive] = useState(99)
  let t = theme ? 'light' : 'dark'

  const toggle = i => {
    if (i === active) {
      setActive(99)
    } else {
      setActive(i)
    }
  }

  const filterLinkedIds = ({ dataObj, key }) => {
    if (!dataObj.fields[key]) {
      return []
    }
    return dataObj.fields[key].map(({ sys }) => ({ id: sys.id }))
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const bIds = filterLinkedIds({ dataObj: data.entry, key: 'galleryItems' })
      setGalleryItemIds(bIds)
    }
  }, [loading, data, error])

  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    switch (type) {
      case 'team':
        return <TeamContainer galleryItemIds={galleryItemIds} theme={theme} />
      case 'simple':
        return (
          <GridSimpleApolloContentfulPreview
            galleryItemIds={galleryItemIds}
            theme={theme}
            {...rest}
          />
        )
      case 'company':
        return (
          <GridWrapper>
            {galleryItemIds.length >= 1 &&
              galleryItemIds.map((item, i) => (
                <CompanyItemContainer
                  isMobile={isMobile}
                  toggle={toggle}
                  t={t}
                  active={active}
                  setActive={setActive}
                  key={i}
                  i={i}
                  id={item.id}
                />
              ))}
          </GridWrapper>
        )
      default:
        return <div />
    }
  }
}

GridContainer.propTypes = {}

export default GridContainer
