import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledBackBtn,
  StyledSvgContainer,
  StyledSvg,
  StyledContainer,
  StyledText,
} from '../styles'
import backArrow from '../../../assets/back-arrow.svg'

const BackButton = ({ prevTitle, onClick }) => {
  return (
    <StyledBackBtn pb={3} mb={2} onClick={onClick}>
      <StyledContainer display="flex">
        <StyledSvgContainer>
          <StyledSvg src={backArrow} alt="back-arrow" />
        </StyledSvgContainer>
        <StyledText fontWeight={400} color="#0072CE" fontSize={6} ml={2} pl={1}>
          {`Back`} {prevTitle && `to ${prevTitle}`}
        </StyledText>
      </StyledContainer>
    </StyledBackBtn>
  )
}

BackButton.propTypes = {
  prevTitle: PropTypes.string,
  onClick: PropTypes.func,
}

export default BackButton
