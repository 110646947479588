import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}

`
export const Background = styled(BackgroundImage)`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`
export const BackgroundDiv = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`

export const RichTextOverlay = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  `

export const Cell = styled.div`
  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
`

export const StyledIcon = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  margin-left: calc(100vw / 2 - (30px / 2));
  -moz-animation: ${bounce} 2s 3;
  -webkit-animation: ${bounce} 2s 3;
  animation: ${bounce} 2s 3;
  bottom: 52px;

  
  `

export const LineSpan = styled.span`
  background: white;
  border-radius: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
`
export const LineLeft = styled(LineSpan)`
  transform: rotate(45deg);
  background: ${({ bg }) => bg};
`
// background: ${({bg}) => bg};
export const LineRight = styled(LineSpan)`
  background: ${({ bg }) => bg};
  transform: translateX(12.8px) rotate(-45deg);
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}

`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}

`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}

`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}

`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
  ${space}
  text-transform: uppercase;
`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}

`
export const P = styled.p`
${color}
  ${layout}
  ${typography}
  ${space}
`
export const Button = styled.button`
${color}
  ${layout}
  ${typography}
  ${border}

  &:hover {
    background-color: #0025435c;
  }

`

export const FontCheck = styled.div`
  ${typography}
`

export const B = styled.b`
${color}
  ${layout}
  ${typography}

`

export const I = styled.i`
${color}
  ${layout}
  ${typography}

`

export const U = styled.u`
${color}
  ${layout}
  ${typography}

`

export const StyledImage = styled(Img)`
  ${layout}
`
