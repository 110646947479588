import React from 'react'
import PropTypes from 'prop-types'
import ModalWrapper from '../../components/Modal/components/ModalWrapper'

const ModalContainer = ({ media, theme, children, type }) => {
  const us = 'en-US'
  theme = theme ? 'light' : 'dark'
  if (!media) {
    return null
  } else if (media.fields) {
    const fields =
      media &&
      media.fields.mediaReference &&
      media.fields.mediaReference[us] &&
      media.fields.mediaReference[us].fields

    const thumbnail =
      fields &&
      fields.thumbnail &&
      fields.thumbnail[us] &&
      fields.thumbnail[us].fields &&
      fields.thumbnail[us].fields.file &&
      fields.thumbnail[us].fields.file[us] &&
      fields.thumbnail[us].fields.file[us].url

    const contentType =
      media &&
      media.fields.mediaReference &&
      media.fields.mediaReference[us] &&
      media.fields.mediaReference[us].sys &&
      media.fields.mediaReference[us].sys.contentType &&
      media.fields.mediaReference[us].sys.contentType.sys &&
      media.fields.mediaReference[us].sys.contentType.sys.id

    if (!fields) return <div />
    return (
      <ModalWrapper
        theme={theme}
        label={children}
        type={type}
        contentType={contentType}
        thumbnail={thumbnail}
        fields={fields}
      />
    )
  } else return null
}

ModalContainer.propTypes = {
  theme: PropTypes.bool,
  type: PropTypes.string.isRequired,
  media: PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.object,
      modalContent: PropTypes.object,
      theme: PropTypes.object,
      mediaReference: PropTypes.object,
    }),
    sys: PropTypes.object,
  }),
  children: PropTypes.array,
}

export default ModalContainer
