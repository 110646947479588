import React from 'react'
import { Cell } from '../Image/styles.js'
import { OverlayContainer } from './styles.js'
import { richText } from './../RichText/index'

const OverlayText = ({ overlayCopy, textAlign, alignItems, themePackage }) => {
  return (
    <OverlayContainer
      width={1}
      display="flex"
      justifyContent="center"
      textAlign={textAlign}
      alignItems={alignItems}
      p={[1, 1, 2, 2]}
    >
      <Cell width={[3 / 4, 2 / 3, 2 / 3, 2 / 3]}>
        {overlayCopy && richText(themePackage, overlayCopy)}
      </Cell>
    </OverlayContainer>
  )
}

export default OverlayText
