import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledTimelineWrapper = styled(Container)``

export const StyledTimelineContainer = styled(Container)``

export const StyledImgTextContainer = styled.li`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledBackground = styled(BackgroundImage)`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export const StyledTitle = styled.p`
  text-shadow: 2px 2px 4px #000000; 
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export const StyledText = styled.p`
  text-shadow: 2px 2px 4px #000000; 
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
export const StyledBorderOverlay = styled(Container)`
  box-sizing: border-box;
`

export const StyledBorder = styled(Container)``

export const StyledUl = styled.ul`
  list-style-type:none;
  text-shadow: 2px 2px 4px #000000;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`

export const StyledDot = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}

`
export const StyledOverflowContainer = styled(Container)``

export const StyledDarkenOverlay = styled(Container)`
  background-color: black;
  opacity: 0.3;
`
