import { useState, useRef, useEffect } from 'react'

const useMeasure = () => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
      setWidth(ref.current.clientWidth)
    }
  }, [])

  return [ref, height, width]
}

export default useMeasure
