import styled, { keyframes } from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}

`
export const Background = styled.section`
box-sizing: border-box;
overflow: hidden;
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`
export const StyledSlider = styled(Slider)`

  //padding between carousel items
  .slick-slide>div {
    padding: ${({ mobileView }) => (mobileView ? `0 10px;` : '0 15px;')}
    transition: padding .2s;
    box-sizing: border-box;
  }

  .slick-list {
    overflow: visible;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    margin: auto;
  }

 

  //Remove Default Buttons
  .slick-prev:before {
    content: none;
  }
  .slick-next:before {
    content: none;
  }

  //Repositions dots to be inside carousel
  .slick-dots {
    bottom: 2%;
  }

  //Fills dot on active and remove them in mobile view
  .slick-dots li button:before {
    display: ${({ mobileView }) => (mobileView ? 'none' : 'block')};

    color: ${({ dotColor }) => dotColor};
    opacity: 1 !important;
    font-size: 11px;
  }

  .slick-dots li.slick-active button:before {
    display: ${({ mobileView }) => (mobileView ? 'none' : 'block')};

    color: transparent;
    font-size: 10px;
    -webkit-text-stroke: 1px ${({ dotColor }) => dotColor} !important;
  }
`

export const StyledArrow = styled(Container)`
  box-sizing: border-box;
`

export const Cell = styled.div`
  box-sizing: border-box;
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}

`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
`

export const StyledIcon = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  margin-left: calc(100vw / 2 - (20px / 2));
  -moz-animation: ${bounce} 2s 3;
  -webkit-animation: ${bounce} 2s 3;
  animation: ${bounce} 2s 3;
  bottom: 52px;

  
  `

export const LineSpan = styled.span`
  background: #5bc2e7;
  border-radius: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
`
export const LineLeft = styled(LineSpan)`
  transform: rotate(45deg);
  background: ${({ bg }) => bg};
`
export const LineRight = styled(LineSpan)`
  transform: translateX(12.8px) rotate(-45deg);
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}

`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}

`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}

`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}

`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}

`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}

`
export const P = styled.p`
${color}
  ${layout}
  ${typography}

`
export const Button = styled.button`
${color}
  ${layout}
  ${typography}
  ${border}

  &:hover {
    background-color: #0025435c;
  }

`

export const FontCheck = styled.div`
  ${typography}
`
export const ImageContainer = styled.div`
  ${space}
  ${position}
  ${layout}
  ${color}
`

export const StyledSvgContainer = styled.div`
  cursor: pointer;
  ${position}
  ${layout}
  ${color}
  ${space}
  ${({ transform }) => transform && `transform: ${transform};`}
`

export const StyledSvg = styled.img`
  ${({ transform }) => transform && `transform: ${transform};`}
  ${position}
  ${layout}
  ${space}
  ${color}
`
export const StyledArrowContainer = styled(Container)``

export const StyledButtonWidth = styled(Container)``

export const StyledButtonContainer = styled(Container)``

export const StyledButton = styled(Container)``
