import React from 'react'
import PropTypes from 'prop-types'
import { useState } from '../../../hooks'
import { renderRichText } from './richText'
import {
  H2,
  ArrowUp,
  GridCloseButton,
  GridExpand,
  GridClose,
  GridFeaturedMedia,
  GridDescription,
  Reel,
  PlayButton,
  GridCloseBottom,
  PrimaryA,
  InverseA,
  Cell,
  GridExpandMobileInner,
  GridExpandMobileOuter,
  GridTitleH2,
  AnimatedCell,
} from './styles'
import close from '../../../assets/close.svg'

const GridItemDescription = ({
  title,
  active,
  toggle,
  website,
  email,
  longDescription,
  reel,
  bgImageUrl,
  theme,
  i,
  isMobile,
}) => {
  const [videoIsVisible, setVideoIsVisible] = useState(false)

  const handleSetVideo = i => {
    setVideoIsVisible(true)
  }

  if (isMobile) {
    return (
      <GridExpandMobileOuter active={active} isMobile={isMobile}>
        <GridExpandMobileInner active={active} padding="1.25rem">
          <GridClose isMobile={isMobile} onClick={() => toggle(i)}>
            <img src={close} alt="close" />
          </GridClose>
          <Cell flex={1} padding="0 0rem 200px">
            <Cell padding="0 1rem">
              {reel ? (
                <>
                  <GridFeaturedMedia
                    img={false}
                    reel
                    backgroundImage={bgImageUrl}
                    active={active}
                    visible={!videoIsVisible}
                    minHeight="35vh"
                  >
                    <PlayButton
                      data-testid="play-button"
                      visible={!videoIsVisible}
                      onClick={() => handleSetVideo(i)}
                    />
                  </GridFeaturedMedia>
                  <Reel visible={videoIsVisible}>
                    <iframe
                      title={reel && reel.title}
                      src={reel && reel.srcUrl && reel.srcUrl}
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen=""
                      data-ready="true"
                    />
                  </Reel>
                </>
              ) : (
                <GridFeaturedMedia
                  img
                  backgroundImage={bgImageUrl}
                  active={active}
                  visible
                  minHeight="35vh"
                />
              )}
            </Cell>
            <GridDescription
              padding="0 1rem"
              color={`${theme}.text`}
              active={active}
              textAlign="left"
              isMobile={isMobile}
            >
              <H2
                color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
                fontSize="2.25rem"
                fontFamily="headings"
                lineHeight={1.4}
                fontWeight={theme === 'dark' ? 400 : 300}
                letterSpacing="0.23px"
                textAlign="left"
                textShadow={theme === 'dark' && 'text'}
              >
                {title}
              </H2>
              {longDescription &&
                renderRichText(longDescription, theme, active)}
              <Cell width="100%" paddingTop="1.75rem">
                {website && (
                  <PrimaryA
                    title={`Learn More about ${title} Click`}
                    href={website}
                    target="_blank"
                    data-tr={`Discover More - ${title} Click`}
                    margin={'.75rem 1.25rem .75rem 0'}
                    padding={' 0.75rem 1.5rem'}
                  >
                    Discover More
                  </PrimaryA>
                )}
                <InverseA
                  title={`Contact ${title} Click`}
                  href={`mailto:${email}`}
                  target="_blank"
                  data-tr={`Contact Us - ${title} Click`}
                  padding={'0.75rem 1.5rem'}
                >
                  Contact Us
                </InverseA>
              </Cell>
            </GridDescription>
          </Cell>
          <GridCloseBottom>
            <Cell
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <ArrowUp isMobile={isMobile} active={active} />
              <GridCloseButton
                data-testid="grid-close-button"
                onClick={() => toggle(i)}
                fontFamily="body"
              >
                Close
              </GridCloseButton>
            </Cell>
          </GridCloseBottom>
        </GridExpandMobileInner>
      </GridExpandMobileOuter>
    )
  }

  return (
    <GridExpand
      active={active}
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      width="100%"
    >
      <GridClose onClick={() => toggle(i)}>
        <img src={close} alt="close" />
      </GridClose>
      <Cell width={[1, 1, 1, 7 / 12]}>
        <GridFeaturedMedia
          img={!reel}
          reel={reel}
          backgroundImage={bgImageUrl}
          active={active}
          visible={!videoIsVisible}
          minHeight="45vh"
        >
          {reel && (
            <PlayButton
              data-testid="play-button"
              visible={!videoIsVisible}
              onClick={() => handleSetVideo(i)}
            />
          )}
        </GridFeaturedMedia>
        <Reel visible={videoIsVisible}>
          <iframe
            title={reel && reel.title}
            src={reel && reel.srcUrl && reel.srcUrl}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen=""
            data-ready="true"
          />
        </Reel>
      </Cell>
      <GridDescription
        width={[1, 1, 1, 5 / 12]}
        height="400px"
        color={`${theme}.text`}
        active={active}
        textAlign="left"
      >
        <GridTitleH2
          color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
          fontSize={[4, 3, 3, 3, 3, 3, 3]}
          fontFamily="headings"
          lineHeight={1.4}
          fontWeight={theme === 'dark' ? 400 : 300}
          letterSpacing="0.23px"
          textAlign="left"
          textShadow={theme === 'dark' && 'text'}
          active={active}
        >
          {title}
        </GridTitleH2>
        {longDescription && renderRichText(longDescription, theme, active)}
        <AnimatedCell
          width="100%"
          paddingTop="2rem"
          active={active && !isMobile}
        >
          {website && (
            <PrimaryA
              title={`Learn More about ${title} Click`}
              href={website}
              target="_blank"
              data-tr={`Discover More - ${title} Click`}
              padding={'.75rem 1.5rem'}
              margin={'.75rem 1.25rem .75rem 0'}
            >
              Discover More
            </PrimaryA>
          )}
          <InverseA
            title={`Contact ${title} Click`}
            href={`mailto:${email}?subject=a.network+contact`}
            target="_blank"
            data-tr={`Contact Us - ${title} Click`}
            padding={'.75rem 1.5rem'}
            margin={'.75rem 1.25rem .75rem 0'}
          >
            Contact Us
          </InverseA>
        </AnimatedCell>
      </GridDescription>
    </GridExpand>
  )
}

GridItemDescription.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  toggle: PropTypes.func,
  website: PropTypes.string,
  email: PropTypes.string,
  longDescription: PropTypes.object,
  reel: PropTypes.object,
  bgImageUrl: PropTypes.string,
  i: PropTypes.number,
  theme: PropTypes.string,
  isMobile: PropTypes.bool,
}

export default GridItemDescription
