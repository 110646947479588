import React from 'react'
import { StyledImage } from '../styles'
import { ModalVideo } from '../../Video/VideoComponent'

const ModalPopUpContent = ({ contentType, theme, description, content }) => {
  switch (contentType) {
    case 'mediaImage':
      return (
        <StyledImage
          src={`https://${content.src}`}
          theme={theme}
          height="100%"
          width="100%"
          alt={description || 'modal-image'}
          boxShadow="0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2)"
          borderRadius="0.3rem"
        />
      )
    case 'mediaVideo':
      return (
        <ModalVideo
          src={content.src}
          autoPlay={content.autoPlay}
          mute={false}
          title={content.title}
          thumbnail={content.thumbnail}
          background={false}
          borderRadius="0.3rem"
          boxShadow="0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2)"
        />
      )
    default:
      return null
  }
}
export default ModalPopUpContent
