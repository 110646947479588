import React from 'react'
import { renderRichText } from './../richText'
import { DescriptionContainer, QuoteContainer } from './../style'
import PropTypes from 'prop-types'

const DescriptionSlick = ({
  content,
  theme = 'dark',
  isMobile,
  name,
  shortenDescription,
  title,
}) => {
  return (
    <DescriptionContainer isMobile={isMobile} pl={isMobile ? 0 : 4}>
      <QuoteContainer>
        {renderRichText(content, theme, shortenDescription)}
      </QuoteContainer>
      <QuoteContainer
        fontSize={[6]}
        fontWeight="200"
        fontFamily="body"
        letterSpacing={0.28}
        color={`${theme}.text`}
      >
        {name}
      </QuoteContainer>
      {title && (
        <QuoteContainer
          fontSize={15}
          fontWeight="200"
          lineHeight="1.61"
          letterSpacing={0.28}
          color={`${theme}.text`}
        >
          {title}
        </QuoteContainer>
      )}
    </DescriptionContainer>
  )
}

DescriptionSlick.propTypes = {
  content: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string.isRequired,
  }),
  theme: PropTypes.string,
  isMobile: PropTypes.bool,
  name: PropTypes.string,
  shortenDescription: PropTypes.string,
  title: PropTypes.string,
}

export default DescriptionSlick
