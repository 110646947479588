import React, { useState, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import { StyledMap, StyledMapContainer, StyledContainer } from './styles'
import './map.css'
import Sidebar from './Sidebar/Sidebar'
import useMap from './useMap'
import _ from 'lodash'
import { useLockBodyScroll } from '../../hooks'

const getAllFeatures = featureCollections =>
  _.flatten(featureCollections.map(({ features }) => features))

const Map = ({
  featureCollections = [],
  initCenter = [-117.97091, 34.12958],
  heightOffset = '75px',
  initZoom = 10, // controls where the zoom starts out
  maxZoom = 16,
  mapStyle = process.env.GATSBY_MAPBOX_STYLE,
  siteInfo,
  closures = [],
}) => {
  const centerRef = useRef(initCenter)
  const [allFeatures, setAllFeatures] = useState(
    getAllFeatures(featureCollections)
  )

  const initView = {
    type: 'main',
    obj: { features: allFeatures },
    category: 'All',
  }

  const [view, setView] = useState({
    current: initView,
    previous: initView,
  })

  const handleFeatureClick = useCallback(({ category, ...rest }) => {
    const nextView = {
      type: 'detail',
      obj: { ...rest },
      category,
    }
    setView(({ current, previous }) => ({
      current: nextView,
      previous: current.type === 'detail' ? previous : current,
    }))
  }, [])

  const [mapContainer, dispatch] = useMap({
    featureCollections: featureCollections,
    initCenter: centerRef.current,
    initZoom,
    maxZoom,
    mapStyle,
    showPopup: false,
    showLabel: true,
    handleFeatureClick,
  })

  useLockBodyScroll()
  useEffect(() => {
    setAllFeatures(getAllFeatures(featureCollections))
  }, [featureCollections])

  return (
    <StyledMapContainer
      position="fixed"
      height={`calc(100vh - ${heightOffset})`}
      width="100%"
      zIndex={1}
    >
      {/* Blank BG to hide footer on init render */}
      <StyledContainer bg="white" zIndex={1} height="100vh" width="100vw" />

      <Sidebar
        featureCollections={featureCollections}
        dispatch={dispatch}
        view={view}
        setView={setView}
        initView={initView}
        zIndex={3}
        siteInfo={siteInfo}
        closures={closures}
      />

      <StyledMap
        ref={el => (mapContainer.current = el)}
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        zIndex={2}
      />
    </StyledMapContainer>
  )
}

Map.propTypes = {
  featureCollections: PropTypes.arrayOf(
    PropTypes.shape({
      features: PropTypes.arrayOf(
        PropTypes.shape({
          geometry: PropTypes.object,
          id: PropTypes.string,
          properties: PropTypes.object,
        })
      ),
      icon: PropTypes.shape({
        customIcon: PropTypes.object,
        icon: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
      }),
      id: PropTypes.string,
      label: PropTypes.string,
      marker: PropTypes.object,
      title: PropTypes.string,
    })
  ),
  initCenter: PropTypes.arrayOf(PropTypes.number),
  heightOffset: PropTypes.string,
  maxZoom: PropTypes.number,
  mapStyle: PropTypes.string,
}

export default Map
