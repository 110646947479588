import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import BladeText from '../../components/BladeText'
import {
  formatMediaArr,
  filterLinkedIdsArr,
  renderBgTypeAndBg,
} from '../../utils'
import { PreviewAssetsContext } from '../../context'
import usePreviewQuery, {
  GET_MEDIA,
  GET_COLUMNS,
} from '../../hooks/usePreviewQuery'

const ColumnsContainer = ({ backgroundId, columnIds, theme, ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)
  const {
    loading: loadingBackground,
    error: errorBackground,
    data: dataBackground,
  } = usePreviewQuery({
    query: GET_MEDIA,
    skip: !backgroundId,
    id: backgroundId,
  })
  const {
    loading: loadingColumns,
    error: errorColumns,
    data: dataColumns,
    networkStatus: columnNetworkStatus,
  } = usePreviewQuery({
    query: GET_COLUMNS,
    skip: !columnIds,
    ids: columnIds.map(({ id }) => id).join(','),
  })

  if ((backgroundId && loadingBackground) || loadingColumns) return null
  if ((backgroundId && errorBackground) || errorColumns) {
    return ` `
  }
  if (
    backgroundId
      ? dataColumns && !loadingColumns && dataBackground && !loadingBackground
      : dataColumns && !loadingColumns
  ) {
    let bg =
      dataBackground?.entry?.fields &&
      formatMediaArr({
        assets,
        mediaObj: dataBackground.entry.fields,
        assetId: dataBackground.entry?.fields?.srcContentful?.sys?.id,
      })

    let t = theme ? 'light' : 'dark'
    const { assetId, type, src, thumbnail, alt, autoPlay } = renderBgTypeAndBg({
      background: dataBackground && bg,
      theme: t,
    })
    let columns = columnIds.map(({ id }) => ({
      border: dataColumns?.entries?.items.filter(item => item.sys?.id === id)[0]
        .fields?.border,
      width: dataColumns?.entries?.items.filter(item => item.sys?.id === id)[0]
        .fields?.width,
      richText: dataColumns?.entries?.items.filter(
        item => item.sys?.id === id
      )[0].fields?.overlayCopy,
      textAlignmentHorizontal: dataColumns?.entries?.items.filter(
        item => item.sys?.id === id
      )[0].fields?.textAlignmentHorizontal,
      textAlignmentVertical: dataColumns?.entries?.items.filter(
        item => item.sys?.id === id
      )[0].fields?.textAlignmentVertical,
      id,
    }))

    return (
      <BladeText
        assetId={assetId}
        assetAltTitle={alt}
        autoPlay={autoPlay}
        bg={src}
        bgType={type}
        columns={columns}
        theme={t}
        thumbnail={thumbnail}
        {...rest}
      />
    )
  } else {
    return <div />
  }
}

const BladeTextApolloContentfulPreviewContainer = ({
  background = [],
  columns = [],
  height = 100,
  theme = false,
  displayScrollIcon = false,
  backgroundColor,
  backgroundOpacity,
}) => {
  const [backgroundId, setBackgroundId] = useState() // id comes down as str
  const [columnIds, setColumnIds] = useState([])

  useEffect(() => {
    if (Array.isArray(background) && background.length === 1) {
      setBackgroundId(background[0].sys.id)
    }
  }, [background])

  useEffect(() => {
    if (Array.isArray(columns) && columns.length >= 1) {
      let cIds = filterLinkedIdsArr({ arr: columns })
      setColumnIds(cIds)
    }
  }, [columns])

  return (
    <ColumnsContainer
      backgroundId={backgroundId}
      columnIds={columnIds}
      height={height}
      theme={theme}
      displayScrollIcon={displayScrollIcon}
      backgroundColor={backgroundColor}
      backgroundOpacity={backgroundOpacity}
    />
  )
}

BladeTextApolloContentfulPreviewContainer.propTypes = {
  layout: PropTypes.string,
  theme: PropTypes.bool,
}

export default BladeTextApolloContentfulPreviewContainer
