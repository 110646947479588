import React from 'react'
import PropTypes from 'prop-types'
import {
  BladeCustom,
  BladeGallery,
  BladeMediaText,
  BladeList,
} from '../../components/Blade'
import BladeTextContainer from '../BladeTextContainer'

const renderBladeBasedOnType = ({ type, id, ...rest }) => {
  switch (type) {
    case 'ContentfulBladeMediaOrText':
      return <BladeMediaText key={id} {...rest} />
    case 'ContentfulBladeText':
      return <BladeTextContainer key={id} {...rest} />
    case 'ContentfulBladeGallery':
      return <BladeGallery key={id} {...rest} />
    case 'ContentfulBladeCustom':
      return <BladeCustom key={id} {...rest} />
    case 'ContentfulBladeList':
      return <BladeList key={id} {...rest} />
    default:
      return <div />
  }
}

const Blades = ({ blades = [], location, pageTitle }) => {
  if (blades.length < 1) {
    return <div />
  } else {
    return blades.map(({ __typename, id, ...rest }) =>
      renderBladeBasedOnType({
        type: __typename,
        id,
        location,
        pageTitle,
        ...rest,
      })
    )
  }
}

Blades.propTypes = {
  blades: PropTypes.array,
}
export default Blades
