import styled from 'styled-components'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  shadow,
  grid,
  background,
} from 'styled-system'
import close from '../../../assets/close.svg'
import down from '../../../assets/down.svg'
import playButton from '../../../assets/anetwork-playbutton.svg'

export const BG = styled.section`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
`

export const ViewGridItemsCta = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
padding: 0px 0px 80px;

`
export const ViewGridItemsLink = styled.span`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
font-size: 1rem;
text-decoration: none;
display: inline-block;
text-transform: lowercase;

display: inline-block;

&:after {
  content: '';
  background-image: url(${down});
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 24px;
  width: 20px;
  display: block;
  margin: .75em auto 0 auto;
  opacity: .5;
  display: block;
}

`

// display: inline-block;
export const PrimaryA = styled.a`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;

  border: 2px solid #fff;
  color: #000;
  background: #fff;

`

// margin: 0.75rem 1.25rem 0.75rem 0;
// display: inline-block;
export const InverseA = styled.a`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;

  border: 2px solid #fff;
  color: #fff;
  background: 0 0;
`
export const StyledGridContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  
  @media screen and (max-width: 39.9375em) {
    padding: 1.25rem;
  }
  
  @media print,screen and (min-width: 40em) {
    display:-ms-grid;
    grid-gap: 1.25rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  @media print,screen and (min-width: 64em) {
    -ms-grid-columns:1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
`

export const GridExpandMobileOuter = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none!important')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  min-height: 100%;
  background-color: black;
  margin: 0;
  padding: 0;
`
export const GridExpandMobileInner = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  top: 0;
  left: 0;
  margin-left: 0;
  height: 100%;
  display: flex!important;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
  padding-top: 4em;
  background: black;
  color: white;
  z-index: 1006;

`
export const GridExpand = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  position: relative;
  background: #000;
  padding: 0;
  overflow: hidden;
  display: none;
  width: calc(100% + calc(1.25rem * 2));
  
  margin-top: ${({ active }) => active && '1.25rem'};
  padding: ${({ active }) => active && '2.5rem 0'};

  @media screen and (min-width: 40em) {
    opacity:${({ active }) => (active ? '1' : '0')};
    max-height: ${({ active }) => (active ? '150vh' : '0')};
    flex-direction: column;
  }
  
  @media print,screen and (min-width: 40em) {
    display:-webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  @media screen and (max-width: 39.9375em) {
    -webkit-transform:translateX(calc(1.25rem * -1));
    -ms-transform: translateX(calc(1.25rem * -1));
    transform: translateX(calc(1.25rem * -1));
    padding-bottom:${({ active }) => active && '0'};
    margin-top:${({ active }) => active && '0'};


  }
  
  @media print,screen and (min-width: 40em) {
    background:0 0;
    width: calc(200% + 1.25rem);
    max-height:${({ active }) => active && '85vh'};

  }
  
  @media print,screen and (min-width: 64em) {
    width:calc(300% + calc(2 * 1.25rem));
    max-height:${({ active }) => active && '65vh'};
    flex-direction: row;
  }

`

export const Tile = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
   width: 100%;
   
   @media screen and (max-width: 39.9375em) {
    margin-bottom: ${({ active, isHovered }) =>
      (active || isHovered) && '1.25rem'};
    transition: ${({ active, isHovered }) =>
      (active || isHovered) && 'margin-bottom .5s'};
  }
  position: relative;
`

export const Overlay = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  transition-duration: .8s;
  transition-timing-function: ease-out;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(to top,#000 15%,rgba(0,0,0,.25) 100%);
  opacity: ${({ active, isHovered }) => (active || isHovered) && '.75'}

`

export const GridLogo = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  & img {
    display: inline-block;
    max-width: 90%;
    height: 2.5rem;
    transition-duration: .4s;
    transition-timing-function: ease-out;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    object-fit: contain;


    

  }
`
export const GridCaption = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}

  -webkit-transform: ${({ active, isHovered }) =>
    active || isHovered ? 'translateY(0)' : 'translateY(25%)'};
  -ms-transform: ${({ active, isHovered }) =>
    active || isHovered ? 'translateY(0)' : 'translateY(25%)'};
  transform: ${({ active, isHovered }) =>
    active || isHovered ? 'translateY(0)' : 'translateY(25%)'};

  opacity: ${({ active, isHovered }) => (active || isHovered ? '1' : '0')};
  transition-duration: .4s;
  transition-timing-function: ${({ active, isHovered }) =>
    (active || isHovered) && 'ease-out'};
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  bottom: ${({ active, isHovered }) => (active || isHovered) && '5%'};
  transition-delay: ${({ active, isHovered }) =>
    (active || isHovered) && '.15s'};


  &:after {
    content: '';
    background-image: url(${down});
    background-repeat: no-repeat;
    background-position: 0 0;
    height: 24px;
    width: 20px;
    display: block;
    margin: .75em auto 0 auto;
    opacity: ${({ active, isHovered }) => (active || isHovered ? '.5' : '0')};
    transition-duration: .8s;
    transition-timing-function: ${({ active, isHovered }) =>
      (active || isHovered) && 'ease-out'};
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    transition-delay: ${({ active, isHovered }) =>
      (active || isHovered) && '.5s'}
  }
  
`

export const ArrowContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}

  height: ${({ active, isHovered }) => (active || isHovered) && '8px'};
  opacity: ${({ active, isHovered }) => (active || isHovered ? '1' : '0')};
`
export const ArrowUp = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transition: height .5s ease-out .5s;
  border-bottom: ${({ active, isMobile }) =>
    active && isMobile && '8px solid #191919'};
  text-align: center;
  margin: 0;
  padding: 0;

`

// active
// transition: opacity .5s;
// transition-delay: .5s;
// opacity: 1;
// outline: 0;
// -webkit-appearance: button;

// @media print, screen and (min-width: 64em)
// padding: 1rem 0;
//   margin: 1.25rem 0 1.25rem 1rem;

export const GridClose = styled.button`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
    position: absolute;
    padding: 0;
    margin: ${({ isMobile }) => (isMobile ? '20px' : 0)};
    right: 0;
    top: 0;
    z-index: 9;
    opacity: ${({ active, isHovered }) => (active || isHovered ? '1' : '0')}
    cursor: pointer;
    border: 0;
    border-radius: 0;
    background: 0 0;
    line-height: 1;
    cursor: pointer;

    transition: ${({ active, isHovered }) =>
      (active || isHovered) && 'opacity .5s'};
    transition-delay: ${({ active, isHovered }) =>
      (active || isHovered) && '.5s'};


`

export const Cell = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
${background}

`
export const AnimatedCell = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  ${background}

  opacity: ${({ active }) => (active ? '1' : '0')};

  transition-timing-function: ease-out;
  transition: -webkit-transform 0.3s ease;

  -webkit-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  -ms-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(20px)')}
  
  transition-duration: 0.8s;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;

  transition-delay: ${({ active }) => active && '1.1s'};


`
export const GridFeaturedMedia = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}
${background}

display: flex;
justify-content: center;
margin: 0;
padding: 0;
display: ${({ visible }) => !visible && 'none!important'};


opacity: ${({ active }) => (active ? '1' : '0')};
  -webkit-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};

  -ms-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(20px)')};

  transition: 0.5s;
  transition-timing-function: ease-out;

  transition-delay: ${({ active }) => active && '.35s'};

  background-repeat: ${({ img }) => img && 'no-repeat'};
  background-position: ${({ img }) => img && 'center'};
  background-size: ${({ img }) => img && 'cover'};
  min-height: ${({ img }) => img && '35vh'} @media screen and
  (max-width: 39.9375em) {
    margin-top: ${({ img }) => img && '1.25rem'};
  }
  @media print, screen and (min-width: 40em) {
    min-height: ${({ img }) => img && '45vh'};
  }


`

export const PlayButton = styled.button`
  outline: 0;
  -webkit-apperance: button;
  cursor: pointer;
  align-self: center;
  border-radius: 0;
  background: 0 0;
  line-height: 1;
  padding: 0;
  border: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;

  & :before {
    display: block;
    content: '';
    background: url(${playButton}) no-repeat center center;
    background-size: contain;
    height: 25vw;
    width: 25vw;
    max-width: 80px;
    max-height: 80px;

    @media print, screen and (min-width: 40em) {
      height: 10vw;
      width: 10vw;
    }
    @media print, screen and (min-width: 64em) {
      max-height: 80px;
      max-width: 80px;
    }
  }
`
export const Reel = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border: 1px solid #282828;
  display: ${({ visible }) => !visible && 'none!important'};

  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
`

export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}
  ${shadow}

`

// opacity: ${({ active }) => active && '1'};
// transition: -webkit-transform 0.8s ease;

export const GridTitleH2 = styled.h2`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  opacity: ${({ active }) => (active ? '1' : '0')};


  transition-timing-function: ease-out;
  transition: -webkit-transform 0.1s ease;


  -webkit-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  -ms-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(20px)')}

  transition-duration: 0.8s;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;

  transition-delay: ${({ active }) => active && '.7s'};

`
export const P = styled.p`
${color}
  ${layout}
  ${typography}

  ${shadow}
`

export const ActiveP = styled.p`
${color}
  ${layout}
  ${typography}

  ${shadow}
  opacity: ${({ active }) => (active ? '1' : '0')};

  transition-timing-function: ease-out;
  transition: -webkit-transform 0.2s ease;

  -webkit-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  -ms-transform: ${({ active }) =>
    active ? 'translateY(0)' : 'translateY(20px)'};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(20px)')}
  
  transition-duration: 0.8s;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;

  transition-delay: ${({ active }) => active && '.9s'};

  
`
export const GridDescription = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${grid}

  @media screen and (max-width: 39.9375em) {
    padding: 0 1.25rem;
    padding-top: 1.5rem;
  }
  @media print, screen and (min-width: 64em) {
    padding-left: 1.25rem;
    padding-top: 2.5rem;
  }


`

export const GridItemContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}

  
    @media screen and (max-width: 39.9375em) {
      margin-bottom: 1.25rem;
    }

@media screen and (min-width: 40em) and (max-width:63.9375em) {
  &:nth-child(2n) ${GridExpand} {
      -webkit-transform:translateX(calc(-50% - (.5 * 1.25rem)));
      -ms-transform: translateX(calc(-50% - (.5 * 1.25rem)));
      transform: translateX(calc(-50% - (.5 * 1.25rem)))
  }
}
@media print,screen and (min-width: 64em) {
  &:nth-child(3n+2) ${GridExpand} {
      -webkit-transform:translateX(calc(-33.33% - (.33 * 1.25rem)));
      -ms-transform: translateX(calc(-33.33% - (.33 * 1.25rem)));
      transform: translateX(calc(-33.33% - (.33 * 1.25rem)))
  }

  &:nth-child(3n+3) ${GridExpand} {
      -webkit-transform: translateX(calc(-66.66% - (.66 * 1.25rem)));
      -ms-transform: translateX(calc(-66.66% - (.66 * 1.25rem)));
      transform: translateX(calc(-66.66% - (.66 * 1.25rem)))
  }
}

`

export const Grid = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${grid}
  ${background}

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  min-height: 35vh;
  overflow: hidden;
  cursor: pointer;
  transition-duration: .8s;
  transition-timing-function: ease-out;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
`
export const GridCloseBottom = styled.div`
  width: 100%;
  height: 135px;
`
export const GridCloseButton = styled.button`
  color: #fff;
  background: #191919;
  width: 100%;
  ${typography}
  padding: 1.25rem 0 2rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;

  &:focus {
    border: none;
  }

  &:before {
    content: '';
    margin-right: 6px;
    background-image: url(${close});
    height: 20px;
    width: 20px;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
  }
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}
  ${shadow}
`

export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}
  ${shadow}
`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}
  ${shadow}
`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
  ${shadow}
`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}
  ${shadow}
`

export const FontCheck = styled.div`
  ${typography}
`
export const B = styled.b`
${color}
  ${layout}
  ${typography}
`
