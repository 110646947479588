import React from 'react'
import EmbeddedEntryBaseContainer from './EmbeddedEntryBaseContainer'
import usePreviewQuery, { GET_ENTRY_BY_ID } from '../../hooks/usePreviewQuery'

const EmbeddedEntryContainer = ({ node, children, theme, bgContentType }) => {
  const [richTextNode, setRichTextNode] = React.useState({})
  const {
    loading: loadingEntry,
    error: errorEntry,
    data: dataEntry,
  } = usePreviewQuery({
    query: GET_ENTRY_BY_ID,
    skip: node?.nodeType === 'hyperlink',
    id: node?.data?.target?.sys?.id,
  })

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const getLinkedEntryAndSanitizeFields = (arr, targetId) => {
    let entry = getLinkedEntry(arr, targetId)
    return {
      ...entry?.fields,
    }
  }

  const getLinkedMediaReferenceEntryWithLinkedAssets = (
    entryIncludes,
    assetIncludes,
    targetEntryId
  ) => {
    let entry = getLinkedEntry(entryIncludes, targetEntryId)

    if (entry?.fields?.srcContentful) {
      entry = {
        ...entry,
        fields: {
          ...entry?.fields,
          srcContentful: getLinkedEntryAndSanitizeFields(
            assetIncludes,
            entry?.fields?.srcContentful?.sys?.id
          ),
          thumbnail: getLinkedEntryAndSanitizeFields(
            assetIncludes,
            entry?.fields?.thumbnail?.sys?.id
          ),
        },
      }
    }
    return entry
  }

  const getMappedItem = React.useCallback(
    (data, entryIncludes, assetIncludes) => {
      if (!data) {
        return {}
      }
      const mappedItem = {
        id: data?.sys?.id,
        contentType: data?.sys?.contentType?.sys?.id,
        fields: {
          ...data?.fields,
          mediaReference:
            data?.fields?.mediaReference &&
            getLinkedMediaReferenceEntryWithLinkedAssets(
              entryIncludes,
              assetIncludes,
              data?.fields.mediaReference?.sys.id
            ),
          customIcon:
            data?.fields?.customIcon &&
            getLinkedEntry(assetIncludes, data?.fields.customIcon?.sys.id),
        },
      }
      for (var propName in mappedItem?.fields) {
        if (
          mappedItem?.fields?.[propName] === null ||
          mappedItem?.fields?.[propName] === undefined
        ) {
          delete mappedItem?.fields?.[propName]
        }
      }

      return mappedItem
    },
    []
  )

  React.useEffect(() => {
    if (
      !loadingEntry &&
      !errorEntry &&
      dataEntry &&
      node?.nodeType !== 'hyperlink'
    ) {
      const entry = dataEntry?.entry?.items?.[0]
      const entryNestedEntries = dataEntry?.entry?.includes?.Entry
      const entryNestedAssets = dataEntry?.entry?.includes?.Asset

      let mappedItem =
        entry && getMappedItem(entry, entryNestedEntries, entryNestedAssets)
      let n = {
        content: node.content,
        data: {
          target: {
            ...mappedItem,
          },
        },
        nodeType: node.nodeType,
      }
      setRichTextNode(n)
    }
    if (node?.nodeType === 'hyperlink') {
      setRichTextNode(node)
    }
  }, [loadingEntry, errorEntry, dataEntry, getMappedItem, node])

  return (
    <EmbeddedEntryBaseContainer
      node={richTextNode}
      theme={theme}
      bgContentType={bgContentType}
    >
      {children}
    </EmbeddedEntryBaseContainer>
  )
}

EmbeddedEntryContainer.propTypes = {}

export default EmbeddedEntryContainer
