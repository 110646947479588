/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, B, ActiveP } from './styles.js'
import { OpaqueButton } from '../../Button'
import Icon from '../../Icon'
import Video from '../../Video'

export const renderComponentFromContentType = (node, children) => {
  // const us = 'en-US'
  const base = node && node.data && node.data.target
  const type =
    base &&
    base.sys &&
    base.sys.contentType &&
    base.sys.contentType.sys &&
    base.sys.contentType.sys.id
  const fields = base && base.fields
  switch (type) {
    case 'mediaVideo':
      return <Video media={fields} />
    case 'icon':
      let { title, icon } = node.data.target.fields
      title = title['en-US']
      icon = icon['en-US']
      return <Icon icon={icon} title={title} />
    default:
      return null
  }
}

const EmbeddedComponent = ({ children, node, layout }) =>
  renderComponentFromContentType(node, children)

const options = (theme, active) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        // children && ((children[0][1] !== "") || (children.length >= 2)) &&
        <ActiveP
          color={`${theme}.text`}
          fontFamily="headings"
          lineHeight="1.6"
          fontSize="16px"
          fontWeight={300}
          w={[1]}
          textAlign="left"
          active={active}
        >
          {children}
        </ActiveP>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1
        color={`${theme}.text`}
        fontSize={[3, 2, 2, 2]}
        w={[1]}
        fontFamily="body"
        fontWeight={400}
        lineHeight={[0]}
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[4, 3, 3, 3, 3, 3, 3]}
        fontFamily="body"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3 color={`${theme}.text`} fontSize={[6, 5, 5, 5, 5, 5, 5]} w={[1]}>
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4 color={`${theme}.text`} fontSize={[6, 5, 5, 5, 5, 5, 5]} w={[1]}>
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <>{children}</>,
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <OpaqueButton theme={theme} href={node.data.uri}>
        {children}
      </OpaqueButton>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <OpaqueButton
        theme={theme}
        to={node.data.target.fields.slug['en-US']}
        href={node.data.uri}
      >
        {children}
      </OpaqueButton>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <OpaqueButton
        theme={theme}
        to={node.data.target.fields.slug['en-US']}
        href={node.data.uri}
      >
        {children}
      </OpaqueButton>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedComponent node={node}>{children}</EmbeddedComponent>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B
        color={`${theme}.text`}
        fontWeight={600}
        fontFamily="headings"
        fontSize="16px"
      >
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => <>{text}</>,
    [MARKS.UNDERLINE]: text => <>{text}</>,
  },
})

export const renderRichText = (document, theme, active) =>
  documentToReactComponents(document, options(theme, active))
