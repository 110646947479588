import React from 'react'
import PropTypes from 'prop-types'
import {
  Background,
  RichTextContainer,
  BorderColLeft,
  BorderColBottom,
} from './styles'
import { useComponentTheme, useMobile } from '../../hooks'
import { renderRichText } from './richText.js'
import { verticalObj } from '../../utils/index'

const findHrIndex = arr => arr.findIndex(item => item.nodeType === 'hr')

const splitDocumentByThematicBreak = copy => {
  if (!copy && !copy) return [false, false, -1]
  const hrIndex = findHrIndex(copy.content)
  if (hrIndex === -1) return [true, false, -1]
  else return [true, true, hrIndex]
}

const RichTextCopy = ({
  copy,
  theme,
  mobile,
  minHeight = '20vh',
  textAlign,
  alignItems,
}) => {
  const [hasCopy, hasHr, hrIndex] = splitDocumentByThematicBreak(copy)
  if (!hasCopy) {
    return <div></div>
  } else if (hasCopy && !hasHr) {
    return renderRichText(copy, theme)
  } else {
    return (
      <RichTextContainer
        margin="0 auto"
        p={[4, 4, 5, 5]}
        display="flex"
        justifyContent="center"
        flexDirection="row"
        flexWrap={mobile ? 'wrap' : 'nowrap'}
        alignItems={alignItems || 'center'}
        width={[1, 1, 1, 5 / 6, 5 / 6]}
      >
        <BorderColBottom
          display="flex"
          textAlign={mobile ? 'center' : textAlign}
          marginBottom={mobile && '20px'}
          marginRight={!mobile && '40px'}
          width={[4 / 4, 3 / 3, 1 / 5, 1 / 5]}
          mobile={mobile}
          margin={mobile && '0px 20%'}
          paddingBottom={mobile && '20px'}
          justifyContent={mobile ? 'center' : 'flex-end'}
        >
          {renderRichText(
            {
              data: {},
              content: copy.content.slice(0, hrIndex),
              nodeType: 'document',
            },
            theme,
            minHeight
          )}
        </BorderColBottom>
        <BorderColLeft
          width={[4 / 4, 3 / 3, 2 / 3, 2 / 3]}
          textAlign={mobile ? 'center' : textAlign}
          mobile={mobile}
          p={mobile ? '30px 10px 0px' : '0px 40px'}
        >
          {renderRichText(
            {
              data: {},
              content: copy.content.slice(hrIndex + 1, copy.content.length),
              nodeType: 'document',
            },
            theme,
            minHeight
          )}
        </BorderColLeft>
      </RichTextContainer>
    )
  }
}

const Divider = ({
  theme: themeBool = true,
  overlayCopy,
  bladeHeight = '25',
  textAlignmentVertical,
  textAlignmentHorizontal,
}) => {
  const { theme } = useComponentTheme(themeBool)
  const [isMobile] = useMobile()

  return (
    <Background
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      zIndex={0}
      minHeight={`${bladeHeight}vh`}
    >
      <RichTextCopy
        copy={overlayCopy}
        theme={theme}
        mobile={isMobile}
        alignItems={verticalObj[textAlignmentVertical] || 'center'}
        textAlign={textAlignmentHorizontal || 'center'}
      />
    </Background>
  )
}

Divider.propTypes = {
  name: PropTypes.string,
  theme: PropTypes.bool,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object),
    nodeType: PropTypes.string,
  }),
  media: PropTypes.number,
}

export default Divider
