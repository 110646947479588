import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Background,
  RichTextOverlay,
  Cell,
  BackgroundDiv,
  StyledImage,
} from './styles.js'
import ScrollIcon from './ScrollIcon.js'
import { useComponentTheme } from '../../hooks'
import { verticalObj } from '../../utils/index'
import { ThemeContext } from 'styled-components'
import { richText } from './../RichText/index'

const Image = ({
  theme: themeBool = true,
  media,
  overlayCopy,
  displayScrollIcon = true,
  minHeight = '80vh',
  overlayPadding = [3, 3, 3, 4, 4, 4],
  alt = 'image',
  darkenImage = true,
  shortenDescription,
  bladeHeight,
  textAlignmentVertical,
  textAlignmentHorizontal,
}) => {
  const us = 'en-US'
  let { theme } = useComponentTheme(themeBool)
  const themePackage = useContext(ThemeContext)
  const mediaBackgroundImage =
    media && media[0] && media[0].srcContentful && media[0].srcContentful.fluid

  const mediaInlineImage =
    media &&
    media.srcContentful &&
    media.srcContentful[us] &&
    media.srcContentful[us].fields &&
    media.srcContentful[us].fields.file &&
    media.srcContentful[us].fields.file[us] &&
    media.srcContentful[us].fields.file[us].url

  const contentType =
    (media && media[0] && media[0].__typename) ||
    (media &&
      media.srcContentful &&
      media.srcContentful[us] &&
      media.srcContentful[us].fields &&
      media.srcContentful[us].fields.file &&
      media.srcContentful[us].fields.file[us] &&
      media.srcContentful[us].fields.file[us].contentType)

  const fluid = mediaInlineImage && { src: mediaInlineImage }
  theme = mediaBackgroundImage && 'dark'
  darkenImage = themePackage.richtext !== 'simple' && darkenImage
  displayScrollIcon = themePackage.richtext !== 'simple' && displayScrollIcon

  if (!media || media.length === 0 || contentType === 'ContentfulMediaVideo')
    return <div />

  return (
    <>
      {mediaBackgroundImage ? (
        <Background
          Tag="div"
          fluid={mediaBackgroundImage}
          backgroundColor={`#040e18`}
          color={`${theme}.text`}
          minHeight={bladeHeight ? `${bladeHeight}vh` : minHeight}
          height="100%"
          display="flex"
          zIndex={1}
          alt={alt}
        >
          <RichTextOverlay
            bg={darkenImage && 'rgba(0,0,0,0.50)'}
            width={1}
            display="flex"
            justifyContent="center"
            alignItems={verticalObj[textAlignmentVertical] || 'center'}
            textAlign={textAlignmentHorizontal || 'center'}
            p={overlayPadding}
          >
            <Cell maxWidth={['maxWidth.text.medium', 'maxWidth.text.large']}>
              <Cell width={1}>
                {overlayCopy &&
                  richText(
                    themePackage,
                    overlayCopy,
                    theme,
                    shortenDescription
                  )}
              </Cell>
            </Cell>
          </RichTextOverlay>
          {displayScrollIcon && (
            <ScrollIcon bg={`${themePackage.colors.heroAlt}`} />
          )}
        </Background>
      ) : (
        <BackgroundDiv
          Tag="div"
          color={`${theme}.text`}
          height="100%"
          minHeight={(bladeHeight && `${bladeHeight}vh`) || '50vh'}
          display="flex"
        >
          <StyledImage width="100%" fluid={fluid && fluid} alt="media-image" />
        </BackgroundDiv>
      )}
    </>
  )
}

Image.propTypes = {
  name: PropTypes.string,
  theme: PropTypes.bool,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object),
    nodeType: PropTypes.string,
  }),
  media: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        title: PropTypes.string,
        src: PropTypes.string,
        srcContentful: PropTypes.shape({
          id: PropTypes.string,
          fluid: PropTypes.object,
        }),
        thumbnail: PropTypes.shape({
          id: PropTypes.string,
          fluid: PropTypes.object,
        }),
      })
    ),
    PropTypes.shape({
      __typename: PropTypes.string,
      title: PropTypes.string,
      src: PropTypes.string,
      srcContentful: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
      thumbnail: PropTypes.shape({
        id: PropTypes.string,
        fluid: PropTypes.object,
      }),
    }),
  ]),
}

export default Image
