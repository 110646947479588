/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, P, B } from './styles.js'
import { OpaqueButton, TransparentButton } from '../Button'
import Modal from './../Modal'
import Video from '../LegacyVideo'
import Image from '../Image/index'
import Icon from '../Icon'
import usePreviewQuery, { GET_ENTRY } from '../../hooks/usePreviewQuery'

const EntryHyperLinkComponent = ({
  targetEntry,
  children,
  theme,
  isModal,
  isIcon,
}) => {
  if (isIcon) {
    let { title, icon } = targetEntry.fields
    return <Icon icon={icon} title={title} />
  }
  if (isModal) {
    return (
      <Modal type="transparent" media={targetEntry}>
        {children}
      </Modal>
    )
  }
  return (
    <TransparentButton
      theme={theme}
      to={
        targetEntry.fields &&
        targetEntry.fields.slug &&
        targetEntry.fields.slug['en-US']
      }
    >
      {children}
    </TransparentButton>
  )
}

const EntryHyperlinkApolloContentfulRest = ({
  targetEntryId,
  children,
  theme,
}) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: targetEntryId,
    skip: !targetEntryId,
  })

  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    return (
      <EntryHyperLinkComponent
        targetEntry={data.entry}
        isModal={data.entry?.fields?.mediaReference}
        isIcon={data.entry?.fields?.icon}
        children={children}
        theme={theme}
      />
    )
  }
}
export const renderComponentFromContentType = (node, children, minHeight) => {
  const us = 'en-US'
  const base = node && node.data && node.data.target
  const type =
    base &&
    base.sys &&
    base.sys.contentType &&
    base.sys.contentType.sys &&
    base.sys.contentType.sys.id
  const fields = base && base.fields
  const id = base && base.sys && base.sys.contentful_id
  switch (type) {
    case 'mediaVideo':
      return (
        <div style={{ marginTop: '40px' }}>
          <Video media={fields} id={id} overlay={false} />
        </div>
      )
    case 'mediaImage':
      return <Image media={fields} minHeight={minHeight} />
    case 'modal':
      return <Modal media={fields} />
    case 'icon':
      let { title, icon } = node.data.target.fields
      title = title && title[us]
      icon = icon && icon[us]
      return <Icon icon={icon} title={title} />
    default:
      if (node.data?.target?.sys?.id) {
        return (
          <EntryHyperlinkApolloContentfulRest
            targetEntryId={node.data.target.sys.id}
            children={children}
          />
        )
      } else {
        return <div />
      }
  }
}

const EmbeddedComponent = ({ children, node, minHeight }) =>
  renderComponentFromContentType(node, children, minHeight)

const options = (theme, minHeight) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <P
          color={`${theme}.text`}
          fontSize={[4, 8, 8, 8, 8, 8, 8]}
          fontFamily="headings"
          fontWeight={200}
          height="100%"
        >
          {children}
        </P>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1
        color={`${theme}.text`}
        fontSize={[2, 1, 1, 1]}
        w={[1]}
        fontFamily="body"
        fontWeight={400}
        lineHeight={[0]}
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[3, 2, 2, 2, 2, 2, 2]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3 color={`${theme}.text`} fontSize={[4, 3, 3, 3, 3, 3, 3]} w={[1]}>
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4
        color={`${theme}.text`}
        fontWeight={200}
        lineHeight="31px"
        fontSize={[6, 4, 4, 4, 4, 4, 4]}
        w={[1]}
      >
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <>{children}</>,
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <OpaqueButton theme={theme} href={node.data.uri}>
        {children}
      </OpaqueButton>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <OpaqueButton
        theme={theme}
        to={node.data.target ? node.data.target.fields.slug['en-US'] : ''}
        href={node.data.uri}
      >
        {children}
      </OpaqueButton>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const findModal =
        node &&
        node.data &&
        node.data.target &&
        node.data.target.fields &&
        node.data.target.fields.mediaReference
      const modalContent = node && node.data && node.data.target
      if (findModal) {
        return (
          <Modal type="opaque" media={modalContent}>
            {children}
          </Modal>
        )
      }
      return (
        <OpaqueButton
          theme={theme}
          to={node.data.target ? node.data.target.fields.slug['en-US'] : ''}
          href={node.data.uri}
        >
          {children}
        </OpaqueButton>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      return (
        <EmbeddedComponent node={node} minHeight={minHeight}>
          {children}
        </EmbeddedComponent>
      )
    },
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B color={`${theme}.textAlt`} fontWeight={400}>
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => <>{text}</>,
    [MARKS.UNDERLINE]: text => <>{text}</>,
  },
})

export const renderRichText = (document, theme, minHeight) =>
  documentToReactComponents(document, options(theme, minHeight))
