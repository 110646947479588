import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GridItemContainer } from './styles'
import GridItemThumbnail from './GridItemThumbnail'
import GridItemDescription from './GridItemDescription'

const GridItem = ({
  isMobile,
  id,
  title,
  shortDescription,
  logo,
  website,
  email,
  longDescription,
  media,
  reel,
  i,
  theme,
  toggle,
  active,
  setActive,
}) => {
  const [hash, setHash] = useState(null)

  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hash !== hash
  ) {
    setHash(window.location.hash)
  }

  useEffect(() => {
    if (hash && hash.split('#')[1] === id) {
      setActive(i)
    }
  }, [i, id, setActive, hash])

  return (
    <GridItemContainer active={i === active} key={title}>
      <GridItemThumbnail
        id={id}
        i={i}
        title={title}
        toggle={toggle}
        active={i === active}
        shortDescription={shortDescription}
        logo={logo}
        isMobile={isMobile}
        bgImageUrl={
          media &&
          media[0] &&
          media[0].thumbnail &&
          `url(https:${media[0].thumbnail.fluid.src})`
        }
      />
      <GridItemDescription
        title={title}
        isMobile={isMobile}
        website={website}
        email={email}
        longDescription={longDescription}
        active={i === active}
        setActive={setActive}
        toggle={toggle}
        theme={theme}
        i={i}
        reel={reel}
        bgImageUrl={
          media &&
          media[0] &&
          media[0].srcContentful &&
          `url(https:${media[0].srcContentful.fluid.src})`
        }
      />
    </GridItemContainer>
  )
}

GridItem.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  logo: PropTypes.object,
  website: PropTypes.string,
  email: PropTypes.string,
  longDescription: PropTypes.object,
  media: PropTypes.arrayOf(PropTypes.object),
  reel: PropTypes.object,
  i: PropTypes.number,
  theme: PropTypes.string,
  toggle: PropTypes.func,
  active: PropTypes.number,
  setActive: PropTypes.func,
}

export default GridItem
