import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from './card'
import {
  StyledCards,
  StyledContainer,
  StyledSectionName,
  StyledWidthContainer,
  StyledExtend,
  StyledLink,
  StyledArrow,
  StyledArrowLeft,
  StyledExtendText,
} from './styles'
import { useMobile, useComponentTheme } from '../../hooks'

const Cards = ({
  news = { news: [], parentSlug: null },
  limitDisplay = false,
  showBtn = false,
  initialDisplayI = 1,
  title = '',
  theme: themeBool = true,
  displayAllCards = false,
  extend = false,
  ...rest
}) => {
  const { theme } = useComponentTheme(themeBool)
  const [isMobile] = useMobile()
  const newsLength = news?.news?.length
  const [displayI, setDisplayI] = useState(initialDisplayI)
  const remaining = newsLength - displayI

  const handleExtendClick = () => {
    if (remaining >= 3) {
      setDisplayI(displayI => displayI + 3)
    } else {
      setDisplayI(displayI => displayI + remaining)
    }
  }

  return (
    <StyledContainer
      data-testid="cards"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={4}
      bg={`${theme}.bg`}
    >
      <StyledWidthContainer
        width="100%"
        maxWidth="maxWidth.xlarge"
        px={3}
        minWidth={isMobile ? '0px' : '800px'}
      >
        <StyledContainer
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          bg={`${theme}.bg`}
        >
          <StyledSectionName
            color={`${theme}.textAlt`}
            mt={0}
            mb={4}
            fontWeight={400}
            fontSize={[0, 3]}
            fontFamily="body"
            marginBottom="8px"
          >
            {title}
          </StyledSectionName>
        </StyledContainer>
        <StyledCards display="flex" flexDirection="column" bg={`${theme}.bg`}>
          {displayAllCards
            ? news.news.map((card, index) => (
                <Card
                  key={card.id}
                  {...card}
                  {...rest}
                  parentSlug={news.parentSlug}
                  theme={themeBool}
                  featured={index === 0}
                />
              ))
            : news.news
                .slice(0, displayI)
                .map((card, index) => (
                  <Card
                    key={card.id}
                    {...card}
                    {...rest}
                    parentSlug={news.parentSlug}
                    theme={themeBool}
                    featured={index === 0}
                  />
                ))}
        </StyledCards>
        <StyledContainer
          py={3}
          display="flex"
          alignItems="flex-end"
          flexDirection="row"
          justifyContent="flex-end"
          color={`${theme}.textAlt`}
          width="100%"
        >
          {showBtn && !displayAllCards && (
            <StyledExtend
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom="12px"
            >
              <StyledLink
                color={`${theme}.textAlt`}
                fontSize="23px"
                fontWeight="600"
                fontFamily="headings"
                to="/news-and-updates"
              >
                {'View more'}
              </StyledLink>
              <StyledArrowLeft ml={2} mr={1} />
            </StyledExtend>
          )}

          {extend &&
            !displayAllCards &&
            remaining !== 0 &&
            (!limitDisplay || displayI !== 4) && (
              <StyledExtend
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                color={`${theme}.textAlt`}
                onClick={() => handleExtendClick()}
              >
                <StyledExtendText data-testid="view-n-btn">
                  View {newsLength && newsLength - 1} more
                </StyledExtendText>

                <StyledArrow ml={2} mb={1} />
              </StyledExtend>
            )}
        </StyledContainer>
      </StyledWidthContainer>
    </StyledContainer>
  )
}

Cards.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  intro: PropTypes.object,
  date: PropTypes.string,
  limitDisplay: PropTypes.bool,
  showBtn: PropTypes.bool,
  showViewMore: PropTypes.bool,
  initialDisplayI: PropTypes.number,
  theme: PropTypes.bool,
  news: PropTypes.shape({
    news: PropTypes.array,
    parentSlug: PropTypes.string,
  }),
}

export default Cards
