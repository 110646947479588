import React from 'react'
import { StyledImage } from './styles'
import { Video } from '../Video/VideoComponent'
import {
  EmbeddedEntryButton,
  EmbeddedEntryIcon,
  EmbeddedEntryModal,
} from './components'

const EmbeddedEntry = ({
  contentType,
  theme,
  href,
  to,
  icon,
  color,
  children,
  alt,
  image,
  video,
  modalContentType,
  modalContent,
  iconSrc,
  inlineVideo,
}) => {
  switch (contentType) {
    case 'icon':
      return (
        <EmbeddedEntryIcon
          icon={icon}
          customIcon={{ file: { url: iconSrc } }}
          title={alt}
        />
      )
    case 'modal':
      return (
        <EmbeddedEntryModal
          theme={theme}
          contentType={modalContentType}
          content={modalContent}
        >
          {children}
        </EmbeddedEntryModal>
      )
    case 'mediaVideo':
      return (
        <Video
          src={video.src}
          autoPlay={video.autoPlay}
          mute={false}
          title={video.title}
          thumbnail={video.thumbnail}
          width="100%"
          height="auto"
          background={false}
          controlsOnly
          inlineVideo={inlineVideo}
        />
      )
    case 'mediaImage':
      return (
        <StyledImage
          src={image.src}
          height="auto"
          maxWidth="100%"
          alt={'image'}
        />
      )
    case 'button':
    case 'hyperlink':
      return (
        <EmbeddedEntryButton
          theme={theme}
          forceOpaqueButton={color}
          to={to}
          href={href}
        >
          {children}
        </EmbeddedEntryButton>
      )
    default:
      return null
  }
}

export default EmbeddedEntry
