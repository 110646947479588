import React from 'react'
import PropTypes from 'prop-types'
import { ViewGridItemsCta, ViewGridItemsLink } from './styles'
import { A } from '../../Button'

const GridTitle = ({ title, theme }) => {
  return (
    <ViewGridItemsCta
      bg={`${theme}.bg`} // black
      color={`${theme}.text`}
      height="auto"
      margin="0 auto"
      display="flex"
      justifyContent="center"
    >
      <A to="/#grid-toggle" title={title}>
        <ViewGridItemsLink fontWeight={600} fontFamily="headings">
          {title}
        </ViewGridItemsLink>
      </A>
    </ViewGridItemsCta>
  )
}
GridTitle.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
}

export default GridTitle
