// Reference to how theme object interacts with styled system
// https://styled-system.com/api
// https://styled-system.com/guides/why-powers-of-two/

// Default Breakpoints
export const breakpoints = ['40em', '52em', '64em']
// @media screen and (min-width: 40em)
// @media screen and (min-width: 52em)
// @media screen and (min-width: 64em)

export const fontSizes = [18, 64, 48, 32, 24, 20, 16, 14]
// export const fontSizes = [12, 14, 16, 20, 24, 28, 32, 48, 64, 72] // default fontSizes
export const lineHeights = [1.2, 3, 1.6, 1.6, 1.2, 1.2, 1.2, 1.2]
export const fontWeights = [300, 400, 400, 300]

/**
 * p
 * h1
 * h2
 * h3
 * h4
 * h5
 * h6
 * small
 *
 */
// default space for margin and padding
export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const cerulean = '#0072ce'
const softBlue = '#5bc2e7'
const charcoal = '#373a36'
const white = '#ffffff'
const buttonAccessible = '#009ccb'
const mediumGrey = '#7e7f74'
const oceanBlue = '#0067ba'
const paleGrey = '#f4f5f8'
const lightPeriwinkle = '#d9d9dd'
const brownishGrey = '#6d6d6d'
const steel = '#768692'
const putty = '#afa9a0'
const lightGrey = '#d9d9d6'
const brightBlueBorder = '#0091FF'
const teal = '#5BC2E7'

export const theme = {
  colors: {
    hero: cerulean,
    heroAlt: oceanBlue,
    white,
    paleGrey,
    brownishGrey,
    dark: {
      // false
      bg: oceanBlue,
      bgAlt: cerulean,
      text: white,
      textAlt: cerulean,
      border: brightBlueBorder,
      borderAlt: teal,
    },
    light: {
      // true, has white background
      bg: white,
      bgAlt: paleGrey,
      text: brownishGrey,
      textAlt: cerulean,
      border: brightBlueBorder,
      borderAlt: teal,
    },
    blues: [cerulean, oceanBlue],
  },
  breakpoints,
  fontSizes,
  lineHeights,
  shadows: {
    text: '0px 2px 8px #0000004f',
    bg: '2px 2px 2px #000',
  },
  fonts: {
    open:
      "'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    cata:
      "'Catamaran', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    headings: "'FS Joey',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
  },
  space,
  transitionDuration: '0.25s',
}

export const sizes = {
  xsmall: { min: 0, max: 574 },
  small: { min: 575, max: 764 },
  medium: { min: 765, max: 991 },
  large: { min: 992, max: 1180 },
  xlarge: { min: 1181, max: Infinity },
}
