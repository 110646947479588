/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, P, B, U, I } from '../BladeText/styles'
import { TransparentButton, SolidColorButton } from '../Button'
import Icon from '../Icon'
import Modal from '../Modal'
import Video from '../Video'
import Image from '../Image/index'
import usePreviewQuery, { GET_ENTRY } from '../../hooks/usePreviewQuery'
import { renderBgTypeAndBg } from '../../utils'

export const renderComponentFromContentType = (node, children, theme) => {
  const base = node && node.data && node.data.target
  const type =
    (base &&
      base.sys &&
      base.sys.contentType &&
      base.sys.contentType.sys &&
      base.sys.contentType.sys.id) ||
    node.nodeType
  const fields = base && base.fields
  const id = base && base.sys && base.sys.contentful_id

  const { assetId, src, thumbnail: thumb, alt, autoPlay } = renderBgTypeAndBg({
    background: fields,
    theme,
  })

  switch (type) {
    case 'mediaVideo':
      return (
        <div style={{ marginTop: '40px' }}>
          <Video src={src} media={fields} id={id} overlay={false} />
        </div>
      )
    case 'mediaImage':
      return <Image media={fields} theme={theme} />
    case 'icon':
      let { title, icon } = node.data.target.fields
      title = title['en-US']
      icon = icon['en-US']
      return <Icon icon={icon} title={title} />
    default:
      return null
  }
}

const EmbeddedComponent = ({ children, node, theme }) =>
  renderComponentFromContentType(node, children, theme)

const options = (theme, shortenDescription) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (shortenDescription) {
        children.forEach(c => {
          if (shortenDescription && c[1] !== '') children = shortenDescription
        })
      }
      return (
        <P
          color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
          fontSize={[0, 6]}
          fontFamily="body"
          lineHeight="30px"
          fontWeight={200}
          letterSpacing="0.23px"
          textShadow={theme === 'dark' && 'text'}
        >
          {children}
        </P>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1
        color={`${theme}.text`}
        fontSize={[2, 1]}
        w={[1]}
        fontFamily="headings"
        fontWeight={400}
        lineHeight={[0]}
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        marginTop="0px"
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[3, 2]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[4, 3]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[5, 4]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {' '}
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <H5
        color={`${theme}.textAlt`}
        fontSize={[0, 6]}
        fontFamily="body"
        lineHeight={1.4}
        fontWeight={400}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
        mt="1.5em"
      >
        {children}
      </H5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <H6
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[5]}
        fontFamily="body"
        lineHeight={1.4}
        fontWeight={400}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <>{children}</>,
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <SolidColorButton theme={theme} href={node.data.uri}>
        {children}
      </SolidColorButton>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <TransparentButton
        theme={theme}
        to={
          node.data.target.fields.slug && node.data.target.fields.slug['en-US']
        }
        href={node.data.uri}
      >
        {children}
      </TransparentButton>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const link = node?.data?.target?.fields?.slug?.['en-US']
      return (
        <SolidColorButton theme={theme} to={link}>
          {children}
        </SolidColorButton>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedComponent node={node} theme={theme}>
        {children}
      </EmbeddedComponent>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B color={`${theme}.text`} fontWeight={600}>
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => (
      <I color={`${theme}.textAlt`} fontWeight={600}>
        {text}
      </I>
    ),
    [MARKS.UNDERLINE]: text => (
      <U color={`${theme}.textAlt`} fontWeight={600}>
        {text}
      </U>
    ),
  },
})

export const simpleRenderRichText = (document, theme, shortenDescription) =>
  documentToReactComponents(document, options(theme, shortenDescription))
