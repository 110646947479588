import React from 'react'
import PropTypes from 'prop-types'
import Video from '../../Video'
import { useMobile } from '../../../hooks'
import {
  Container,
  Background,
  RichTextOverlay,
  OverlayContainer,
  StyledColorOverlay,
  StyledColorOverlayContainer,
} from '../styles'
import { DownButton } from '../../Button'

const Wrapper = ({
  autoPlay,
  assetAltTitle,
  bg,
  bgType,
  children,
  displayScrollIcon = false,
  hasRichText = false,
  height,
  minHeight = '50vh',
  theme,
  thumbnail,
  backgroundColor,
  backgroundOpacity = 0,
}) => {
  const [isMobile] = useMobile()
  if (height === 100) {
    height = 94
  }
  backgroundOpacity = backgroundOpacity * 0.01
  switch (bgType) {
    case 'image':
      return (
        <StyledColorOverlayContainer position="relative">
          <Background
            data-testid="image-bltext"
            Tag="section"
            fluid={bg}
            backgroundColor={`#040e18`}
            color={`${theme}.text`}
            margin="0 auto"
            minHeight={(height && `${height}vh`) || minHeight}
            height="auto"
            display="flex"
            alignItems="center"
            alt={bg.title || 'bladetext-img'}
          >
            {backgroundColor && (
              <StyledColorOverlay
                position="absolute"
                top="0"
                bottom="0"
                left="0"
                right="0"
                bg={backgroundColor}
                opacity={backgroundOpacity}
              />
            )}

            <RichTextOverlay
              width="100%"
              height="fit-content"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              top="0"
              bottom="0"
              left="0"
              right="0"
              zIndex={1}
            >
              {children}
            </RichTextOverlay>
            {displayScrollIcon && <DownButton bg={`${theme}.heroAlt`} />}
          </Background>
        </StyledColorOverlayContainer>
      )
    case 'video-external':
      return (
        <StyledColorOverlayContainer position="relative">
          <Video
            autoPlay={autoPlay}
            src={bg}
            height={height}
            minHeight={height || minHeight}
            overlay={hasRichText && !autoPlay}
            hasRichText={hasRichText}
            theme={theme}
            thumbnail={thumbnail}
            title={assetAltTitle}
          >
            {children && (
              <OverlayContainer
                width={1}
                display="flex"
                justifyContent="center"
                zIndex={1}
              >
                {children}
              </OverlayContainer>
            )}
            {backgroundColor && (
              <StyledColorOverlay
                position="absolute"
                top="0"
                bottom="0"
                left="0"
                right="0"
                bg={backgroundColor}
                opacity={backgroundOpacity}
              />
            )}
          </Video>
        </StyledColorOverlayContainer>
      )
    case 'video-internal':
      return (
        <StyledColorOverlayContainer position="relative">
          <Video
            autoPlay={autoPlay}
            height={height}
            src={bg}
            minHeight={height || minHeight}
            hasRichText={hasRichText}
            overlay={hasRichText && !autoPlay}
            thumbnail={thumbnail}
            title={assetAltTitle}
            theme={theme}
          >
            {children && (
              <OverlayContainer
                width={1}
                display="flex"
                justifyContent="center"
                zIndex={1}
              >
                {children}
              </OverlayContainer>
            )}
          </Video>
          {backgroundColor && (
            <StyledColorOverlay
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              bg={backgroundColor}
              opacity={backgroundOpacity}
            />
          )}
        </StyledColorOverlayContainer>
      )
    default:
      return (
        <StyledColorOverlayContainer position="relative">
          <StyledColorOverlay
            bg={backgroundColor ? backgroundColor : bg}
            opacity={backgroundOpacity ? backgroundOpacity : 1}
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            zIndex={-1}
          />
          <Container
            data-testid="color-bltext"
            color={`${theme}.text`}
            height="auto"
            minHeight={`${height}vh`}
            margin="0 auto"
            py={[5, 1, 1, 1]}
            width="100%"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
          >
            {children}
          </Container>
        </StyledColorOverlayContainer>
      )
  }
}

export default Wrapper

Wrapper.propTypes = {
  assetAltTitle: PropTypes.string,
  autoPlay: PropTypes.bool,
  bg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgType: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  displayScrollIcon: PropTypes.bool,
  height: PropTypes.number,
  theme: PropTypes.string,
  thumbnail: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number,
}
