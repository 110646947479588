import styled from 'styled-components'
// import BackgroundImage from 'gatsby-background-image'
import playButton from '../../assets/anetwork-playbutton.svg'
import Img from 'gatsby-image'
import {
  position,
  space,
  color,
  layout,
  background,
  flexbox,
  typography,
} from 'styled-system'

export const StyledPlayButton = styled.img`
  z-index: 3;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0.6;
  ${layout}
  ${position}
  ${color}
  ${space}
`

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${background}
  position: relative;
  overflow: hidden;
  cursor: pointer;
  // max-height: 100vh;
  & iframe .player .vp-title {
    opacity: 0 !important;
  }
  
  &: hover ${StyledPlayButton} {
    opacity: .95;
  }
  > iframe {
    ${layout}
    width: 100%;
    height: 100%;
    border: 0;
  }
  > video {
    pointer-events: ${({ isPlaying }) => (isPlaying ? '' : 'none !important')};
    object-fit: cover;
    ${layout}
    & :focus {outline:0 !important;}
    // video::-webkit-media-controls {
    //   display: none;
    // }
    
    /* Could Use thise as well for Individual Controls */
    & ::-webkit-media-controls-play-button {
    }
    
    video::-webkit-media-controls-volume-slider {
    }
    
    video::-webkit-media-controls-mute-button {
    }
    
    video::-webkit-media-controls-timeline {
    }
    
    video::-webkit-media-controls-current-time-display {
    }
  }
`

export const PlayButtonWrapper = styled.div`
  ${flexbox}
  ${layout}
  position: absolute;
  top: 50%;
  margin: 0 auto;
  transform: translate(0, -50%);
  z-index: 3;
`

export const Overlay = styled.div`
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  z-index: ${({ visible }) => (visible ? 2 : -1)};
  background: #000;
  opacity: 0.5;
  position: absolute;
  height: 100%;
  max-width: 100%;
  top: 0;
  cursor: pointer;
  width: 100%;
  left: 0;
`

export const Button = styled.button`
  outline: 0;
  -webkit-apperance: button;
  cursor: pointer;
  align-self: center;
  border-radius: 0;
  background: 0 0;
  line-height: 1;
  padding: 0;
  border: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  & :focus {
    outline: 0 !important;
  }
  & :before {
    display: block;
    content: '';
    background: url(${playButton}) no-repeat center center;
    background-size: contain;
    height: 25vw;
    width: 25vw;
    ${({ maxWidth }) => `max-width: ${maxWidth}`};
    max-height: 120px;
    @media print, screen and (min-width: 40em) {
      height: 10vw;
      width: 10vw;
    }
    @media print, screen and (min-width: 64em) {
      max-height: 120px;
      ${({ maxWidth }) => `max-width: ${maxWidth}`};
    }
  }
  cursor: pointer;
  ${layout}
  ${space}
`

export const StyledIcon = styled.img`
  ${layout}
`

export const OverlayContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  position: absolute;
  top: 50%;
  margin: 0 auto;
  transform: translate(0, -50%);
  z-index: 1;
`

export const VideoWrapper = styled.div`
  ${layout}
  & iframe .player .vp-title {
    opacity: 0 !important;
  }
`

export const ExternalVideoWrapper = styled.div`
  ${layout}
  & iframe .player .vp-title {
    opacity: 0 !important;
  }
`

export const StyledThumbnail = styled(Img)`
  z-index: 2;
  ${layout}
  ${position}
  ${color}
  ${space}
`
