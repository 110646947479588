import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import { StyledSelect, StyledContainer } from '../styles'
import _ from 'lodash'
import searchIcon from '../../../assets/search.svg'

const Search = ({
  options = [],
  handleSelect = () => {},
  groupOptions = false,
  throttleRate = 750,
  placeholder = 'Search',
  onTouchStart = () => {},
}) => {
  const styles = {
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? 'grey' : base.color,
    }),
  }

  const filterData = inputValue => {
    const flatOptions = groupOptions
      ? _.flatten(options.map(({ options }) => options))
      : options
    const filteredOptions = flatOptions.filter(({ label }) =>
      label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return filteredOptions
  }

  const loadOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterData(inputValue))
      }, throttleRate)
    })

  // Custom Components - https://react-select.com/components
  const IndicatorSeparator = () => null
  const DropdownIndicator = ({ innerProps }) => {
    return (
      <div {...innerProps}>
        <img
          src={searchIcon}
          alt="search-icon"
          height="18px"
          width="auto"
          style={{ marginRight: '10px' }}
        />
      </div>
    )
  }

  return (
    <StyledContainer onTouchStart={onTouchStart} onClick={onTouchStart}>
      <StyledSelect
        placeholder={placeholder}
        components={{ IndicatorSeparator, DropdownIndicator }}
        cacheOptions
        defaultOptions={options}
        loadOptions={loadOptions}
        onChange={handleSelect}
        classNamePrefix="react-select"
        styles={styles}
      />
    </StyledContainer>
  )
}

Search.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
          })
        ),
      }),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ])
  ),
  handleSelect: PropTypes.func,
  groupOptions: PropTypes.bool,
  throttleRate: PropTypes.number,
  placeholder: PropTypes.string,
}

export default Search
