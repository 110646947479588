import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Overlay } from './styles'
import PlayButton from './PlayButton'
import OverlayText from './OverlayText'
import ContentfulVideo from './ContentfulVideo'
import ExternalVideo from './ExternalVideo'

const useVideoState = (refId, externalVid) => {
  const [playCount, setPlayCount] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const ctrlVideo = useRef(null)

  useEffect(() => {
    if (document !== undefined) {
      ctrlVideo.current = document.getElementById(`${refId}`)
    }
  }, [refId])

  const play = () => {
    if (!isPlaying && !externalVid) {
      ctrlVideo.current.play()
      setPlayCount(playCount + 1)
      setIsPlaying(true)
    }
  }

  const pause = () => {
    if (isPlaying) {
      ctrlVideo.current.pause()
      setIsPlaying(false)
    }
  }
  const handleSetPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  return [ctrlVideo, isPlaying, play, playCount, pause, handleSetPlaying]
}

const VideoComponent = ({
  src,
  title,
  minHeight = null,
  referenceId,
  externalVideoSrcUrl,
  thumbnail,
  height,
  overlayCopy,
  overlay = false,
  theme,
}) => {
  const [ctrlVideo, isPlaying, play, playCount] = useVideoState(
    referenceId,
    externalVideoSrcUrl
  )

  return (
    <Container
      width="100%"
      height="100%"
      minHeight={`${minHeight}vh`}
      bg={`${theme}.bg`}
      onClick={playCount === 0 ? play : () => {}}
      ref={ctrlVideo}
      isPlaying={isPlaying}
    >
      {!isPlaying && !externalVideoSrcUrl && (
        <PlayButton aria-label="Play Video" />
      )}
      <Overlay visible={overlay && !isPlaying} />
      {externalVideoSrcUrl ? (
        <ExternalVideo
          referenceId={referenceId}
          src={src}
          minHeight={minHeight}
          thumbnail={thumbnail}
          height={height}
          title={title}
        />
      ) : (
        <ContentfulVideo
          thumbnail={thumbnail}
          height={height}
          referenceId={referenceId}
          src={src}
          showControls={playCount >= 1}
          title={title}
          minHeight={`${minHeight}vh`}
        />
      )}
      {overlayCopy && <OverlayText overlayCopy={overlayCopy} />}
    </Container>
  )
}

VideoComponent.propTypes = {
  title: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
  minHeight: PropTypes.string.isRequired,
  externalVideoSrcUrl: PropTypes.string,
  internalVideoSrcUrl: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  overlayCopy: PropTypes.exact({
    json: PropTypes.exact({
      data: PropTypes.object,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
      nodeType: PropTypes.string.isRequired,
    }),
  }),
  height: PropTypes.string.isRequired,
}

export default VideoComponent
