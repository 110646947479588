import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  StyledCategoryListContainer,
  StyledCategoryHeaderContainer,
  StyledText,
  StyledList,
  StyledListItem,
  StyledPhoneNumber,
  StyledContainer,
  StyledSvg,
  StyledSvgContainer,
  StyledTextWithCursor,
} from '../styles'
import Icon from '../../Icon'
import BackButton from './BackButton'
import closureIcon from '../../../assets/closure.svg'
import phoneIcon from '../../../assets/phone.svg'
import useMeasure from '../../../hooks/useMeasure'
import useVerticalSnapToPosition from '../useVerticalSnapToPosition'
import { getDirectionsGeoJson } from '../../../utils'

const applyMarks = (string, mark, matcher) =>
  string
    .replace(matcher, `<${mark}>`)
    .split(`<${mark}>`)
    .reduce((a, b, i) =>
      i % 2 === 0 ? a + `</${mark}>` + b : a + `<${mark}>` + b
    )

const renderMarkdown = text => {
  if (!text) return null

  const mdText = text
    .split('\n')
    .map(words => applyMarks(words, 'strong', /(__|\*\*)/g))
    .map(words => applyMarks(words, 'em', /(_|\*)/g))
    .join('<br />')

  return (
    <StyledContainer
      fontSize={6}
      fontWeight={300}
      lineHeight="24px"
      color="black"
      dangerouslySetInnerHTML={{ __html: mdText }}
    />
  )
}

const DetailView = ({
  goToPrevView = () => {},
  isExpanded,
  snapPositions,
  sidebarRef,
  prevTitle = 'Main Menu',
  properties: {
    icon,
    title,
    address,
    description,
    phoneNumber,
    tags = [],
    nearbyFacilities = [],
    nearbyParkingAndTransit = [],
    closure,
  },
  geometry,
}) => {
  const [ref, height] = useMeasure()
  const [detailedViewRef] = useVerticalSnapToPosition(
    sidebarRef,
    isExpanded,
    snapPositions?.current?.expanded,
    snapPositions?.current?.minimized
  )

  return (
    <StyledCategoryListContainer height="100%">
      <StyledCategoryHeaderContainer
        p={3}
        borderBottom="1px solid #D9D9DD"
        minHeight="100px"
        ref={ref}
      >
        <StyledContainer mx={2} mb={2} ref={detailedViewRef}>
          <BackButton prevTitle={prevTitle} onClick={() => goToPrevView(16)} />
          <StyledContainer display="flex" alignItems="flex-start">
            <Icon {...icon} height="auto" width="19px" mr={3} mt={1} />
            <StyledText fontWeight="600" fontSize="28px" lineHeight="32px">
              {title}
            </StyledText>
          </StyledContainer>
        </StyledContainer>
      </StyledCategoryHeaderContainer>
      <StyledContainer p={3} ml={2} pr={1} height={`calc(100% - ${height}px)`}>
        <StyledContainer height="100%" pr={3} overflowY="scroll">
          <StyledList height="100%">
            {address && (
              <StyledListItem borderBottom="1px solid #D9D9DD" pb={3}>
                <StyledText
                  py={1}
                  fontWeight={600}
                  fontSize={6}
                  fontFamily="headings"
                >
                  {address}
                </StyledText>
                <StyledTextWithCursor
                  py={1}
                  fontWeight={400}
                  fontSize={10}
                  color="#0072CE"
                  onClick={() =>
                    getDirectionsGeoJson({
                      destinationPolygonCoordinates: geometry?.coordinates?.[0],
                      defaultAddress: address,
                    })
                  }
                >
                  Get directions
                </StyledTextWithCursor>
                {closure?.displayText && (
                  <StyledContainer>
                    <StyledSvgContainer
                      my={2}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <StyledSvg
                        src={closureIcon}
                        alt="closure-icon"
                        height="16px"
                        width="auto"
                        mt={2}
                        mr={2}
                      />
                      <StyledText py={1} fontWeight={400} fontSize={6}>
                        {closure?.displayText}
                      </StyledText>
                    </StyledSvgContainer>
                    {renderMarkdown(closure?.longDescription)}
                  </StyledContainer>
                )}
              </StyledListItem>
            )}
            {description && (
              <StyledListItem borderBottom="1px solid #D9D9DD" py={3}>
                <StyledContainer py={1}>
                  {renderMarkdown(description)}
                </StyledContainer>
              </StyledListItem>
            )}
            {phoneNumber && (
              <StyledListItem
                py={3}
                borderBottom="1px solid #D9D9DD"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledContainer display="flex" alignItems="center" py={1}>
                  <StyledSvgContainer>
                    <StyledSvg src={phoneIcon} alt="phone-icon" />
                  </StyledSvgContainer>
                  <StyledPhoneNumber
                    href={`tel: ${phoneNumber}`}
                    py={0}
                    ml={2}
                    fontSize={6}
                    fontWeight={400}
                  >
                    {phoneNumber}
                  </StyledPhoneNumber>
                </StyledContainer>
              </StyledListItem>
            )}
            {tags && tags.length > 0 && (
              <StyledListItem borderBottom="1px solid #D9D9DD" py={3}>
                {tags.map(({ id, title, icon, customIcon }) => (
                  <StyledContainer key={id} display="flex" alignItems="center">
                    <Icon
                      icon={icon}
                      customIcon={customIcon}
                      height="auto"
                      width="14px"
                      m={1}
                      ml={0}
                    />
                    <StyledText ml={2} my={1} fontSize={6} fontWeight={400}>
                      {title}
                    </StyledText>
                  </StyledContainer>
                ))}
              </StyledListItem>
            )}
            {nearbyFacilities && nearbyFacilities.length > 0 && (
              <StyledListItem borderBottom="1px solid #D9D9DD" py={3}>
                <StyledText
                  fontSize={6}
                  fontWeight={600}
                  color="#969696"
                  pb={1}
                >
                  Nearby Facilities
                </StyledText>
                {nearbyFacilities.map((facility, i) => (
                  <StyledText
                    key={`${facility}-${i}`}
                    fontWeight="semiBold"
                    fontSize={6}
                    pt={1}
                  >
                    {facility}
                  </StyledText>
                ))}
              </StyledListItem>
            )}
            {nearbyParkingAndTransit && nearbyParkingAndTransit.length > 0 && (
              <StyledListItem borderBottom="1px solid #D9D9DD" py={3}>
                <StyledText
                  fontSize={6}
                  fontWeight={600}
                  color="#969696"
                  pb={1}
                >
                  {`Nearby Parking & Transit`}
                </StyledText>
                <StyledContainer
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {nearbyParkingAndTransit.map(({ id, customIcon }) => (
                    <StyledSvgContainer key={id} ml={1} my={2}>
                      <StyledSvg
                        src={customIcon?.fluid?.src || customIcon?.file?.url}
                        alt="transit-icon"
                        height="18px"
                        width="auto"
                      ></StyledSvg>
                    </StyledSvgContainer>
                  ))}
                </StyledContainer>
              </StyledListItem>
            )}
          </StyledList>
        </StyledContainer>
      </StyledContainer>
    </StyledCategoryListContainer>
  )
}

DetailView.propTypes = {
  goToPrevView: PropTypes.func,
  sidebarRef: PropTypes.object,
  isExpanded: PropTypes.object,
  snapPositions: PropTypes.object,
  prevTitle: PropTypes.string,
  properties: PropTypes.shape({
    icon: PropTypes.object,
    title: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.string,
    phoneNumber: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.object),
    nearbyFacilities: PropTypes.arrayOf(PropTypes.string),
    nearbyParkingAndTransit: PropTypes.arrayOf(PropTypes.object),
    closure: PropTypes.object,
  }),
}

export default DetailView
