import React from 'react'
import PropTypes from 'prop-types'
import {
  Cell,
  PostCell,
  PostCaption,
  StyledExtraContainer,
  StyledLikes,
  StyledComments,
  StyledLikesContainer,
  StyledCommentsContainer,
  StyledImage,
  StyledImageContainer,
  StyledInstaImageGatsby,
  StyledInstaImageRemote,
  StyledCaptions,
} from './styles'
import { useHover } from '../../hooks'
import { trunc } from '../../utils'
import heartIcon from '../../assets/heart-icon@3x.png'
import speechIcon from '../../assets/comment-icon@3x.png'

const Post = ({
  fixed,
  original,
  caption = '',
  isMobile,
  comments,
  likes,
  lg,
}) => {
  const [hoverRef, isHovered] = useHover()
  return (
    <Cell
      data-testid="instagram-posts"
      ref={hoverRef}
      pr={!lg && '10px'}
      paddingBottom="10px"
      display="flex"
      flexShrink={isMobile ? '0' : '1'}
      flexDirection="column"
    >
      <StyledImageContainer
        display="flex"
        flexShrink={isMobile ? '0' : '1'}
        flexDirection="column"
        position="relative"
      >
        {fixed && (
          <StyledInstaImageGatsby
            height="270px"
            width="270px"
            fixed={fixed}
            alt={caption}
            flexShrink={isMobile ? '0' : '1'}
            isDarken={isHovered && !isMobile}
            color="white"
          />
        )}
        {original && (
          <StyledInstaImageRemote
            height="270px"
            width="270px"
            src={original}
            alt={caption}
            flexShrink={isMobile ? '0' : '1'}
            isDarken={isHovered && !isMobile}
            color="white"
          />
        )}
        <PostCell
          position="absolute"
          isDarken={isHovered && !isMobile}
          bg="instagramHoverBlue"
          height="100%"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <PostCaption
            display="flex"
            flexDirection="column"
            fontSize={6}
            m={0}
            p="10px 10px 0px 10px"
            borderBottom="white"
            isVisible={isHovered && !isMobile}
            overflow="hidden"
            fontWeight={300}
            textAlign="left"
            color="black"
          >
            <StyledExtraContainer
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              my={2}
              isVisible={isHovered && !isMobile}
            >
              <StyledLikesContainer
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <StyledImage
                  height="20px"
                  width="20px"
                  mr={2}
                  src={heartIcon}
                  alt={'heart icon'}
                />
                <StyledLikes data-testid="like-amount" fontFamily="body" m={0}>
                  {likes || 0}
                </StyledLikes>
              </StyledLikesContainer>
              <StyledCommentsContainer
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <StyledImage
                  height="20px"
                  width="23px"
                  mr={2}
                  src={speechIcon}
                  alt={'speech icon'}
                />
                <StyledComments
                  data-testid="comment-amount"
                  fontFamily="body"
                  m={0}
                >
                  {comments || 0}
                </StyledComments>
              </StyledCommentsContainer>
            </StyledExtraContainer>
            <StyledCaptions fontFamily="body" m={0}>
              {trunc(caption, 300)}
            </StyledCaptions>
          </PostCaption>
        </PostCell>
      </StyledImageContainer>
    </Cell>
  )
}

Post.propTypes = {
  fixed: PropTypes.object,
  original: PropTypes.string,
  caption: PropTypes.string,
  isMobile: PropTypes.bool,
  comments: PropTypes.number,
  likes: PropTypes.number,
}

export default Post
