import React from 'react'
import PropTypes from 'prop-types'
import { DefaultList, DownloadList } from '../List'

const BladeList = ({ layout, ...rest }) => {
  switch (layout) {
    case 'Default':
      return <DefaultList {...rest} />
    case 'Download':
      return <DownloadList {...rest} />
    default:
      return null
  }
}

BladeList.propTypes = {
  layout: PropTypes.string,
}

export default BladeList
