/* eslint-disable camelcase */
import React from 'react'
import { A } from '../Button'
import { Container, Cell, StyledArrowLeft } from './styles.js'
import Post from './Post'
import { useMobile } from '../../hooks'

import PropTypes from 'prop-types'
import useMediaQuery from '../../hooks/useMediaQuery'
import useInsta from './useInsta'

const ViewMoreButton = ({ user }) => {
  return (
    <Cell
      textAlign="center"
      fontSize={0}
      py={[4]}
      fontWeight="600"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="none"
      flexDirection="row"
    >
      <Cell
        textAlign="right"
        fontWeight="600"
        color="white"
        fontSize="23px"
        fontFamily="headings"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="none"
        flexDirection="row"
      >
        <A color="white" href={`https://www.instagram.com/${user}`}>
          View more
        </A>
        <StyledArrowLeft ml={2} mr={1} />
      </Cell>
    </Cell>
  )
}
const Instagram = ({ user, theme = false, posts = [] }) => {
  const t = theme ? 'light' : 'dark'
  const [isMobile] = useMobile('40em')
  const [igPosts] = useInsta(user, posts)

  const xs = useMediaQuery(`(max-width: 40em)`)
  const lg = useMediaQuery(`(min-width: 1167px)`)

  return (
    <Container
      data-testid="instagram"
      color={`${t}.text`}
      backgroundColor={`${t}.bgAlt`}
      py={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="maxWidth.xlarge" width="100%" px={3}>
        <Cell
          margin="0 auto"
          textAlign="center"
          fontSize={3}
          py={4}
          textShadow={theme === 'dark' && 'text'}
          fontFamily="body"
          marginBottom="20px"
          fontWeight="400"
        >
          Follow our progress on Instagram{' '}
          <A color="white" href={`https://www.instagram.com/${user}`}>
            @{user}
          </A>
        </Cell>
        <Cell
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={xs || lg ? 'space-between' : 'center'}
          flexWrap={isMobile ? 'nowrap' : 'wrap'}
          overflowX="auto"
          overflowY="hidden"
          width="100%"
          margin="0 auto"
          textAlign="center"
        >
          {igPosts.length >= 1 &&
            igPosts.map(
              ({
                id,
                comments,
                likes,
                caption,
                timestamp,
                localFile,
                original,
              }) => {
                return (
                  <Post
                    key={id}
                    comments={comments}
                    likes={likes}
                    fixed={localFile?.childImageSharp?.fixed}
                    original={original}
                    caption={caption}
                    date={timestamp}
                    id={id}
                    isMobile={isMobile}
                    lg={lg}
                  />
                )
              }
            )}
        </Cell>
        {igPosts.length >= 1 && (
          <ViewMoreButton isMobile={isMobile} user={user} />
        )}
      </Container>
    </Container>
  )
}

Instagram.propTypes = {
  user: PropTypes.string,
  theme: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      timestamp: PropTypes.number,
      caption: PropTypes.string,
      comments: PropTypes.number,
      likes: PropTypes.number,
    })
  ),
}

export default Instagram
