import React from 'react'
import {
  StyledUpdate,
  StyledCategory,
  StyledTitle,
  StyledBorder,
  StyledContainer,
} from './styles'
import PropTypes from 'prop-types'
import { Date } from '../Date'

const Update = ({ title, date, categories = [], theme = 'light' }) => {
  const borderSize = `1px solid`
  return (
    <StyledUpdate
      display="flex"
      flexShrink="0"
      width="260px"
      flexDirection="column"
      justifyContent="space-between"
      bg={`${theme}.bg`}
      mr="45px"
      my={2}
    >
      <StyledCategory
        color={`${theme}.textAlt`}
        fontWeight={400}
        fontSize="14px"
        fontFamily="headings"
        my="0px"
        py="0px"
      >
        {categories?.[0]}
      </StyledCategory>
      <StyledContainer display="flex" justifyContent="flex-start" height="100%">
        <StyledTitle
          fontSize={[6]}
          fontWeight={400}
          fontFamily="body"
          m={0}
          color="#4A4A4A"
          borderColor="hero"
          py="8px"
        >
          {title}
        </StyledTitle>
      </StyledContainer>
      <StyledBorder borderTop={borderSize} borderColor="hero">
        <Date
          date={date}
          fontFamily="body"
          fontWeight="400"
          color="#808080"
          fontSize="18px"
          my="16px"
        />
      </StyledBorder>
    </StyledUpdate>
  )
}

Update.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.string,
}

export default Update
