import React from 'react'
import { ThemeSwitchButton } from '../../Button'

const InternalOrExternalLinkButton = ({
  theme,
  children,
  to,
  href,
  bgType,
}) => {
  return (
    <ThemeSwitchButton theme={theme} to={to} href={href}>
      {children}
    </ThemeSwitchButton>
  )
}

export default InternalOrExternalLinkButton
