import React from 'react'
import DownloadList from '../../components/List/DownloadList'

const DownloadListContainer = ({ listItems = [], ...rest }) => {
  listItems = listItems.map(({ downloadItem, ...rest }) => ({
    ...rest,
    src: downloadItem?.file?.url,
  }))

  return <DownloadList listItems={listItems} {...rest} />
}

export default DownloadListContainer
