import React from 'react'
import Icon from '../../Icon'
import Cell from '../../Cell'

const EmbeddedEntryIcon = ({ icon, customIcon, title }) => {
  return (
    <Cell py="20px">
      <Icon icon={icon} customIcon={customIcon} title={title} />
    </Cell>
  )
}

export default EmbeddedEntryIcon
