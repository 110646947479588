import React from 'react'
import { useMobile } from '../../hooks'
import CardDesktop from './CardDesktop'
import CardMobile from './CardMobile'
import PropTypes from 'prop-types'

const Card = ({ ...rest }) => {
  const [isMobile] = useMobile()
  if (isMobile) return <CardMobile {...rest} />
  if (isMobile === false) return <CardDesktop {...rest} />

  return null // isMobile === undefined for SSR     */
}

Card.propTypes = {
  rest: PropTypes.object,
}

export default Card
