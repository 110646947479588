import styled from 'styled-components'
// import { sizes } from '../../themes/coh'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

const sizes = {
  xsmall: { min: '0px', max: '574px' },
  small: { min: '575px', max: '764px' },
  medium: { min: '765px', max: '991px' },
  large: { min: '992px', max: '1279px' },
  xlarge: { min: '1280px', max: Infinity },

  // Sidebar/nav related tweakpoints
  largerSidebar: { min: 1100, max: 1339 },
  sidebarFixed: { min: 2000, max: Infinity },

  // Topbar related tweakpoints
  expandedSearch: { min: 1180, max: Infinity },
  // to remove

  grid: {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    outerPadding: '15px',
    maxWidth: '1200px',
    maxWidthCentered: '650px',
  },
}

const getWidthString = colSize => {
  if (!colSize) return
  const width = (colSize / sizes.grid.gridSize) * 100
  return `${width}%`
}

export const Container = styled.section`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  overflow: hidden;
`

export const Col = styled.div`
  box-sizing: border-box;
  float: left;
  border: ${({ border }) => (border ? '1px solid #DDE0E3' : null)};
  border-width: ${({ border }) => (border ? '0px 1px 1px 0px' : null)};
  border-radius: ${({ border }) => (border ? '0px' : null)};
  padding: ${({ padding }) => padding || '0px'};
  width: ${({ xs }) => (xs ? getWidthString(xs) : '100%')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0px'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};

  @media screen and (min-width: ${sizes.small.min}) {
    width: ${({ sm }) => sm && getWidthString(sm)};
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    width: ${({ md }) => md && getWidthString(md)};
  }
  @media screen and (min-width: ${sizes.large.min}) {
    width: ${({ lg }) => lg && getWidthString(lg)};
  }
  @media screen and (min-width: ${sizes.large.max}) {
    width: ${({ xl }) => xl && getWidthString(xl)};
  }
`

export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${({ padding }) => padding || '0px'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0')};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '0px')};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : '0px'};
  

  ${({ reverse }) =>
    reverse &&
    `
    flex-direction: row-reverse;
  `}
  ${({ start }) =>
    start &&
    `
    justify-content: flex-start;
  `}
  ${({ center }) =>
    center &&
    `
    justify-content: center;
  `}
  ${({ end }) =>
    end &&
    `
    justify-content: flex-end;
    width: 100%;
  `}
  ${({ top }) =>
    top &&
    `
    align-items: flex-start;
  `}
  ${({ middle }) =>
    middle &&
    `
    align-items: center;
  `}
  ${({ bottom }) =>
    bottom &&
    `
    align-items: flex-end;
  `}
  ${({ around }) =>
    around &&
    `
    justify-content: space-around;
  `}
  ${({ between }) =>
    between &&
    `
    justify-content: space-between;
  `}
  ${({ first }) =>
    first &&
    `
    order: 1;
  `}
  ${({ last }) =>
    last &&
    `
    order: -1;
  `}
  
  > ${Col}:nth-child(odd) {
    padding: 40px 0px 0px 0px;
  }

  > ${Col}:nth-child(even) {
    padding: 40px 0px 0px 0px;
  }

  > ${Col}:first-child, > ${Col}:nth-child(2) {
    padding: 0px;
  }
`

export const Block = styled.div`
  background-color: ${({ color }) => 'white'};
  background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : 'none')};
  background-size: ${({ imgUrl }) => (imgUrl ? '565px' : 'none')};
  background-position: ${({ imgUrl }) => (imgUrl ? 'right top' : 'none')};
  background-repeat: ${({ imgUrl }) => (imgUrl ? 'no-repeat' : 'none')};
  width: 100%;
  padding: ${({ padding }) => padding || '1em 0em 1em 0em'};
  margin: ${({ margin }) => margin || '0 auto'};

  @media (max-width: ${sizes.small.max}) {
    padding: 50px 0px;
  }
`

export const Background = styled(BackgroundImage)`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`
export const BackgroundDiv = styled.div`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
`

export const RichTextOverlay = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  `

export const Cell = styled.div`
  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}
`

// left
// right
export const TextCell = styled(Cell)`
  p:first-child,
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0px;
  }
  p:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:first-child,
  h5:last-child,
  h6:last-child {
    margin-bottom: 0px;
  }
`
export const BorderCell = styled(TextCell)`
  background-image: ${({ mobile }) =>
    !mobile
      ? `linear-gradient(to bottom,#0072ce 40%,rgba(255,255,255,0) 0%)`
      : `linear-gradient(to right, #0072ce 40%, rgba(255, 255, 255, 0) 0%)`};
  background-position: ${({ mobile }) => (!mobile ? 'left' : 'top')};
  background-size: ${({ mobile }) => (!mobile ? '2px 14px' : '14px 2px')};
  background-repeat: ${({ mobile }) => (!mobile ? 'repeat-y' : 'repeat-x')};
  box-sizing: ${({ mobile }) => !mobile && 'border-box'};
`

export const OverlayContainer = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  position: absolute;
  top: 50%;
  margin: 0 auto;
  transform: translate(0, -50%);
  
`

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}


`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}

`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}

`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}


`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
  ${space}
  ${shadow}
`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}

  text-transform: uppercase;

`
export const P = styled.div`
margin-top: 24px;
margin-bottom: 24px;
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${border}
`
export const Button = styled.button`
${color}
  ${layout}
  ${typography}
  ${border}

  &:hover {
    background-color: #0025435c;
  }

`

export const LI = styled.li`
  ${space}
`

export const B = styled.b`
${color}
  ${layout}
  ${typography}

`

export const I = styled.i`
${color}
  ${layout}
  ${typography}

`

export const U = styled.u`
${color}
  ${layout}
  ${typography}

`

export const StyledImage = styled(Img)`
  ${layout}
`

export const StyledColorOverlay = styled(Container)``

export const StyledColorOverlayContainer = styled(Container)``
