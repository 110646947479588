import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  StyledSlider,
  CarouselContainer,
  StyledArrowContainer,
  StyledButtonWidth,
  StyledButtonContainer,
  StyledButton,
} from './style'
import CarouselSlickContent from './components/CarouselSlickContent'
import { ThemeContext } from 'styled-components'
import CarouselSlickXsBtn from './CarouselSlickXsBtn'

const CarouselSlickXs = ({
  theme = 'dark',
  autoPlay,
  minHeight = '20vh',
  galleryItems = [],
  isMobile,
}) => {
  const themePackage = useContext(ThemeContext)
  const bg = themePackage.colors[theme].bgAlt
  const borderRadius = themePackage && themePackage.borderRadius
  const slider = useRef(null)
  const carouselSettings = {
    centerPadding: '80px',
    adaptiveHeight: true,
    autoplay: autoPlay,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          centerPadding: '30px',
        },
      },
    ],
  }
  return (
    <CarouselContainer data-testid="carousel-xs" bg={bg} overflow="hidden">
      <StyledArrowContainer position="relative">
        <StyledButtonWidth
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          width="100%"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <StyledButtonContainer
            display="flex"
            flexDirection="row"
            width="100%"
            px={['6vw', '6vw', '6vw', '6vw', '6vw', '1vw']}
            margin="0 auto"
            justifyContent="space-between"
            maxWidth={themePackage.sizes.maxWidth.xlargeNav}
          >
            <StyledButton zIndex={1}>
              <CarouselSlickXsBtn
                onClick={() => slider.current.slickPrev()}
                direction="left"
                fill={themePackage.colors[theme].btnBg}
                hover={themePackage.colors[theme].btnHover}
                arrowColor={isMobile ? themePackage.colors.blues[3] : '#fff'}
              />
            </StyledButton>
            <StyledButton zIndex={1}>
              <CarouselSlickXsBtn
                onClick={() => slider.current.slickNext()}
                direction="right"
                fill={themePackage.colors[theme].btnBg}
                hover={themePackage.colors[theme].btnHover}
                arrowColor={isMobile ? themePackage.colors.blues[3] : '#fff'}
              />
            </StyledButton>
          </StyledButtonContainer>
        </StyledButtonWidth>
        <StyledSlider ref={slider} {...carouselSettings}>
          {galleryItems &&
            galleryItems.map((g, i) => {
              return (
                <CarouselSlickContent
                  key={i}
                  items={g}
                  isMobile={isMobile}
                  theme={theme}
                  minHeight={minHeight}
                  borderRadius={borderRadius}
                />
              )
            })}
        </StyledSlider>
      </StyledArrowContainer>
    </CarouselContainer>
  )
}

CarouselSlickXs.propTypes = {
  theme: PropTypes.string,
  autoPlay: PropTypes.bool,
  minHeight: PropTypes.string,
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnail: PropTypes.object,
      description: PropTypes.exact({
        json: PropTypes.exact({
          data: PropTypes.object,
          content: PropTypes.arrayOf(PropTypes.object).isRequired,
          nodeType: PropTypes.string.isRequired,
        }),
      }),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string.isRequired,
          title: PropTypes.string,
          src: PropTypes.string,
          srcContentful: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
          thumbnail: PropTypes.shape({
            id: PropTypes.string,
            fluid: PropTypes.object,
          }),
        })
      ).isRequired,
    })
  ),
  isMobile: PropTypes.bool,
}

export default CarouselSlickXs
