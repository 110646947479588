import React from 'react'
import { StyledSvgContainer } from './style'
import { useMobile, useHover } from '../../hooks'
import PropTypes from 'prop-types'

const Glyph = ({ glyph, stroke = '#fff' }) => {
  switch (glyph) {
    case 'carousel-btn':
      return (
        <>
          <g
            transform="translate(84 84) rotate(180)"
            stroke="#5bc2e7"
            strokeWidth="1"
          >
            <circle cx="42" cy="42" r="42" stroke="none" />
            <circle cx="42" cy="42" r="41.5" fill="none" />
          </g>
          <circle
            cx="42"
            cy="42"
            r="42"
            transform="translate(84 84) rotate(180)"
            opacity="0"
          />
        </>
      )
    case 'left-arrow':
      return (
        <g transform="translate(19.541 2.828) rotate(90)">
          <path
            d="M0,0,16.713,16.713"
            transform="translate(0 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="4px"
          />
          <path
            d="M16.855,0,0,16.713"
            transform="translate(16.996 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="4px"
          />
        </g>
      )
    case 'right-arrow':
      return (
        <g transform="translate(2.828 36.679) rotate(-90)">
          <path
            d="M0,0,16.713,16.713"
            transform="translate(0 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="4px"
          />
          <path
            d="M16.855,0,0,16.713"
            transform="translate(16.996 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="4px"
          />
        </g>
      )
    default:
      return null
  }
}

const Svg = ({ glyph, stroke, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
      <Glyph glyph={glyph} stroke={stroke} />
    </svg>
  )
}

const CarouselSlickBtn = ({
  direction,
  onClick,
  fill,
  hover,
  arrowColor = '#fff',
}) => {
  const [isMobile] = useMobile()
  const [hoverRef, isHovered] = useHover()
  const xTranslate = isMobile ? 90 : 120
  return (
    <StyledSvgContainer
      ref={hoverRef}
      onClick={onClick}
      position="relative"
      color="white"
      py={1}
      data-testid={direction}
    >
      {!isMobile && (
        <Svg
          width="85"
          height="84"
          viewBox="0 0 85 84"
          fill={isHovered ? hover : fill}
          glyph="carousel-btn"
        />
      )}
      <StyledSvgContainer
        position="absolute"
        top="0"
        left={direction === 'left' && 0}
        right={direction === 'right' && 0}
        transform={`translate(${
          direction === 'left' ? xTranslate : -1 * xTranslate
        }%, ${isMobile ? 0 : 60}%)`}
      >
        <Svg
          width="22.37"
          height="39.507"
          viewBox="0 0 22.37 39.507"
          stroke={arrowColor}
          glyph={direction === 'left' ? 'left-arrow' : 'right-arrow'}
        />
      </StyledSvgContainer>
    </StyledSvgContainer>
  )
}

CarouselSlickBtn.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string,
  fill: PropTypes.string,
  hover: PropTypes.string,
  arrowColor: PropTypes.string,
}

export default CarouselSlickBtn
