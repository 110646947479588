import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'
import Select from 'react-select/async'

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  ${({ overflowY }) => overflowY && `overflow-y: ${overflowY};`}
  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
    border-left:0;
    background:rgba(0,0,0,0.1);
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
  }
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`

export const StyledText = styled.p`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif;
  color: black;
  font-weight: 300;
  line-height: 24px;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
`

export const StyledTextWithCursor = styled(StyledText)`
  cursor: pointer;
`
export const StyledMapContainer = styled(StyledContainer)``

export const StyledMap = styled(StyledMapContainer)`
  border-left: 1px solid #fff;
  top: 0;
  bottom: 0;
  ${position}
  ${border}
`

export const StyledSidebar = styled(StyledMapContainer)`
  border-radius: 10px;
  box-shadow: 0px 0px 2px #969696;
  ${border};
`

export const StyledPreviewContainer = styled(StyledContainer)`
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }
`

export const StyledPreviewContent = styled(StyledContainer)`
  cursor: pointer;
`

export const StyledAddress = styled(StyledText)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledPhoneNumber = styled.a`
  cursor: pointer;
  display: block;
  font-family: 'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif;
  color: black;
  font-weight: 300;
  border-bottom: 1px solid transparent;
  transition: border-color 0.25s ease;
  ${space}
  ${color}
  ${typography}
  
  
  &:hover {
    border-color: black;
  }
`
export const StyledClosure = styled(StyledText)`
  cursor: default;
`

export const StyledPopup = styled(StyledContainer)``

export const StyledPopupTitle = styled(StyledText)``

export const StyledPopupAddress = styled(StyledText)``

export const StyledZoomContainer = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  ${layout}
  ${flexbox}
`

export const StyledBtn = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
 
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
`

export const StyledSvgContainer = styled(StyledContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${layout}
  ${flexbox}
`

export const StyledSvg = styled.img`
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
`

export const StyledCategoryContainer = styled(StyledContainer)`
  height: 100%;
  ${layout}
`

export const StyledCategoryHeaderContainer = styled(StyledContainer)``

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  li:last-child {
    border: none;
  }
  
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
`

export const StyledListItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`

export const StyledCategoryList = styled(StyledList)``
export const StyledHighlightList = styled(StyledList)``
export const StyledHighlightItem = styled(StyledListItem)``

export const StyledViewContainer = styled(StyledContainer)``
export const StyledSectionContainer = styled(StyledContainer)``
export const StyledCategoryListContainer = styled(StyledContainer)``
export const StyledDetailContainer = styled(StyledContainer)``

export const StyledSectionTitle = styled(StyledText)`
  text-transform: uppercase;
`
export const StyledCategoryTitle = styled(StyledText)`
  cursor: pointer;
`

export const StyledZoomBtn = styled(StyledBtn)``

export const StyledBackBtn = styled(StyledBtn)`
  background: none;
`

export const StyledSelect = styled(Select)`
  .react-select__control {
    border-color: #6D7278;
    border-radius: 10px;
    cursor: text;
  }

  .react-select__menu {
    border-radius: 10px;
    .react-select__option {
      cursor: pointer;
    }
  }

  ${space}
  ${layout}
  ${color}
  ${border}
`

export const StyledOverlayShadow = styled(StyledContainer)`
  background: linear-gradient(to bottom, transparent, #eee);
  border-radius: 10px;
`
