import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { StyledDate } from './styles'

const Date = ({ date, short = false, ...rest }) => {
  var fdate = moment(date)
  if (!date) {
    return ''
  }

  return (
    <StyledDate {...rest}>
      {short ? fdate.format('MMM YYYY') : fdate.format('MMM DD, YYYY')}
    </StyledDate>
  )
}
Date.propTypes = {
  date: PropTypes.string,
  short: PropTypes.bool,
  rest: PropTypes.object,
}

export default Date
