import React from 'react'
import {
  StyledSvgContainer,
  StyledArrowBox,
  StyledArrowBoxContainer,
} from './style'
import { useMobile, useHover } from '../../hooks'
import PropTypes from 'prop-types'

const Glyph = ({ glyph, stroke = 'black', isMobile }) => {
  const arrowSize = isMobile ? 8 : 16
  const translateYLeft = isMobile ? 11 : 2.828
  const translateYright = isMobile ? 30.679 : 36.679
  const strokeWidth = isMobile ? '1px' : '4px'
  switch (glyph) {
    case 'left-arrow':
      return (
        <g transform={`translate(19.541 ${translateYLeft}) rotate(90)`}>
          <path
            d={`M0,0,${arrowSize}.713,${arrowSize}.713`}
            transform="translate(0 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={strokeWidth}
          />
          <path
            d={`M${arrowSize}.855,0,0,${arrowSize}.713`}
            transform={`translate(${arrowSize}.996 0)`}
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={strokeWidth}
          />
        </g>
      )
    case 'right-arrow':
      return (
        <g transform={`translate(2.828 ${translateYright}) rotate(-90)`}>
          <path
            d={`M0,0,${arrowSize}.713,${arrowSize}.713`}
            transform="translate(0 0)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={strokeWidth}
          />
          <path
            d={`M${arrowSize}.855,0,0,${arrowSize}.713`}
            transform={`translate(${arrowSize}.996 0)`}
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={strokeWidth}
          />
        </g>
      )
    default:
      return null
  }
}

const Svg = ({ glyph, stroke, isMobile, ...rest }) => {
  return (
    <StyledArrowBoxContainer position="relative">
      <StyledArrowBox
        position="absolute"
        top="0"
        right={glyph === 'right-arrow' ? 0 : 'auto'}
        bottom="2%"
        width="30px"
        bg={isMobile ? 'white' : 'transparent'}
        opacity=".70"
        height="40px"
      />
      <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Glyph glyph={glyph} stroke={stroke} isMobile={isMobile} />
      </svg>
    </StyledArrowBoxContainer>
  )
}

const CarouselSlickInlineBtn = ({
  direction,
  onClick,
  arrowColor = 'black',
}) => {
  const [isMobile] = useMobile()
  const [hoverRef] = useHover()
  return (
    <StyledSvgContainer
      ref={hoverRef}
      onClick={onClick}
      position="relative"
      color="black"
      data-testid={direction}
      height="44.5px"
      width="44.35px"
      zIndex={2}
    >
      <StyledSvgContainer
        position="absolute"
        top="0"
        left={direction === 'left' && 0}
        right={direction === 'right' && 0}
      >
        <Svg
          width="22.37"
          height="39.507"
          viewBox="0 0 22.37 39.507"
          stroke={arrowColor}
          glyph={direction === 'left' ? 'left-arrow' : 'right-arrow'}
          isMobile={isMobile}
        />
      </StyledSvgContainer>
    </StyledSvgContainer>
  )
}

CarouselSlickInlineBtn.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string,
  fill: PropTypes.string,
  hover: PropTypes.string,
  arrowColor: PropTypes.string,
}

export default CarouselSlickInlineBtn
