import React from 'react'
import Map from '../../components/Map'

const getFeatureCollections = rawFeatureCollections => {
  const categoryMap = {}
  if (rawFeatureCollections) {
    rawFeatureCollections.forEach(
      ({ category, coordinates, description, closure, ...rest }) => {
        if (!categoryMap[category.title]) {
          categoryMap[category.title] = { ...category, features: [] }
        }
        categoryMap[category.title].features.push({
          ...coordinates,
          properties: {
            ...rest,
            description: description?.description,
            closure: {
              ...closure,
              longDescription: closure?.longDescription?.longDescription,
            },
            icon: category?.icon,
          },
        })
      }
    )
  }

  return Object.values(categoryMap)
}

const MapContainerGatsby = ({ mapItems, ...props }) => {
  return <Map featureCollections={getFeatureCollections(mapItems)} {...props} />
}

export default MapContainerGatsby
