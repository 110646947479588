import React from 'react'
import PropTypes from 'prop-types'
import { ClosuresApolloCPAContainer } from '../ClosuresContainer'
import { UpdatesApolloCPAContainer } from '../UpdatesContainer'
import { NewsApolloCPAContainer } from '../NewsContainer'
import { InstagramApolloCPAContainer } from '../InstagramContainer'
import { NewsAndUpdatesApolloCPAContainer } from '../NewsAndUpdatesContainer'
import { ProjectListApolloCPAContainer } from '../ProjectListContainer'
import { MapApolloCPAContainer } from '../MapContainer'

const BladeCustomContainer = ({ layout, ...rest }) => {
  switch (layout) {
    case 'News':
      return <NewsApolloCPAContainer {...rest} />
    case 'Closures':
      return <ClosuresApolloCPAContainer {...rest} />
    case 'Updates':
      return <UpdatesApolloCPAContainer {...rest} />
    case 'Expansion Timeline - Projects':
      return <ProjectListApolloCPAContainer {...rest} />
    case 'News & Updates':
      return <NewsAndUpdatesApolloCPAContainer {...rest} />
    case 'Instagram':
      return <InstagramApolloCPAContainer {...rest} />
    case 'Map':
      return <MapApolloCPAContainer {...rest} />
    default:
      return <div />
  }
}

BladeCustomContainer.propTypes = {
  layout: PropTypes.string,
}

export default BladeCustomContainer
