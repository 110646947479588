import React from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '../../../hooks'

import { renderRichText } from './RichText'
import { Cell, BorderCell, TextCell } from '../styles'

const BladeColumn = ({
  textAlignmentHorizontal,
  dividerMinWidth,
  richText,
  border,
  theme,
  bgContentType,
}) => {
  const [isMobile] = useMobile()

  if (border === 'Left') {
    return (
      <Cell
        mobile={isMobile}
        textAlign={isMobile ? 'center' : textAlignmentHorizontal}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile && 'center'}
      >
        <BorderCell
          mobile={isMobile}
          textAlign={isMobile ? 'center' : textAlignmentHorizontal}
          paddingTop={isMobile && '16px'}
          marginRight={!isMobile && '40px'}
          marginBottom={isMobile && '40px'}
          paddingLeft={!isMobile && '40px'}
          maxHeight={!isMobile && '100%'}
          width={isMobile ? [(dividerMinWidth + 2) / 12] : 0}
        />
        <TextCell
          mobile={isMobile}
          textAlign={isMobile ? 'center' : textAlignmentHorizontal}
          display="block"
        >
          {richText &&
            renderRichText({ richText, theme, isMobile, bgContentType })}
        </TextCell>
      </Cell>
    )
  }
  return (
    <TextCell textAlign={isMobile ? 'center' : textAlignmentHorizontal}>
      {richText && renderRichText({ richText, theme, isMobile, bgContentType })}
    </TextCell>
  )
}

export default BladeColumn

BladeColumn.propTypes = {
  textAlignmentHorizontal: PropTypes.string,
  dividerMinWidth: PropTypes.number,
  richText: PropTypes.object,
  border: PropTypes.string,
  bgContentType: PropTypes.string,
  theme: PropTypes.string,
}
