import { useState, useEffect } from 'react'
import { getInstagramPosts, formatPosts } from '../../utils'

const useInsta = (username, initPosts = []) => {
  const [data, setData] = useState(initPosts) // response
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)
    getInstagramPosts({ username })
      .then(posts => formatPosts({ posts: posts.slice(0, 4) }))
      .then(formattedPosts => {
        if (isSubscribed) {
          setData(formattedPosts)
          setLoading(false)
        }
      })
      .catch(e => {
        if (isSubscribed) {
          setError(e)
          setLoading(false)
        }
      })
    return () => {
      isSubscribed = false
    }
  }, [username])

  return [data, loading, error]
}

export default useInsta
