import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Cell } from './styles'
import { renderRichText } from './richText'
import { RichTextOverlay } from './../Image/styles'
import Particles from 'react-particles-js'

const TextContent = ({ backgroundImage, overlayCopy, theme }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (document.body.querySelector('p')) {
        const grabParentEl = document.body.querySelectorAll('p')
        if (grabParentEl) {
          grabParentEl.forEach(n => {
            if (n && n.firstElementChild instanceof HTMLDivElement) {
              n.style.padding = '0'
            }
          })
        }
      }
    }
  }, [])
  return (
    <>
      <Particles
        params={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            size: {
              value: 7,
              random: true,
            },
          },
        }}
        height="30rem"
        width="100%"
        style={{ position: 'absolute', top: '2rem', left: 0 }}
      />
      <Cell
        paddingBottom="0px"
        maxWidth={[
          'maxWidth.text.small',
          'maxWidth.text.medium',
          'maxWidth.text.medium',
          'maxWidth.text.large',
        ]}
        height="100%"
        width="100%"
        paddingTop={'1rem'}
        color="#fff"
        position="relative"
      >
        {overlayCopy && renderRichText(overlayCopy, theme)}
      </Cell>
    </>
  )
}

TextContent.propTypes = {
  backgroundImage: PropTypes.string,
  theme: PropTypes.bool.isRequired,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string.isRequired,
  }),
}

export default TextContent
