import React from 'react'
import {
  StyledBrandLogosContainer,
  StyledWidthContainer,
  StyledLogoContainer,
  StyledNormalImage,
} from './styles'
import { useComponentTheme } from '../../hooks'

const BrandLogos = ({ logos = [], theme: themeBool = 'true' }) => {
  const { theme } = useComponentTheme(themeBool)

  return (
    <StyledBrandLogosContainer
      data-testid="brand-logos"
      bg={`${theme}.logoBg`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <StyledWidthContainer maxWidth="1250px" width="100%" pb={4}>
        <StyledLogoContainer
          display="flex"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-around"
          alignItems="center"
          flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
        >
          {logos.slice(0, logos.length / 2).map(logo => (
            <StyledNormalImage
              key={logo.id}
              p={3}
              src={logo.srcContentful.file.url}
            />
          ))}
        </StyledLogoContainer>
        <StyledLogoContainer
          display="flex"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-around"
          alignItems="center"
          flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
        >
          {logos.slice(logos.length / 2, logos.length).map(logo => (
            <StyledNormalImage
              key={logo.id}
              p={3}
              src={logo.srcContentful.file.url}
            />
          ))}
        </StyledLogoContainer>
      </StyledWidthContainer>
    </StyledBrandLogosContainer>
  )
}

export default BrandLogos
