import React from 'react'
import { PlayButtonWrapper, Button } from './styles'

const PlayButton = ({ playVideo, maxWidth = '120px' }) => {
  return (
    <PlayButtonWrapper
      width="100%"
      height={120}
      display="flex"
      justifyContent="center"
      onClick={() => playVideo}
    >
      <Button aria-label="Play Button" p={0} maxWidth={maxWidth} />
    </PlayButtonWrapper>
  )
}

export default PlayButton
