import React from 'react'
import { useMobile } from './../../hooks'
import {
  Background,
  Cell,
  RichTextOverlay,
  StyledNotificationIcon,
} from './style.js'
// import { TransparentButton } from '../Button'
import notificationIcon from '../../assets/AlertButton.svg'
import { TransparentButton } from '../Button'

const Closures = ({ title, theme = 'dark', closures, closuresBg }) => {
  let data = closures
  const [isMobile] = useMobile('40em')

  return (
    <div data-testid="closures">
      <Background
        Tag="section"
        fluid={closuresBg?.childImageSharp?.fluid}
        backgroundColor={`#040e18`}
        color={`${theme}.text`}
        minHeight={isMobile ? '60vh' : '50vh'}
        display="flex"
      >
        <RichTextOverlay
          width={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <RichTextOverlay
            width={1}
            display="flex"
            flexDirection={isMobile && 'column'}
            flexWrap="wrap"
            p={[4, 4, 5, 5]}
            textAlign="center"
          >
            <Cell
              width={[4 / 4, 3 / 3, 3 / 3, 3 / 3]}
              fontSize={[3, 2, 2, 2]}
              fontWeight={600}
              fontFamily="body"
              color="white"
              textShadow={theme === 'dark' && 'text'}
              p={isMobile ? '40px 0px' : '80px 0px 40px'}
            >
              {title}
            </Cell>
            <Cell
              width="fit-content"
              display="flex"
              justifyContent="center"
              margin="0 auto"
              flexDirection={isMobile && 'column'}
            >
              <Cell
                textAlign="center"
                fontWeight="400"
                fontSize={[5, 4, 4, 4]}
                width={[1, 1 / 20, 1 / 20, 1 / 20, 1 / 20]}
                display="flex"
                p="0px 30px 20px 0px"
                justifyContent={isMobile && 'center'}
              >
                <StyledNotificationIcon
                  src={notificationIcon}
                  alt="notification"
                />
              </Cell>
              <Cell
                textAlign={!isMobile && 'left'}
                width={[1, 3 / 5, 3 / 5, 3 / 5, 3 / 5]}
                color={`${theme}.text`}
                paddingBottom="60px"
              >
                <Cell
                  fontSize={[5, 4, 4, 4]}
                  fontWeight={600}
                  fontFamily="headings"
                  paddingBottom="20px"
                >
                  {data && data[0] && data[0].displayText}
                </Cell>
                <Cell fontSize={[6, 5]} fontWeight={300} fontFamily="headings">
                  {data && data[0] && data[0].description}
                </Cell>
              </Cell>
            </Cell>
            <Cell width={[4 / 4, 3 / 3, 3 / 3, 3 / 3]} pb="20px">
              <TransparentButton theme={theme} to="map">
                Explore Map
              </TransparentButton>
            </Cell>
          </RichTextOverlay>
        </RichTextOverlay>
      </Background>
    </div>
  )
}

Closures.propTypes = {}

export default Closures
