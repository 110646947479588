import React, { useState, useEffect, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import mapboxgl from 'mapbox-gl'
import { useMobile } from '../../hooks'
import PopupContent from './MapComponents/PopupContent'
import _ from 'lodash'
import Marker from './MapComponents/Marker'

const useMap = ({
  featureCollections = [],
  initCenter = [0, 0],
  initZoom = 7,
  maxZoom = 16,
  mapStyle = 'mapbox://styles/mapbox/light-v10',
  handleFeatureClick = () => {},
  showPopup = false,
  showLabel = false,
  polygonFillColor = 'rgb(91, 194, 231)',
}) => {
  const [isMobile] = useMobile()
  const [map, setMap] = useState(null)
  const layerList = useRef([])
  const mapContainer = useRef(null)
  const geolocate = useRef()
  const configuredGeolocateHandler = useRef(false)

  // Utility Functions
  const getPolygonCenter = coordinates => {
    let bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
    )
    return bounds.getCenter()
  }

  const renderLayer = useCallback(
    layerName => {
      if (map) {
        if (layerName === 'All') {
          layerList.current.forEach(name => {
            map.setLayoutProperty(`${name}-fill`, 'visibility', 'none')
            map.setLayoutProperty(`${name}-line`, 'visibility', 'none')
          })
        } else {
          layerList.current.forEach(name => {
            map.setLayoutProperty(`${name}-fill`, 'visibility', 'none')
            map.setLayoutProperty(`${name}-line`, 'visibility', 'none')
          })

          if (layerList.current.includes(layerName)) {
            map.setLayoutProperty(`${layerName}-fill`, 'visibility', 'visible')
            map.setLayoutProperty(`${layerName}-line`, 'visibility', 'visible')
          }
        }
      }
    },
    [map]
  )

  const renderMarkers = useCallback(
    markerName => {
      if (map) {
        const allMarkers = document.getElementsByClassName('marker')
        for (let marker of allMarkers) {
          marker.style.display = 'none'
        }
        const targetMarkers = document.getElementsByClassName(markerName)
        for (let marker of targetMarkers) {
          marker.style.display = 'block'
        }
      }
    },
    [map]
  )

  const createPopup = useCallback(
    (properties, coordinates) => {
      const popUps = document.getElementsByClassName('mapboxgl-popup')
      if (popUps[0]) popUps[0].remove()

      const placeholder = document.createElement('div')
      ReactDOM.render(<PopupContent {...properties} />, placeholder)

      new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat(coordinates)
        .setDOMContent(placeholder)
        .addTo(map)
    },
    [map]
  )

  const fitToBounds = useCallback(
    (features, zoom = maxZoom, shiftOffset) => {
      if (map && features.length) {
        const popUps = document.getElementsByClassName('mapboxgl-popup')
        if (popUps[0]) popUps[0].remove()
        let bounds = new mapboxgl.LngLatBounds()
        features.forEach(({ geometry: { type, coordinates } }) => {
          coordinates =
            type === 'Polygon' ? getPolygonCenter(coordinates[0]) : coordinates
          bounds.extend(coordinates)
        })
        map.fitBounds(bounds, {
          padding: 50,
          maxZoom: zoom + 1,
          offset: isMobile ? [0, -50] : shiftOffset ? [50, 100] : [100, 25],
        })
      }
    },
    [isMobile, map, maxZoom]
  )

  const flyToLocation = useCallback(
    (location, zoom = maxZoom) => {
      if (map) {
        let {
          geometry: { coordinates, type },
          properties,
        } = location
        if (type === 'Polygon') {
          let bounds = new mapboxgl.LngLatBounds()
          coordinates[0].forEach(c => bounds.extend(c))
          map.fitBounds(bounds, {
            padding: isMobile ? 100 : 200,
            maxZoom: zoom + 1,
            offset: isMobile ? [0, -50] : [100, 0],
          })
          coordinates = getPolygonCenter(coordinates[0])
        } else {
          map.flyTo({
            center: coordinates,
            zoom: isMobile ? zoom + 1 : zoom,
            offset: isMobile ? [0, 0] : [200, 0],
          })
        }

        if (showPopup) createPopup(properties, coordinates)
      }
    },
    [createPopup, isMobile, map, maxZoom, showPopup]
  )

  const initializeMap = useCallback(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      zoom: initZoom,
      center: initCenter,
    })

    geolocate.current = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    })

    map.addControl(new mapboxgl.NavigationControl())
    map.addControl(new mapboxgl.FullscreenControl())
    map.addControl(geolocate.current)

    map.on('load', () => {
      setMap(map)
      map.resize()

      featureCollections.forEach(({ title, features }) => {
        map.addSource(title, {
          type: 'geojson',
          data: { type: 'FeatureCollection', features },
        })
      })
    })
  }, [featureCollections, geolocate, initCenter, initZoom, mapStyle])

  const addMarkers = useCallback(
    (title, features, imgSrc) => {
      features.forEach(marker => {
        const el = document.createElement('div')
        ReactDOM.render(
          <Marker
            showLabel={showLabel}
            category={title}
            {...marker}
            imgSrc={imgSrc}
          />,
          el
        )
        el.className = `marker All ${title}`
        el.addEventListener('click', () => {
          flyToLocation(marker, 17)
          renderMarkers(title)
          handleFeatureClick({ ...marker, category: title })
          renderLayer(title)
        })

        const coordinates =
          marker.geometry.type === 'Polygon'
            ? getPolygonCenter(marker.geometry.coordinates[0])
            : marker.geometry.coordinates

        new mapboxgl.Marker(el, { offset: [0, -23] })
          .setLngLat(coordinates)
          .addTo(map)
      })
    },
    [
      flyToLocation,
      handleFeatureClick,
      map,
      renderLayer,
      renderMarkers,
      showLabel,
    ]
  )

  const addLayer = useCallback(
    (title, color) => {
      if (map && title && Object.keys(map.getStyle().sources).includes(title)) {
        if (
          !map
            .getStyle()
            .layers.map(({ id }) => id)
            .includes(`${title}-fill`)
        ) {
          map.addLayer({
            id: `${title}-fill`,
            type: 'fill',
            source: title,
            layout: {
              visibility: 'none',
            },
            paint: {
              'fill-color': color || polygonFillColor,
              'fill-opacity': 0.35,
            },
          })
        }
        if (
          !map
            .getStyle()
            .layers.map(({ id }) => id)
            .includes(`${title}-line`)
        ) {
          map.addLayer({
            id: `${title}-line`,
            type: 'line',
            source: title,
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-color': color || polygonFillColor,
              'line-width': 2,
            },
          })
        }
      }
    },
    [map, polygonFillColor]
  )

  const initializeMarkersAndLayers = useCallback(() => {
    if (featureCollections.length) {
      const layers = []
      featureCollections.forEach(({ title, features, marker, color }) => {
        if (title) {
          addMarkers(title, features, marker?.fluid?.src || marker?.file?.url)
          addLayer(title, color)
          layers.push(title)
        }
      })
      layerList.current = layers

      const allFeatures = _.flatten(
        featureCollections.map(({ features }) => features)
      )

      fitToBounds(allFeatures, maxZoom, true)
      geolocate.current.on('geolocate', () => {
        if (!configuredGeolocateHandler.current) {
          fitToBounds(allFeatures, maxZoom, true)
          configuredGeolocateHandler.current = true
        }
      })
      geolocate.current.trigger()
    }
  }, [addLayer, addMarkers, featureCollections, fitToBounds, maxZoom])

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_TOKEN_PUBLIC

    if (!map) {
      initializeMap()
    } else {
      initializeMarkersAndLayers()
    }
  }, [initializeMap, initializeMarkersAndLayers, map])

  const dispatch = action => {
    if (!map) return
    switch (action.type) {
      case 'FIT_TO_BOUNDS':
        fitToBounds(
          action.payload.features,
          action.payload.zoom,
          action.payload.shiftOffset
        )
        break
      case 'FLY_TO_LOCATION':
        flyToLocation(action.payload.location, action.payload.zoom)
        break
      case 'RENDER_MARKERS':
        renderMarkers(action.payload)
        break
      case 'RENDER_LAYER':
        renderLayer(action.payload)
        break
      default:
        throw new Error('Invalid Action')
    }
  }

  return [mapContainer, dispatch]
}

export default useMap
