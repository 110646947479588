import React from 'react'
import PropTypes from 'prop-types'
import List from '.'
import { useComponentTheme } from '../../hooks'
import { A } from '../Button'
import ListItem from './ListItem'

const DefaultList = ({ listItems = [], theme: themeBool, title }) => {
  const { theme } = useComponentTheme(themeBool)

  return (
    <List dataTestid="default-list" theme={theme} title={title}>
      {listItems.map(({ id, to, href, ...rest }) => (
        <A data-testid="defaultlist-items" key={id} to={to} href={href}>
          <ListItem theme={theme} {...rest} />
        </A>
      ))}
    </List>
  )
}

DefaultList.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
      }),
      navigationLink: PropTypes.string,
      src: PropTypes.string,
    })
  ),
  theme: PropTypes.bool,
  title: PropTypes.string,
}

export default DefaultList
