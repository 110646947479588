import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  StyledContainer,
  StyledSvgContainer,
  StyledSvg,
  StyledText,
} from '../styles'

const Marker = ({
  id,
  properties: { title, shortName },
  imgSrc,
  category,
  showLabel,
}) => {
  return (
    <StyledContainer
      id={id}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <StyledSvgContainer>
        <StyledSvg
          src={`https:${imgSrc}`}
          width="30px"
          height="auto"
          alt={`${category} Marker`}
        />
      </StyledSvgContainer>
      {showLabel && (
        <StyledText
          color="black"
          fontWeight={400}
          width="100px"
          textAlign="center"
          mt={1}
          lineHeight="20px"
        >
          {shortName || title}
        </StyledText>
      )}
    </StyledContainer>
  )
}

Marker.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  shortName: PropTypes.string,
  imgSrg: PropTypes.string,
}

export default Marker
