import React from 'react'
// import PropTypes from 'prop-types'
import ImageContainer from './components/Image'
import VideoContainer from './components/Video'
import usePreviewQuery, { GET_ENTRY } from '../../hooks/usePreviewQuery'

const MediaContainer = ({
  overlayCopy,
  theme = true,
  media,
  type,
  id,
  ...rest
}) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skip: !id,
  })
  let videoSrcs = ['Contentful - MP4', 'External - YouTube', 'External - Vimeo']
  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    let mediaObj = data.entry.fields

    const renderType = (type, mediaObj) => {
      if (type) return type
      if (videoSrcs.includes(mediaObj.src)) {
        return 'video'
      } else {
        return 'image'
      }
    }

    const finalType = renderType(type, mediaObj)

    switch (finalType) {
      case 'image':
        return (
          <ImageContainer
            overlayCopy={overlayCopy}
            theme={theme}
            mediaObj={mediaObj}
            assetId={
              mediaObj && mediaObj.srcContentful
                ? mediaObj.srcContentful.sys.id
                : false
            }
            displayScrollIcon={false}
            darkenImage={false}
            {...rest}
          />
        )
      case 'video':
        return (
          <VideoContainer
            id={id}
            overlayCopy={overlayCopy}
            theme={theme}
            mediaObj={mediaObj}
            assetId={
              mediaObj && mediaObj.srcContentful
                ? mediaObj.srcContentful.sys.id
                : false
            }
            {...rest}
          />
        )
      default:
        return <div />
    }
  }
}
MediaContainer.propTypes = {}

export default MediaContainer
