import React from 'react'
import PropTypes from 'prop-types'
import Video from '../LegacyVideo'
import Image from '../Image'
import Divider from '../Divider'
import Text from '../Text'
import HeroAnimation from '../HeroAnimation'

const BladeMediaText = ({
  layout,
  overlayCopy,
  textAlignmentHorizontal,
  ...rest
}) => {
  if (textAlignmentHorizontal === 'Divider') {
    return (
      <Divider
        textAlignmentHorizontal={textAlignmentHorizontal}
        overlayCopy={
          overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
        }
        {...rest}
      />
    )
  }
  switch (layout) {
    case 'Single Image':
      return (
        <Image
          overlayCopy={
            overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
          }
          textAlignmentHorizontal={textAlignmentHorizontal}
          {...rest}
        />
      )
    case 'Single Video':
      return (
        <Video
          overlayCopy={
            overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
          }
          textAlignmentHorizontal={textAlignmentHorizontal}
          {...rest}
        />
      )
    case 'Divider':
      return (
        <Divider
          textAlignmentHorizontal={textAlignmentHorizontal}
          overlayCopy={
            overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
          }
          {...rest}
        />
      )
    case 'Hero Animation':
      return (
        <HeroAnimation
          textAlignmentHorizontal={textAlignmentHorizontal}
          overlayCopy={
            overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
          }
          {...rest}
        />
      )
    case 'Text - Left':
    case 'Text - Center':
    case 'Text':
      return (
        <Text
          textAlignmentHorizontal={textAlignmentHorizontal}
          overlayCopy={
            overlayCopy && overlayCopy.json ? overlayCopy.json : overlayCopy
          }
          {...rest}
        />
      )
    default:
      return <div />
  }
}
export default BladeMediaText

BladeMediaText.propTypes = {
  layout: PropTypes.string,
  theme: PropTypes.bool,
}
