import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, ImageContainer } from './../style'
import Img from 'gatsby-image'
import DescriptionSlick from './DescriptionSlick'
import { ThemeContext } from 'styled-components'

const CarouselSlickContent = ({
  isMobile = false,
  items = {},
  theme = 'dark',
  minHeight,
  borderRadius,
}) => {
  const themePackage = useContext(ThemeContext)
  if (!(items && items.description && items.description.json)) return <div />
  return (
    <Container
      display="flex"
      alignItems="center"
      maxWidth={themePackage.sizes.maxWidth.xlargeNav}
      m="20px auto"
      px={['50px', '100px', '150px', '200px']}
      isMobile={isMobile}
      minHeight={minHeight}
      justifyContent="center"
    >
      {items && items.fluid && !isMobile && (
        <ImageContainer
          width={borderRadius ? '175px' : '248px'}
          height={borderRadius ? '175px' : '248px'}
        >
          <Img
            fluid={{ src: items && items.fluid }}
            style={{
              width: `${borderRadius ? '175px' : '248px'}`,
              height: `${borderRadius ? '175px' : '248px'}`,
              borderRadius: `${borderRadius && '50%'}`,
            }}
            alt={items.name}
          />
        </ImageContainer>
      )}
      <DescriptionSlick
        isMobile={isMobile}
        name={items && items.name}
        content={items && items.description && items.description.json}
        shortenDescription={items && items.shortenDescription}
        theme={theme}
        title={items && items.title}
      />
    </Container>
  )
}

CarouselSlickContent.propTypes = {
  isMobile: PropTypes.bool,
  items: PropTypes.shape({
    name: PropTypes.string,
    thumbnail: PropTypes.object,
    description: PropTypes.exact({
      json: PropTypes.exact({
        data: PropTypes.object,
        content: PropTypes.arrayOf(PropTypes.object).isRequired,
        nodeType: PropTypes.string.isRequired,
      }),
    }),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string.isRequired,
        title: PropTypes.string,
        src: PropTypes.string,
        srcContentful: PropTypes.shape({
          id: PropTypes.string,
          fluid: PropTypes.object,
        }),
        thumbnail: PropTypes.shape({
          id: PropTypes.string,
          fluid: PropTypes.object,
        }),
      })
    ).isRequired,
  }),
  theme: PropTypes.string,
  minHeight: PropTypes.string,
  borderRadius: PropTypes.bool,
}

export default CarouselSlickContent
