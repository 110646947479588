import React from 'react'
import {
  StyledBackground,
  StyledImgTextContainer,
  StyledTitle,
  StyledText,
  StyledBorderOverlay,
  StyledBorder,
  StyledDot,
  StyledUl,
  StyledDarkenOverlay,
} from './styles'
import { Date } from '../Date'
import { useMobile, useComponentTheme } from '../../hooks'

import PropTypes from 'prop-types'

const TimelineItem = ({ title, date, copy, image, theme = true }) => {
  //  const test = [...entries, ...entries, ...entries, ...entries, ...entries]
  const [isMobile] = useMobile()
  const {
    theme: themeStyle,
    invertTheme: invertThemeStyle,
  } = useComponentTheme(theme)
  return (
    <StyledBackground
      position="relative"
      p={[5, 6, 6]}
      fluid={image?.fluid}
      height="100%"
    >
      <StyledDarkenOverlay
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
      />
      <StyledUl
        flexShrink="0"
        position="relative"
        maxWidth="350px"
        fontFamily="body"
      >
        <StyledImgTextContainer>
          {/* will change how date get its color */}
          <Date
            fontSize={5}
            textShadow="2px 2px 4px #000000"
            color={'teal'}
            date={date}
            short
          />
          <StyledTitle fontSize={3} color={`${invertThemeStyle}.text`}>
            {title}
          </StyledTitle>
          <StyledText fontSize={5} color={`${invertThemeStyle}.text`}>
            {copy?.copy}
          </StyledText>
          {isMobile && (
            <StyledBorderOverlay
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              overflow="hidden"
              pb={3}
              pl="15px"
            >
              <StyledDot m={0} mb={2} mt="-5px" fontSize="30px" color={'teal'}>
                •
              </StyledDot>
              <StyledBorder
                height="100%"
                width="100%"
                ml="3px"
                borderLeft="2px dotted"
                borderColor={`${themeStyle}.borderAlt`}
              />
            </StyledBorderOverlay>
          )}
        </StyledImgTextContainer>
      </StyledUl>
    </StyledBackground>
  )
}

TimelineItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  copy: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.object,
  theme: PropTypes.bool,
}

export default TimelineItem
