import React from 'react'
// import PropTypes from 'prop-types'
import { GridApolloCPAContainer } from '../GridContainer'
import { CarouselApolloCPAContainer } from '../CarouselContainer'

const BladeGalleryApolloCPAContainer = ({ layout, ...rest }) => {
  switch (layout) {
    case 'Grid - Team':
      return <GridApolloCPAContainer {...rest} type="team" />
    case 'Grid - Simple':
      return <GridApolloCPAContainer type="simple" {...rest} />
    case 'Grid':
      return <GridApolloCPAContainer type="company" {...rest} />
    case 'Carousel':
      return <CarouselApolloCPAContainer type="full" {...rest} />
    case 'Carousel - Inline':
      return <CarouselApolloCPAContainer type="inline" {...rest} />
    case 'Carousel - XS':
      return <div>Carousel XS Container</div>
    default:
      return <div />
  }
}

BladeGalleryApolloCPAContainer.propTypes = {}

export default BladeGalleryApolloCPAContainer
