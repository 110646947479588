import styled from 'styled-components'
import {
  space,
  color,
  layout,
  typography,
  position,
  border,
  shadow,
} from 'styled-system'
import Img from 'gatsby-image'

export const GridTitle = styled.h1`
  margin: 0;
  padding: 0;
  ${space}
  ${color}
  ${typography}
`

export const GridSubtitle = styled.h2`
  margin: 0;
  padding: 0;
  ${space}
  ${color}
  ${typography}
`

export const GridHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${space}
  ${color}
  ${typography}
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${layout}
  ${space}
  ${color}
  ${typography}
`

export const GridItemContainer = styled.div`
  box-sizing: border-box;
  ${layout}
  ${space}
  ${color}
  ${typography}
`

export const StyledImageContainer = styled.div`
  box-sizing: border-box;
  ${border}
  ${shadow}
  ${layout}
  ${space}
  ${color}
  ${typography}
  ${position}
`

export const StyledImage = styled(Img)`
  box-sizing: border-box;
  ${border}
  ${shadow}
  ${layout}
  ${space}
  ${color}
  ${typography}
  ${position}
`
export const StyledCaption = styled.div`
  box-sizing: border-box;
  margin: 0;
  ${layout}
  ${space}
  ${color}
  ${typography}

  & p {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 0;
  }
`
