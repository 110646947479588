import React from 'react'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css'
import { StyledPopup, StyledPopupTitle, StyledPopupAddress } from '../styles'

const PopupContent = ({ title, address }) => {
  return (
    <StyledPopup fontFamily="'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif">
      <StyledPopupTitle
        fontWeight="400"
        fontSize="18px"
        color="black"
        bg="bg"
        pb={2}
      >
        {title}
      </StyledPopupTitle>
      <StyledPopupAddress fontWeight="300" fontSize="16px" color="black">
        {address}
      </StyledPopupAddress>
    </StyledPopup>
  )
}

PopupContent.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
}

export default PopupContent
