import React from 'react'
import PropTypes from 'prop-types'
import BladesGatsbyCDAContainer from '../../containers/BladeContainer'
import TimelineBanner from '../TimelineBanner'

const ProjectList = ({ defaultProject }) => {
  if (!defaultProject?.id) {
    return null
  } else {
    const { blades, timelineEntries } = defaultProject
    return (
      <div data-testid="project-list">
        <BladesGatsbyCDAContainer blades={blades} />
        <TimelineBanner entries={timelineEntries} />
      </div>
    )
  }
}

ProjectList.propTypes = {
  defaultProject: PropTypes.shape({
    blades: PropTypes.arrayOf(PropTypes.object),
    timelineEntries: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default ProjectList
