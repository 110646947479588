import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { PreviewAssetsContext } from '../../context'
import TimelineBanner from '../../components/TimelineBanner'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
} from '../../hooks/usePreviewQuery'
import { filterAssetsObjFromAssetsArrByIdAndReturnFluid } from '../../utils'

const TimelineEntryApolloContentfulPreviewContainer = ({ ids }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    id: 'timelineEntry',
    ids: ids,
    skip: !ids,
  })
  const { assets } = useContext(PreviewAssetsContext)
  const [timelineEntries, setTimelineEntries] = useState([])

  useEffect(() => {
    if (!loading && !error && data) {
      let timelineEntries = data?.entries?.items?.map(({ fields }) => ({
        date: fields.date,
        title: fields.title,
        copy: fields.copy,
        image: filterAssetsObjFromAssetsArrByIdAndReturnFluid({
          id: fields?.image?.sys?.id,
          assetsArr: assets,
        }),
      }))

      setTimelineEntries(timelineEntries)
    }
  }, [loading, data, error])

  if (loading) return null
  if (error) return ` `
  if (data && !loading) {
    return <TimelineBanner entries={timelineEntries} />
  }
}

TimelineEntryApolloContentfulPreviewContainer.propTypes = {}

export default TimelineEntryApolloContentfulPreviewContainer
