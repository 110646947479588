import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Cell } from './styles'
import { renderRichText } from './richText'
import { RichTextOverlay } from './../Image/styles'

const TextContent = ({ backgroundImage, overlayCopy, theme }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (document.body.querySelector('p')) {
        const grabParentEl = document.body.querySelectorAll('p')
        if (grabParentEl) {
          grabParentEl.forEach(n => {
            if (n && n.firstElementChild instanceof HTMLDivElement) {
              n.style.padding = '0'
            }
          })
        }
      }
    }
  }, [])
  return backgroundImage ? (
    <Cell
      width={1}
      paddingBottom="0px"
      mt={'3rem'}
      maxWidth={[
        'maxWidth.text.xsmall',
        'maxWidth.text.small',
        'maxWidth.text.medium',
        'maxWidth.text.medium',
        'maxWidth.text.large',
      ]}
      height="100%"
      backgroundImage={`url(${backgroundImage})`}
    >
      <RichTextOverlay bg="rgba(0,0,0,0.5)" p={[4, 4, 5, 5]}>
        {overlayCopy && renderRichText(overlayCopy, theme)}
      </RichTextOverlay>
    </Cell>
  ) : (
    <Cell
      width={1}
      paddingBottom="0px"
      maxWidth={[
        'maxWidth.text.xsmall',
        'maxWidth.text.small',
        'maxWidth.text.medium',
        'maxWidth.text.medium',
        'maxWidth.text.large',
      ]}
      height="100%"
      paddingTop={'1rem'}
    >
      {overlayCopy && renderRichText(overlayCopy, theme)}
    </Cell>
  )
}

TextContent.propTypes = {
  backgroundImage: PropTypes.string,
  theme: PropTypes.bool,
  overlayCopy: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object),
    nodeType: PropTypes.string,
  }),
}

export default TextContent
