import styled from 'styled-components'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

const Cell = styled.div`
  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}
`
export default Cell
