import React from 'react'
import { Date } from '../Date'
import {
  StyledImage,
  StyledImageContainer,
  StyledTitle,
  StyledCategoryContainer,
  StyledDetails,
  StyledIntro,
  StyledDirect,
  StyledTitleCategoryContainer,
  StyledIntroCTAContainer,
  StyledDetailsContainer,
  StyledCardContainer,
} from './styles'
import { useComponentTheme } from '../../hooks'
import { trunc } from '../../utils'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const CardDesktop = ({
  title,
  heroImage,
  date,
  parentSlug,
  slug,
  location,
  pageTitle = '',
  categories = [null],
  intro = { intro: null },
  featured = false,
  theme: themeBool = true,
}) => {
  const { theme } = useComponentTheme(themeBool)
  const displayIntro = trunc(intro.intro, 150)
  const borderSize = `1px solid`

  const handleLinkClick = e => {
    let currentLocation = { slug: location?.pathname, title: pageTitle }
    let locationTrail = []
    if (location?.state?.trail) locationTrail = location.state.trail
    locationTrail.push(currentLocation)
    navigate(`${parentSlug}/${slug}`, {
      state: { trail: locationTrail },
    })
  }

  return (
    <StyledCardContainer
      display="flex"
      flexDirection={['column', 'column', 'row']}
      width="100%"
      onClick={e => handleLinkClick(e)}
      data-testid={'card-desktop'}
      mb={4}
    >
      {/* Image */}
      {heroImage && (
        <StyledImageContainer flexShrink="0" flexGrow="0" width={[1, 1, 3 / 5]}>
          <StyledImage
            fluid={heroImage.fluid}
            overflow="hidden"
            alt="card image"
            width="100%"
            height="100%"
          />
        </StyledImageContainer>
      )}

      <StyledDetailsContainer
        display="flex"
        flexDirection={heroImage ? 'column' : 'row'}
        justifyContent="center"
        borderTop={borderSize}
        borderColor="hero"
        borderTopWidth="5px"
        px={(2, 1, 4)}
        py={(2, 1, 4)}
      >
        <StyledTitleCategoryContainer
          width={heroImage ? 'auto' : [1, 1, 3 / 5]}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRight={!heroImage ? borderSize : 'none'}
          borderColor="hero"
          pr={!heroImage && 5}
          mr={!heroImage && 4}
        >
          <StyledTitle
            color={`${theme}.textAlt`}
            mb={0}
            mt={featured ? 0 : 3}
            pt={heroImage ? 0 : 3}
            fontFamily="body"
            fontSize="26px"
            fontWeight="400"
          >
            {title}
          </StyledTitle>
          <StyledCategoryContainer
            display="flex"
            flexShrink="0"
            justifyContent="space-between"
            borderBottom={heroImage ? borderSize : 'none'}
            borderColor="hero"
            fontFamily="headings"
          >
            <StyledDetails
              fontSize="16px"
              fontFamily="headings"
              color={`${theme}.text`}
              fontWeight={400}
              isDesktop
            >
              {categories?.[0]}
            </StyledDetails>
            <Date
              date={date}
              fontSize="16px"
              fontFamily="headings"
              fontWeight={600}
              color={`${theme}.text`}
            />
          </StyledCategoryContainer>
        </StyledTitleCategoryContainer>

        <StyledIntroCTAContainer
          width={heroImage ? 'auto' : [1, 1, 2 / 5]}
          display="flex"
          flexDirection="column"
        >
          <StyledIntro
            color={`${theme}.text`}
            fontWeight="300"
            lineHeight="22px"
            fontFamily="headings"
            fontSize="18px"
            my={3}
          >
            {displayIntro}
          </StyledIntro>
          <StyledDirect
            color={`${theme}.textAlt`}
            fontSize="23px"
            fontWeight="600"
            fontFamily="headings"
            my={3}
          >
            Keep reading >
          </StyledDirect>
        </StyledIntroCTAContainer>
      </StyledDetailsContainer>
    </StyledCardContainer>
  )
}

CardDesktop.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  location: PropTypes.object,
  parentSlug: PropTypes.string,
  slug: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  intro: PropTypes.shape({
    intro: PropTypes.string,
  }),
  heroImage: PropTypes.object,
  date: PropTypes.string,
  featured: PropTypes.bool,
  theme: PropTypes.bool,
}

export default CardDesktop
